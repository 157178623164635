import React from "react";
import { useContext } from "react";
import GlobalContext from "../../store/global-context";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Spin,
  Button,
  Table,
  Collapse,
  Tag,
  Tooltip,
} from "antd";
import LogAccordion from "../Logs/LogAccordion";
import { currencyFormatter, getPayloadType } from "../../utils/helpers";
import { useState } from "react";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import { PAGINATION_LIMIT, STATUS } from "../../utils/constants";
import { useCallback } from "react";
import API from "../../api";
import useManageTableFilter from "../../hooks/useManageTableFilter";
import Notification from "../shared/Notification";
import HelperModal from "./HelperModal";

const { Panel } = Collapse;

const TestcaseDetails = () => {
  const globalCtx = useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [payload, setPayload] = useState();
  const [importDetails, setImportDetails] = useState();
  const [addresses, setAddresses] = useState([]);
  const [totalAddress, setTotalAddress] = useState(0);
  const [addressLoading, setAddressLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorAccordionOpened, setErrorAccordionOpened] = useState("error");
  const [modalOpen, setModalOpen] = useState(false);
  const [statusData, setStatusData] = useState({});
  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload, {
    page: 1,
    limit: PAGINATION_LIMIT,
  });

  const getImportDetails = useCallback(() => {
    setIsLoading(true);
    API.get("addresses/list-csv", {
      params: { uuid: id },
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setImportDetails(result?.data?.response?.data[0]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        navigate(-1);
        setImportDetails(false);
      });
  }, [id, navigate]);

  const getImportAddresses = useCallback(() => {
    setAddressLoading(true);
    API.get(`addresses/list-test-cases`, {
      params: { ...payload, csv: id },
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setAddresses(result?.data?.response?.data);
          setTotalAddress(result?.data?.response?.total);
        }
        setAddressLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setAddressLoading(false);
      });
  }, [id, payload]);

  const changeStatus = () => {
    setAddressLoading(true);
    setModalOpen(false);
    API.post("addresses/update-test-case", statusData, {
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    })
      .then((result) => {
        if (result.status) {
          getImportAddresses();
          Notification("success", "Status has been updated successfully.");
        }
        setStatusData({});
        setAddressLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setStatusData({});
        setAddressLoading(false);
      });
  };

  useEffect(() => {
    globalCtx.setBreadcrumb([
      { name: "Verified BestX Test Cases", route: "/admin/best-x" },
      { name: "Import", route: "/admin/best-x/import" },
      { name: id },
    ]);
    globalCtx.setActive("3");
    getImportDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getImportDetails]);

  useEffect(() => {
    if (payload) {
      getImportAddresses();
    }
  }, [getImportAddresses, id, payload]);

  const classNames = {
    0: "centered",
    1: "active centered",
    2: "error centered",
  };
  const columns = [
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100,
      fixed: "left",
      render: (text) => (
        <Tag className={classNames[text]}>{STATUS[text] ?? "-"}</Tag>
      ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Pending Review",
            value: "0",
          },
          {
            label: "Approved",
            value: "1",
          },
          {
            label: "Disapproved",
            value: "2",
          },
        ],
        paramKey: "status",
        inputPlaceholder: "Search by status",
      }),
    },
    {
      title: "State",
      key: "State",
      dataIndex: "State",
      width: 80,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "State",
        inputPlaceholder: "Search by state",
      }),
    },
    {
      title: "County",
      key: "County",
      dataIndex: "County",
      width: 100,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "County",
        inputPlaceholder: "Search by county",
      }),
    },
    {
      title: "Transaction Type",
      key: "TransactionType",
      dataIndex: "TransactionType",
      ellipsis: true,
      width: 220,
      render: (text) =>
        <div style={{ textTransform: "capitalize" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Purchase",
            value: "purchase",
          },
          {
            label: "Refinance",
            value: "refi",
          },
        ],
        paramKey: "TransactionType",
        inputPlaceholder: "Search by transaction type",
      }),
    },
    {
      title: "Property Type",
      key: "PropertyType",
      dataIndex: "PropertyType",
      ellipsis: true,
      width: 180,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "SFR",
            value: "sfr",
          },
          {
            label: "Condo",
            value: "condo",
          },
          {
            label: "PUD",
            value: "pud",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        paramKey: "PropertyType",
        inputPlaceholder: "Search by property type",
      }),
    },
    {
      title: "Loan Type",
      key: "LoanType",
      dataIndex: "LoanType",
      ellipsis: true,
      width: 140,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "CNV",
            value: "cnv",
          },
          {
            label: "FHA",
            value: "fha",
          },
          {
            label: "VA",
            value: "va",
          },
          {
            label: "None",
            value: "none",
          },
        ],
        paramKey: "LoanType",
        inputPlaceholder: "Search by loan type",
      }),
    },
    Table.EXPAND_COLUMN,
    {
      title: "Standard/AOL Pricing",
      ellipsis: true,
      key: "standard",
      dataIndex: "standard",
      width: 200,
      render: (text) => <div>{"Click + to see pricing"}</div> || "-",
    },
    {
      title: "Purchase Price",
      key: "PurchasePrice",
      dataIndex: "PurchasePrice",
      ellipsis: true,
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "PurchasePrice",
        inputPlaceholder: "Search by purchase price",
      }),
    },
    {
      title: "Loan Amount",
      key: "LoanOneAmount",
      dataIndex: "LoanOneAmount",
      ellipsis: true,
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "LoanOneAmount",
        inputPlaceholder: "Search by loan amount",
      }),
    },

    {
      title: "Actions",
      key: "uuid",
      dataIndex: "uuid",
      width: 120,
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            {record?.status !== 1 ? (
              <Tooltip title={`Approve`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    setModalOpen(true);
                    setStatusData({ uuid: text, status: 1 });
                  }}
                  style={{ color: "green" }}
                >
                  <i class="fa-solid fa-check"></i>
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {record?.status !== 2 ? (
              <Tooltip title={`Disapprove`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    setModalOpen(true);
                    setStatusData({ uuid: text, status: 2 });
                  }}
                  style={{ color: "rgb(220, 56, 56)" }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  return isLoading ? (
    <Spin spinning tip="Loading...">
      <div className="content-layout card-form-layout" />
    </Spin>
  ) : (
    <div className="content-layout card-form-layout">
      <HelperModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        handleSave={changeStatus}
        text={`Are you sure you want to ${
          statusData?.status == 1 ? "approve" : "disapprove"
        } the test case?`}
      />
      <Row gutter={24} style={{ marginTop: "8px" }}>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-file"></i>
                <p>File Name</p>
              </div>
            }
          >
            {importDetails?.file_name}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-calendar"></i>
                <p>Imported On</p>
              </div>
            }
          >
            {importDetails?.formatted_created_at}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-circle-check"></i>
                <p>Successful Imports</p>
              </div>
            }
            style={{
              textAlign: "center",
            }}
          >
            {importDetails?.success_count || 0}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div
                className="card-title-with-icon"
                style={{ color: "#dc3838" }}
              >
                <i className="fa-solid fa-circle-exclamation"></i>
                <p>Failed Imports</p>
              </div>
            }
            style={{
              color: "#dc3838",
              textAlign: "center",
            }}
          >
            {importDetails?.failure_count}
          </Card>
        </Col>
      </Row>

      {importDetails?.error && (
        <Row gutter={24} style={{ marginTop: "24px" }}>
          <Col span={24}>
            <Card className="request-card">
              <Collapse
                activeKey={errorAccordionOpened}
                onChange={(key) => {
                  setErrorAccordionOpened(key);
                  if (typeof key !== "undefined") {
                    setTimeout(() => {
                      const scrollEl = document.getElementById(
                        `log-summary-${key}`
                      );
                      if (scrollEl) {
                        scrollEl.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }
                    }, 301);
                  }
                }}
                accordion
                bordered={false}
                className="log-summary-accordion address-error-accordion"
                expandIconPosition="end"
              >
                <Panel
                  id={`log-summary-error`}
                  header={
                    <div className="card-title-with-icon error-title">
                      <i className="fa-solid fa-circle-exclamation"></i>
                      <p>Import Error Details</p>
                    </div>
                  }
                  key={"error"}
                >
                  <LogAccordion
                    payload={importDetails?.error}
                    payloadType={getPayloadType({ test: "test" })}
                  />
                </Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: "48px" }}>
        <Col span={24}>
          <Table
            className="best-x-tables"
            loading={addressLoading}
            columns={columns}
            scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
            dataSource={addresses}
            onChange={handleTableChange}
            pagination={{
              current: payload?.page || 1,
              pageSize: payload?.limit || PAGINATION_LIMIT,
              total: totalAddress,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing Address ${range[0]}-${range[1]} of ${total}`,
              onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
                setPayload((prevPayload) => ({
                  ...prevPayload,
                  page,
                  limit: pageSize,
                })),
            }}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Table
                    title={() => <div> Standard Pricing</div>}
                    style={{ width: "45%", marginTop: "16px" }}
                    scroll={{ x: "max-content" }}
                    dataSource={Object?.keys(record?.Standard || {}).map(
                      (val) => {
                        return { type: val, price: record?.Standard?.[val] };
                      }
                    )}
                    columns={[
                      { title: "Type", dataIndex: "type" },
                      { title: "Price", dataIndex: "price" },
                    ]}
                    pagination={false}
                  />
                  <Table
                    title={() => <div>AOL Pricing</div>}
                    style={{
                      width: "45%",
                      marginTop: "16px",
                      marginLeft: "16px",
                    }}
                    scroll={{ x: "max-content" }}
                    dataSource={Object?.keys(record?.AOL || {}).map((val) => {
                      return { type: val, price: record?.AOL?.[val] };
                    })}
                    columns={[
                      { title: "Type", dataIndex: "type" },
                      { title: "Price", dataIndex: "price" },
                    ]}
                    pagination={false}
                  />
                </div>
              ),
            }}
            rowKey={(record) => record._id}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TestcaseDetails;
