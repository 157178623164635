import React, { useEffect } from "react";
import { Col, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../api";
import { useState } from "react";
import LoginVoxturImage from "./../../assets/images/login-icon.png";
import Loader from "../shared/Loader";

const EmailVerification = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      saveToken();
    }
  }, [token]);

  const saveToken = () => {
    setIsLoading(true);
    API.post(
      "email-verification",
      {
        token,
      },
      { baseURL: process.env.REACT_APP_API_ENDPOINT }
    )
      .then((result) => {
        if (result.status === 200) {
          navigate("/verify-email", { replace: true });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-container">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-wrapper">
          <div className="login-form" style={{ width: "100%" }}>
            <div className="login-info">
              <div className="login-icon-container d-flex-center">
                <img
                  className="login-icon"
                  src={LoginVoxturImage}
                  alt="Voxtur EmailVerification"
                  width={"230px"}
                />
              </div>
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "550",
                  display: "flex",
                  justifyContent: "center",
                  color: !token ? "green" : "red",
                }}
              >
                {!token ? "VERIFICATION SUCCESS" : "VERIFICATION FAILURE"}
              </div>
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                {token
                  ? "Your email verification has failed. Please reverify using the link in your email, or you can sign up again using the button below."
                  : "Thank you! Your email has been successfully verified. Please click the button below to log in."}
              </div>
              {
                <Col
                  md={24}
                  sm={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className="login-btn"
                    onClick={() => {
                      navigate(
                        token ? "/sign-up?client=vss-standard" : "/login"
                      );
                    }}
                    loading={isLoading}
                  >
                    Continue
                  </Button>
                </Col>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
