import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import GlobalContext from "../../store/global-context";
import { useState } from "react";
import { Button, Col, Form, Row, Table, Upload, Alert } from "antd";
import { useNavigate } from "react-router";
// import Papa from "papaparse";
import { InboxOutlined } from "@ant-design/icons";
import Notification from "../shared/Notification";
import API from "../../api";
import SAMPLE_CSV from "../../assets/downloads/BestX Test Cases Sample.csv";

const ImportTestcase = () => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const [step] = useState("file-upload");
  const [acceptedFile, setAcceptedFile] = useState();
  const [errorRes, setErrorRes] = useState({});
  // const [mapping, setMapping] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    globalCtx.setBreadcrumb([
      { name: "All Test Cases", route: "/admin/best-x/import" },
      { name: "Import" },
    ]);
    globalCtx.setActive("3");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const parseCSV = (file) => {
  //   Papa.parse(file, {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: (results) => {
  //       const tempMapping = results.meta.fields.map((field) => ({
  //         field: null,
  //         header: field,
  //         row1: results.data?.[0]?.[field] || "-",
  //       }));
  //       setMapping(tempMapping);
  //     },
  //   });
  // };

  // const handleImport = () => {
  //   const requiredFields = ADDRESS_FIELDS_MAPPING.filter(
  //     (mapping) => mapping.required
  //   );

  //   const allRequiredFieldsAreMapped = requiredFields.every(
  //     (requiredField) =>
  //       mapping.findIndex((item) => item.field === requiredField.value) !== -1
  //   );

  //   if (!allRequiredFieldsAreMapped) {
  //     Notification("error", "Please map all fields.");
  //   } else {
  //     setSubmitLoading(true);
  //     const formData = new FormData();
  //     mapping.forEach((item, index) => {
  //       formData.append(`mapping[${index}][field]`, item.field);
  //       formData.append(`mapping[${index}][header]`, item.header);
  //     });
  //     formData.append("file", acceptedFile[0]);
  //     API.post("/addresses/import", formData, {
  //       baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  //     })
  //       .then((result) => {
  //         if (result.status === 200) {
  //           navigate("/admin/addresses/import");
  //           Notification("success", "CSV Successfully Imported.");
  //         }
  //         setSubmitLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log("Error", err?.message);
  //         setSubmitLoading(false);
  //       });
  //   }
  // };

  const handleImport = () => {
    const formData = new FormData();
    formData.append("file", acceptedFile[0]);
    setSubmitLoading(true);
    API.post("/addresses/create-test-case", formData, {
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    })
      .then((result) => {
        if (result?.data?.status) {
          setAcceptedFile("");
          if (result?.data?.response?.failed) {
            Notification(
              "error",
              `There is an error while importing the CSV file.`
            );
            setErrorRes(result?.data?.response);
          } else {
            Notification(
              "success",
              result?.data?.response?.success_count +
                " " +
                result?.data?.response?.success
            );
            navigate(-1);
          }
        } else {
          Notification("error", "Something went wrong!");
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setSubmitLoading(false);
      });
  };

  // const columns = [
  //   {
  //     title: "Mapping",
  //     dataIndex: "field",
  //     key: "field",
  //     width: 300,
  //     render: (_, __, index) => (
  //       <Form.Item style={{ marginBottom: 0 }} className="rounded-input">
  //         <Select
  //           virtual={false}
  //           placeholder="Select Mapping"
  //           allowClear
  //           showSearch
  //           filterOption={(input, option) =>
  //             option?.value
  //               ?.toLowerCase()
  //               ?.indexOf(input?.toLowerCase()?.trim()) >= 0
  //           }
  //           options={ADDRESS_FIELDS_MAPPING.filter(
  //             (field) =>
  //               !mapping.find(
  //                 (item, itemIndex) =>
  //                   item.field === field.value &&
  //                   itemIndex !== index &&
  //                   field.value !== ""
  //               )
  //           )}
  //           onChange={(value) => {
  //             setMapping((prevMapping) => {
  //               const tempMapping = [...prevMapping];
  //               tempMapping[index].field = value;
  //               return tempMapping;
  //             });
  //           }}
  //         />
  //       </Form.Item>
  //     ),
  //   },
  //   {
  //     title: "Header",
  //     dataIndex: "header",
  //     align: "center",
  //     key: "header",
  //     width: 300,
  //   },
  //   {
  //     title: "First Row Data",
  //     dataIndex: "row1",
  //     align: "center",
  //     key: "row1",
  //     width: 300,
  //   },
  // ];

  const downloadCSV = () => {
    const link = document.createElement("a");
    link.href = SAMPLE_CSV;
    link.download = "sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="content-layout">
      {step === "file-upload" && (
        <Form
          onFinish={() => {
            if (acceptedFile && acceptedFile.length > 0) {
              // setStep("mapping");
              handleImport();
            } else {
              Notification("error", "Please upload file.");
            }
          }}
        >
          <div className="form-title-header">
            <div className="form-title-row">
              <i className="fa-solid fa-upload"></i>
              <h1 className="form-title">Upload CSV File</h1>
            </div>
            {errorRes?.failed ? (
              <Alert
                message={
                  <div>
                    <div className="alert-title">
                      Failed Test Cases Count : {errorRes?.failure_count}
                    </div>
                    <div className="alert-title" style={{ marginTop: "8px" }}>
                      Errors
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      {errorRes?.failed?.split("\n")?.length
                        ? errorRes?.failed?.split("\n")?.map((err) => (
                            <li
                              style={{
                                fontSize: "13px",
                                listStyleType: "circle",
                              }}
                            >
                              {err}
                            </li>
                          ))
                        : ""}
                    </div>
                  </div>
                }
                type="warning"
                closable
                onClose={() => {
                  setErrorRes({});
                }}
              />
            ) : (
              ""
            )}
            <Row justify="center">
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "16px",
                  }}
                >
                  <Button className="save-btn" onClick={() => downloadCSV()}>
                    Download Sample CSV
                  </Button>
                </div>
              </Col>
            </Row>

            <Row justify="center">
              <Col sm={24} md={24} lg={14} xxl={14}>
                <div style={{ marginTop: "32px" }}>
                  <Upload.Dragger
                    accept="text/csv"
                    multiple={false}
                    maxCount={1}
                    fileList={acceptedFile || []}
                    customRequest={async (options) => {
                      options.onProgress({ percent: 0 });
                      options.onProgress({ percent: 100 });
                      options.onSuccess();
                    }}
                    beforeUpload={(file) => {
                      if (acceptedFile) {
                        Notification(
                          "error",
                          "Can not upload more than one file."
                        );
                        return;
                      }
                      if (file.type !== "text/csv") {
                        Notification(
                          "error",
                          "File Error",
                          "Only CSV file is allowed to be uploaded."
                        );
                      } else {
                        Notification(
                          "info",
                          "File Successfully uploaded",
                          "You can click the import button to import the test cases."
                        );
                        // parseCSV(file);
                        setAcceptedFile([file]);
                      }
                    }}
                    onRemove={() => {
                      setAcceptedFile();
                      // setMapping([]);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Only 1 file is allowed to be uploaded and Only CSV will be
                      accepted.
                    </p>
                  </Upload.Dragger>
                </div>
              </Col>
              <Col md={24}>
                <div className="permission-info" style={{ marginTop: "32px" }}>
                  <div className="title">
                    <i className="fa-solid fa-circle-info"></i>{" "}
                    <p className="title-text">Notes:</p>
                  </div>
                  <ul>
                    <li>
                      <p>
                        All the values inside the CSV file are case-sensitive.
                      </p>
                    </li>
                    <li>
                      <p>
                        Refer below table for the allowed column values in the
                        CSV file:
                      </p>
                      <Table
                        style={{ width: "100%", marginTop: "16px" }}
                        scroll={{ x: "max-content" }}
                        dataSource={[
                          {
                            column: "State",
                            values: "Valid State (short name)",
                          },
                          {
                            column: "County",
                            values: "Valid County",
                          },
                          {
                            column: "Transaction Type",
                            values: "Purchase or Refinance",
                          },
                          {
                            column: "Property Type",
                            values: `SFR, Two Units, Three Units, Four Units, PUD, Condo, CoOp, Raw/Unimproved, Vacant Land, Mobile Home or Modular`,
                          },
                          {
                            column: "Loan Type",
                            values: "CNV, FHA, VA or None",
                          },
                          {
                            column: "Purchase Price",
                            values: "Number",
                          },
                          {
                            column: "Loan Amount",
                            values: "Number",
                          },
                        ]}
                        columns={[
                          { title: "Column", dataIndex: "column" },
                          { title: "Allowed Values", dataIndex: "values" },
                        ]}
                        pagination={false}
                      />
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <Row type="flex" justify="end" align="middle" className="form-footer">
            <div className="footer-content">
              <Button
                className="cancel-btn"
                onClick={() => navigate(-1)}
                disabled={submitLoading}
              >
                Cancel
              </Button>
              <Button
                className="save-btn"
                htmlType="submit"
                loading={submitLoading}
              >
                Import
              </Button>
            </div>
          </Row>
        </Form>
      )}
      {/* {step === "mapping" && (
        <Form
          onFinish={handleImport}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          name={`fieldMapForm`}
          className="field-map-form"
        >
          <div className="form-title-header">
            <div className="form-title-row">
              <i className="fa-solid fa-shuffle"></i>
              <h1 className="form-title">CSV Field mapping</h1>
            </div>
            <Row justify="center" style={{ marginTop: "32px" }}>
              <Col sm={24} md={24} lg={18} xxl={18}>
                <Table
                  className="editable-table"
                  bordered
                  dataSource={mapping}
                  columns={columns}
                  sticky={true}
                  scroll={{ x: "max-content", y: "calc(100vh - 340px)" }}
                  pagination={false}
                  rowKey={(record) =>
                    `${
                      mapping.find((item) => item.header === record.header)
                        ?.header
                    }-${mapping.findIndex(
                      (item) => item.header === record.header
                    )}`
                  }
                />
              </Col>
            </Row>
          </div>
          <Row type="flex" justify="end" align="middle" className="form-footer">
            <div className="footer-content">
              <Button
                className="cancel-btn"
                onClick={() => setStep("file-upload")}
                disabled={submitLoading}
              >
                Back
              </Button>
              <Button
                className="save-btn"
                htmlType="submit"
                loading={submitLoading}
              >
                Import
              </Button>
            </div>
          </Row>
        </Form>
      )} */}
    </div>
  );
};

export default ImportTestcase;
