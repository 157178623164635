import React, { useState, useEffect, useContext, useCallback } from "react";
import GlobalContext from "../../store/global-context";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import { PAGINATION_LIMIT } from "../../utils/constants";
import API from "../../api";
import { Table, Tag } from "antd";
import { currencyFormatter } from "../../utils/helpers";
import useManageTableFilter from "../../hooks/useManageTableFilter";

const AddressList = () => {
  const globalCtx = useContext(GlobalContext);
  const [addresses, setAddresses] = useState([]);
  const [totalAddress, setTotalAddress] = useState(0);
  const [payload, setPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload);

  useEffect(() => {
    globalCtx.setBreadcrumb([{ name: "Addresses" }]);
    globalCtx.setActive("1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAddresses = useCallback(() => {
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setIsLoading(true);
    API.get("addresses", {
      params: payload,
      signal: newAbortController.signal,
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((res) => {
        if (res.status === 200) {
          setAddresses(res?.data?.response?.data || []);
          setTotalAddress(res?.data?.response?.total || 0);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (!err?.code || err?.code !== "ERR_CANCELED") {
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (payload) {
      getAddresses();
    }
  }, [getAddresses, payload]);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const columns = [
    {
      title: "BestX AOL Eligibility",
      key: "result",
      dataIndex: "aol_eligible",
      align: "center",
      fixed: "left",
      width: 210,
      render: (text, record) =>
        text ? (
          <Tag
            className={
              text === record?.aol ? "active centered" : "error centered"
            }
          >
            {text}
          </Tag>
        ) : (
          "-"
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ],
        paramKey: "aol_eligible",
      }),
    },
    {
      title: "Expected AOL Eligibility",
      key: "aol",
      dataIndex: "aol",
      fixed: "left",
      width: 230,
      render: (text, record) =>
        text ? (
          <Tag
            className={
              text === record?.aol_eligible
                ? "active centered"
                : "error centered"
            }
          >
            {text}
          </Tag>
        ) : (
          "-"
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ],
        paramKey: "aol",
      }),
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
      width: 170,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "address",
        inputPlaceholder: "Search by address",
      }),
    },
    {
      title: "City",
      key: "city",
      dataIndex: "city",
      width: 80,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "city",
        inputPlaceholder: "Search by city",
      }),
    },
    {
      title: "State",
      key: "state",
      dataIndex: "state",
      width: 100,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "state",
        inputPlaceholder: "Search by state",
      }),
    },
    {
      title: "Zip",
      key: "zip",
      dataIndex: "zip",
      width: 100,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "zip",
        inputPlaceholder: "Search by zip",
      }),
    },
    {
      title: "County",
      key: "county",
      dataIndex: "county",
      width: 100,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "county",
        inputPlaceholder: "Search by county",
      }),
    },
    {
      title: "Transaction Type",
      key: "transaction_type",
      dataIndex: "transaction_type",
      width: 200,
      render: (text) =>
        <div style={{ textTransform: "capitalize" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Purchase",
            value: "purchase",
          },
          {
            label: "Refinance",
            value: "refi",
          },
        ],
        paramKey: "transaction_type",
        inputPlaceholder: "Search by transaction type",
      }),
    },
    {
      title: "Property Type",
      key: "property_type",
      dataIndex: "property_type",
      width: 180,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "SFR",
            value: "sfr",
          },
          {
            label: "Condo",
            value: "condo",
          },
          {
            label: "PUD",
            value: "pud",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        paramKey: "property_type",
        inputPlaceholder: "Search by property type",
      }),
    },
    {
      title: "Loan Type",
      key: "loan_type",
      dataIndex: "loan_type",
      width: 140,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "CNV",
            value: "cnv",
          },
          {
            label: "FHA",
            value: "fha",
          },
          {
            label: "VA",
            value: "va",
          },
          {
            label: "None",
            value: "none",
          },
        ],
        paramKey: "loan_type",
        inputPlaceholder: "Search by loan type",
      }),
    },
    {
      title: "Purchase Price",
      key: "purchase_price",
      dataIndex: "purchase_price",
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "purchase_price",
        inputPlaceholder: "Search by purchase price",
      }),
    },
    {
      title: "Loan Amount",
      key: "loan_amount",
      dataIndex: "loan_amount",
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "loan_amount",
        inputPlaceholder: "Search by loan amount",
      }),
    },
    {
      title: "HOA",
      key: "hoa",
      dataIndex: "hoa",
      width: 80,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          { label: "Available", value: "YES" },
          { label: "Not Available", value: "NO" },
        ],
        paramKey: "hoa",
        inputPlaceholder: "Search by HOA",
      }),
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  return (
    <div className="content-layout">
      <Table
        className="transaction-list-table"
        loading={isLoading}
        columns={columns}
        sticky={true}
        scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
        dataSource={addresses}
        onChange={handleTableChange}
        pagination={{
          current: payload?.page || 1,
          pageSize: payload?.limit || PAGINATION_LIMIT,
          showTotal: (total, range) =>
            `Showing Address ${range[0]}-${range[1]} of ${total}`,
          total: totalAddress,
          showSizeChanger: true,
          onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
            setPayload((prevPayload) => ({
              ...prevPayload,
              page,
              limit: pageSize,
            })),
        }}
        rowKey={(record) => record.uuid}
        rowClassName={(record) =>
          record?.aol !== record?.aol_eligible ? "error-row" : undefined
        }
      />
    </div>
  );
};

export default AddressList;
