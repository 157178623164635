import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../api";
import Notification from "../shared/Notification";
import {
  getLocalStorageRememberData,
  removeLocalStorageUser,
  setLocalStorageRememberData,
} from "../../utils/helpers";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      token: new URLSearchParams(search).get("token"),
      email: new URLSearchParams(search).get("email"),
    };

    setIsLoading(true);
    API.post("password/reset", payload, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status === 200) {
          Notification("success", result.data.response.message);
          navigate(
            window.location.pathname.startsWith("/standalone")
              ? "/standalone"
              : "/admin"
          );
          removeLocalStorageUser();
          const rememberMeData = getLocalStorageRememberData();
          if (rememberMeData.rememberMe) {
            const newData = { ...rememberMeData, password: "" };
            setLocalStorageRememberData(newData);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-form">
          <div className="login-info">
            <h3 className="reset-password-heading">Reset Password</h3>

            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              layout={"vertical"}
              name="resetPassword"
              onFinish={handleSubmit}
            >
              <Row gutter={30} type="flex">
                <Col md={24} sm={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      { required: true, message: "Please enter password" },
                      {
                        min: 6,
                        message: "password must be atleast 6 characters long",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ marginBottom: "0.5rem" }}
                      autoComplete="new-password"
                      name="password"
                      type="password"
                      // placeholder={"Enter Password"}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    label="Confirm Password"
                    name="password_confirmation"
                    rules={[
                      {
                        required: true,
                        message: "Please enter confirm password",
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Password do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      style={{ marginBottom: "0.5rem" }}
                      name="password2"
                      type="password"
                      // placeholder={"Enter Confirm Password"}
                    />
                  </Form.Item>
                </Col>

                <Col
                  md={24}
                  sm={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className="login-btn"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

            <Row gutter={30} type="flex">
              <Col
                md={24}
                sm={24}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="login-btn"
                  onClick={() =>
                    navigate(
                      window.location.pathname.startsWith("/standalone")
                        ? "/standalone"
                        : "/admin"
                    )
                  }
                >
                  Back To Login
                </Button>
              </Col>
            </Row>
          </div>
          {/* <span className="signup-text">Need an account? Sign up</span> */}
        </div>
        <div className="login-image"></div>
      </div>
    </div>
  );
};

export default ResetPassword;
