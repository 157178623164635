import { Button } from "antd";
import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../store/global-context";

const AddButton = (props) => {
  const navigate = useNavigate();
  const globalCtx = useContext(GlobalContext);
  return (
    <Button
      onClick={() => {
        if (props.onClick) {
          props.onClick();
          return;
        }
        navigate(props.navigateTo);
      }}
      style={
        props.style
          ? {
              marginLeft: "auto",
              ...props?.style,
            }
          : {
              marginLeft: "auto",
            }
      }
      className={
        globalCtx.user.role === "read_only" ? `add-btn disabled-btn` : "add-btn"
      }
      // className="add-btn"
      disabled={globalCtx.user.role === "read_only" ? true : undefined}
    >
      <span>{props.icon}</span>
      {!props.hideAddIcon && <i className="fas fa-plus"></i>}
      {props.title ? props.title : "Add"}
    </Button>
  );
};

export default AddButton;
