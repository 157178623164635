import React, { Suspense, lazy, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import GlobalContext from "./store/global-context";
import { checkPermissions, lazyRetry } from "./utils/helpers";
import { MODULE_VALUES, PERMISSIONS_VALUES } from "./utils/constants";
import { Spin } from "antd";

const Dashboard = lazy(() =>
  lazyRetry(() => import("./components/Dashboard/Dashboard"))
);
const PartnerForm = lazy(() =>
  lazyRetry(() => import("./components/Partner/PartnerForm"))
);
const PartnerList = lazy(() =>
  lazyRetry(() => import("./components/Partner/PartnerList"))
);
const ViewPartner = lazy(() =>
  lazyRetry(() => import("./components/Partner/ViewPartner"))
);
const ProviderForm = lazy(() =>
  lazyRetry(() => import("./components/Provider/ProviderForm"))
);
const ProviderList = lazy(() =>
  lazyRetry(() => import("./components/Provider/ProviderList"))
);
const ProviderProductList = lazy(() =>
  lazyRetry(() => import("./components/Provider/ProductList"))
);
const ViewProvider = lazy(() =>
  lazyRetry(() => import("./components/Provider/ViewProvider"))
);
const QuestionForm = lazy(() =>
  lazyRetry(() => import("./components/Questions/QuestionForm"))
);
const QuestionsList = lazy(() =>
  lazyRetry(() => import("./components/Questions/QuestionsList"))
);
const UserForm = lazy(() =>
  lazyRetry(() => import("./components/User/UserForm"))
);
const UserList = lazy(() =>
  lazyRetry(() => import("./components/User/UserList"))
);
const UserApprovals = lazy(() =>
  lazyRetry(() => import("./components/User/UserApprovals"))
);
const ViewUser = lazy(() =>
  lazyRetry(() => import("./components/User/ViewUser"))
);
const InboundRequests = lazy(() =>
  lazyRetry(() => import("./components/Report/InboundRequests"))
);
const TransactionList = lazy(() =>
  lazyRetry(() => import("./components/Transactions/TransactionList"))
);
const ProductList = lazy(() =>
  lazyRetry(() => import("./components/Product/ProductList"))
);
const FieldMapListing = lazy(() =>
  lazyRetry(() => import("./components/FieldMapping/FieldMapListing"))
);
const FieldMapForm = lazy(() =>
  lazyRetry(() => import("./components/FieldMapping/FieldMapForm"))
);
const ClientList = lazy(() =>
  lazyRetry(() => import("./components/Client/ClientList"))
);
const ClientForm = lazy(() =>
  lazyRetry(() => import("./components/Client/ClientForm"))
);
const ViewClient = lazy(() =>
  lazyRetry(() => import("./components/Client/ViewClient"))
);
const StandaloneDashboard = lazy(() =>
  lazyRetry(() => import("./components/Standalone/Dashboard/Dashboard"))
);
const TitleCalculator = lazy(() =>
  lazyRetry(() =>
    import("./components/Standalone/TitleCalculator/TitleCalculator")
  )
);
const TitleQuoteUpload = lazy(() =>
  lazyRetry(() =>
    import("./components/Standalone/TitleCalculator/TitleQuoteUpload")
  )
);
const StandaloneTransactionList = lazy(() =>
  lazyRetry(() => import("./components/Standalone/Transaction/TransactionList"))
);
const StandaloneTransaction = lazy(() =>
  lazyRetry(() => import("./components/Standalone/Transaction/Transaction"))
);
const NotificationList = lazy(() =>
  lazyRetry(() =>
    import("./components/Standalone/Notification/NotificationList")
  )
);
const RoleListing = lazy(() =>
  lazyRetry(() => import("./components/Role/RoleListing"))
);
const RoleForm = lazy(() =>
  lazyRetry(() => import("./components/Role/RoleForm"))
);
const CategoryList = lazy(() =>
  lazyRetry(() => import("./components/Category/CategoryList"))
);
const ChangePassword = lazy(() =>
  lazyRetry(() => import("./components/Auth/ChangePassword"))
);
const TransactionTimeline = lazy(() =>
  lazyRetry(() => import("./components/Transactions/TransactionTimeline"))
);
const Settings = lazy(() =>
  lazyRetry(() => import("./components/Settings/Settings"))
);
const AuditTrailsList = lazy(() =>
  lazyRetry(() => import("./components/Logs/AuditTrailsList"))
);
const LogList = lazy(() =>
  lazyRetry(() => import("./components/Logs/LogList"))
);
const ViewLog = lazy(() =>
  lazyRetry(() => import("./components/Logs/ViewLog"))
);

const QuickCalculator = lazy(() =>
  lazyRetry(() =>
    import("./components/Standalone/TitleCalculator/TitleCalculator")
  )
);

const TitleCalculatorHistory = lazy(() =>
  lazyRetry(() =>
    import("./components/Standalone/TitleCalculator/TitleCalculatorHistory")
  )
);

const AppRoutes = () => {
  const globalCtx = useContext(GlobalContext);
  const preference = globalCtx?.user?.client?.feature_preference;
  const canCreateTx = globalCtx?.user?.can_create_tx;
  const permissionKeysWithOutNone = Object.values(PERMISSIONS_VALUES).filter(
    (permissionValue) => permissionValue !== PERMISSIONS_VALUES.NONE
  );
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<Spin spinning tip="Loading..." />}>
      <Routes>
        {/* Dashboard Routes */}
        {!window.location.pathname.startsWith("/standalone") ||
        globalCtx.user.role === "admin" ||
        globalCtx.user.role === "read_only" ? (
          <>
            {/* <Route path="/admin/home" element={<Home />} /> */}
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/change-password" element={<ChangePassword />} />
            <Route
              path="/admin/title-calculator/history"
              element={<TitleCalculatorHistory />}
            />
            {/* <Route
              path="/admin/title-calculator/history"
              element={<TitleCalculator />}
            /> */}
            {/* Partner Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PARTNER_SETUP
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route path="/admin/partner" element={<PartnerList />} />
              )}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PARTNER_SETUP
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route
                  path="/admin/partner/view/:id"
                  element={<ViewPartner />}
                />
              )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PARTNER_SETUP
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route path="/admin/partner/add" element={<PartnerForm />} />
              )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PARTNER_SETUP
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route
                  path="/admin/partner/edit/:id"
                  element={<PartnerForm />}
                />
              )}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.FIELD_MAPPING
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route
                  path="/admin/partner/field_map"
                  element={<FieldMapListing />}
                />
              )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.FIELD_MAPPING
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route
                  path="/admin/partner/field_map/add"
                  element={<FieldMapForm />}
                />
              )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.FIELD_MAPPING
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route
                  path="/admin/partner/field_map/edit/:id"
                  element={<FieldMapForm />}
                />
              )}

            {/* Provider Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PROVIDER_SETUP
              ),
              globalCtx.user.role
            ) && <Route path="/admin/provider" element={<ProviderList />} />}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PROVIDER_SETUP
              ),
              globalCtx.user.role
            ) && (
              <Route
                path="/admin/provider/view/:id"
                element={<ViewProvider />}
              />
            )}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PROVIDER_SETUP
              ),
              globalCtx.user.role
            ) && (
              <Route
                path="/admin/provider/products/:id"
                element={<ProviderProductList />}
              />
            )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PROVIDER_SETUP
              ),
              globalCtx.user.role
            ) && (
              <Route path="/admin/provider/add" element={<ProviderForm />} />
            )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PROVIDER_SETUP
              ),
              globalCtx.user.role
            ) && (
              <Route
                path="/admin/provider/edit/:id"
                element={<ProviderForm />}
              />
            )}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) =>
                  module.module === MODULE_VALUES.MASTER_FORM_CONFIGURATION
              ),
              globalCtx.user.role
            ) && (
              <Route path="/admin/provider/form" element={<QuestionsList />} />
            )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) =>
                  module.module === MODULE_VALUES.MASTER_FORM_CONFIGURATION
              ),
              globalCtx.user.role
            ) && (
              <Route
                path="/admin/provider/form/add"
                element={<QuestionForm />}
              />
            )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) =>
                  module.module === MODULE_VALUES.MASTER_FORM_CONFIGURATION
              ),
              globalCtx.user.role
            ) && (
              <Route
                path="/admin/provider/form/edit/:id"
                element={<QuestionForm />}
              />
            )}

            {/* Client Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.CLIENT
              ),
              globalCtx.user.role
            ) && <Route path="/admin/client" element={<ClientList />} />}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.CLIENT
              ),
              globalCtx.user.role
            ) && <Route path="/admin/client/add" element={<ClientForm />} />}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.CLIENT
              ),
              globalCtx.user.role
            ) && (
              <Route path="/admin/client/edit/:id" element={<ClientForm />} />
            )}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.CLIENT
              ),
              globalCtx.user.role
            ) && (
              <Route path="/admin/client/view/:id" element={<ViewClient />} />
            )}

            {/* User Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.USER
              ),
              globalCtx.user.role
            ) && <Route path="/admin/user" element={<UserList />} />}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.USER
              ),
              globalCtx.user.role
            ) && <Route path="/admin/approvals" element={<UserApprovals />} />}
            {(checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.USER
              ),
              globalCtx.user.role
            ) ||
              pathname.includes(`/user/view/${globalCtx.user.user_uuid}`)) && (
              <Route path="/admin/user/view/:id" element={<ViewUser />} />
            )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.USER
              ),
              globalCtx.user.role
            ) && <Route path="/admin/user/add" element={<UserForm />} />}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.USER
              ),
              globalCtx.user.role
            ) && <Route path="/admin/user/edit/:id" element={<UserForm />} />}

            {/* Inbound Request Report Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.REPORT
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route
                  path="/admin/report/inboundRequests"
                  element={<InboundRequests />}
                />
              )}

            {/* Activity Logs Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.INTEGRATION_LOGS
              ),
              globalCtx.user.role
            ) && <Route path="/admin/logs" element={<LogList />} />}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.INTEGRATION_LOGS
              ),
              globalCtx.user.role
            ) && <Route path="/admin/logs/view/:id" element={<ViewLog />} />}

            {/* Admin Audit Trails Routes */}
            {checkPermissions(undefined, undefined, globalCtx.user.role) && (
              <Route path="/admin/audit-trails" element={<AuditTrailsList />} />
            )}

            {/* Transaction Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.TRANSACTION
              ),
              globalCtx.user.role
            ) && (
              <Route path="/admin/transactions" element={<TransactionList />} />
            )}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.TRANSACTION
              ),
              globalCtx.user.role
            ) && (
              <Route
                path="/admin/transactions/timeline/:id"
                element={<TransactionTimeline />}
              />
            )}

            {/* Product Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.PRODUCT
              ),
              globalCtx.user.role
            ) && <Route path="/admin/product" element={<ProductList />} />}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.CATEGORY
              ),
              globalCtx.user.role
            ) && (
              <Route
                path="/admin/product/categories"
                element={<CategoryList />}
              />
            )}

            {/* Role Routes */}
            {checkPermissions(
              permissionKeysWithOutNone,
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.ROLE
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route path="/admin/roles" element={<RoleListing />} />
              )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.ROLE
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route path="/admin/roles/add" element={<RoleForm />} />
              )}
            {checkPermissions(
              [PERMISSIONS_VALUES.ADD_UPDATE_ONLY, PERMISSIONS_VALUES.FULL],
              globalCtx?.permissions?.find(
                (module) => module.module === MODULE_VALUES.ROLE
              ),
              globalCtx.user.role
            ) &&
              globalCtx.configuration !== "standalone" && (
                <Route path="/admin/roles/edit/:id" element={<RoleForm />} />
              )}

            <Route path="/admin/settings" element={<Settings />} />

            {/* Default Route -> Dashboard */}
            {/* <Route path="/" element={<Navigate to={"/admin/"} />} /> */}
            <Route path="*" element={<Navigate to={"/admin/dashboard"} />} />
          </>
        ) : (
          <>
            {/* <Route path="/standalone/home" element={<Home />} /> */}
            {(preference ? preference !== "title_calculator" : true) &&
              canCreateTx && (
                <Route
                  path="/standalone/dashboard"
                  element={<StandaloneDashboard />}
                />
              )}
            {/* <Route
              path="/standalone/title-calculator"
              element={<TitleCalculator />}
            />
            <Route
              path="/standalone/title-calculator/import"
              element={<TitleQuoteUpload />}
            /> */}
            <Route
              path="/standalone/change-password"
              element={<ChangePassword />}
            />
            {(preference ? preference !== "title_calculator" : true) &&
              canCreateTx && (
                <>
                  <Route
                    path="/standalone/transaction"
                    element={
                      <StandaloneTransactionList
                        showFilters
                        sideBarKey={"2"}
                        breadCrumbTitle="All Transactions"
                        showCreateButton
                        omitKeys={
                          globalCtx?.user?.role === "standalone_user"
                            ? ["created_by"]
                            : undefined
                        }
                      />
                    }
                  />
                  <Route
                    path="/standalone/transaction/not-submitted"
                    element={
                      <StandaloneTransactionList
                        showFilters
                        status="Not Submitted"
                        sideBarKey={"3"}
                        breadCrumbTitle="Not Submitted Transactions"
                        omitKeys={
                          globalCtx?.user?.role === "standalone_user"
                            ? ["order_status", "property_address", "created_by"]
                            : ["order_status", "property_address"]
                        }
                      />
                    }
                  />
                  <Route
                    path="/standalone/transaction/open"
                    element={
                      <StandaloneTransactionList
                        showFilters
                        status="Submitted"
                        sideBarKey={"4"}
                        breadCrumbTitle="Open Transactions"
                        omitKeys={
                          globalCtx?.user?.role === "standalone_user"
                            ? ["created_by"]
                            : undefined
                        }
                      />
                    }
                  />
                  <Route
                    path="/standalone/transaction/cancelled"
                    element={
                      <StandaloneTransactionList
                        status="Cancelled"
                        sideBarKey={"5"}
                        breadCrumbTitle="Cancelled Transactions"
                        omitKeys={
                          globalCtx?.user?.role === "standalone_user"
                            ? ["order_status", "time_passed", "created_by"]
                            : ["order_status", "time_passed"]
                        }
                      />
                    }
                  />
                  <Route
                    path="/standalone/transaction/completed"
                    element={
                      <StandaloneTransactionList
                        status="Completed"
                        sideBarKey={"6"}
                        breadCrumbTitle="Completed Transactions"
                        omitKeys={
                          globalCtx?.user?.role === "standalone_user"
                            ? ["order_status", "time_passed", "created_by"]
                            : ["order_status", "time_passed"]
                        }
                      />
                    }
                  />
                  <Route
                    path="/standalone/transaction/:id"
                    element={<StandaloneTransaction />}
                  />
                </>
              )}
            {/* User Routes */}
            {globalCtx.user.role === "standalone" && (
              <Route path="/standalone/user" element={<UserList />} />
            )}

            {(globalCtx.user.role === "standalone" ||
              pathname.includes(`/user/view/${globalCtx.user.user_uuid}`)) && (
              <Route path="/standalone/user/view/:id" element={<ViewUser />} />
            )}
            {globalCtx.user.role === "standalone" && (
              <Route path="/standalone/user/add" element={<UserForm />} />
            )}
            {(globalCtx.user.role === "standalone" ||
              globalCtx.user.role === "standalone_user") && (
              <Route path="/standalone/user/edit/:id" element={<UserForm />} />
            )}

            {/* title calculator private version */}
            {(preference ? preference !== "transaction" : true) &&
              (globalCtx.user.role === "standalone" ||
                globalCtx.user.role === "standalone_user") && (
                <>
                  <Route
                    path="/standalone/title-calculator/quick"
                    element={<QuickCalculator />}
                  />
                  <Route
                    path="/standalone/title-calculator/history"
                    element={<TitleCalculatorHistory />}
                  />
                </>
              )}

            <Route
              path="/standalone/notifications"
              element={<NotificationList />}
            />

            <Route
              path="/standalone/transaction/timeline/:id"
              element={<TransactionTimeline />}
            />

            <Route path="/standalone/settings" element={<Settings />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    (preference && preference === "title_calculator") ||
                    !canCreateTx
                      ? "/standalone/title-calculator/quick"
                      : "/standalone/dashboard"
                  }
                />
              }
            />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
