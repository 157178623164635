import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Echo from "laravel-echo";
import axios from "axios";
import Notification from "../components/shared/Notification";
import "pusher-js";

const ChannelsContext = createContext(undefined);

export const ChannelsProvider = ({ children, authUser, authToken }) => {
  const [channels, setChannels] = useState(undefined);
  useEffect(() => {
    const channels = getChannels(authToken);
    setChannels(channels);
    return () => {
      // disconnect from server and reset the channels
      channels.disconnect();
      setChannels(undefined);
    };
  }, [authUser, authToken]);
  return (
    <ChannelsContext.Provider value={channels}>
      {children}
    </ChannelsContext.Provider>
  );
};

export function useChannels() {
  const channels = useContext(ChannelsContext);
  return channels;
}

export function usePrivateChannels(authUserId) {
  const channels = useChannels();
  return useMemo(() => {
    return channels && channels.private("users." + authUserId);
  }, [channels, authUserId]);
}

function getChannels(authToken) {
  const channels = new Echo({
    broadcaster: "pusher",
    key: process.env.REACT_APP_PUSHER_APP_ID,
    wsHost: window.location.hostname,
    wsPort: 6001,
    // wssPort: 6001,
    // wsPath: process.env.NODE_ENV === "production" ? "/ws" : undefined,
    transports: ["websocket"],
    enabledTransports: ["ws", "wss"],
    forceTLS: false,
    disableStats: true,
    encrypted: true,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT}pusher/auth`,
              {
                socket_id: socketId,
                channel_name: channel.name,
              },
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }
            )
            .then((response) => {
              callback(null, { auth: response?.data?.response?.auth });
            })
            .catch((error) => {
              Notification("error", "Pusher Authentication Failed!");
              callback(error);
            });
        },
      };
    },
  });
  return channels;
}
