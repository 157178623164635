import { notification } from "antd";

const Notification = (alertType, alertMessage, description, duration = 3) => {
  const _className =
    alertType === "success"
      ? "ant-alert ant-alert-success"
      : alertType === "warning"
      ? "ant-alert ant-alert-warning"
      : alertType === "info"
      ? "ant-alert ant-alert-info"
      : "ant-alert ant-alert-error";
  notification[alertType]({
    message: alertMessage,
    className: _className,
    description,
    duration: duration,
    placement: "bottom",
  });
};

export default Notification;
