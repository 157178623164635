// CustomModalWrapper.js
import React from "react";

const CustomModalWrapper = ({ visible, children }) => {
  return (
    <>
      {visible && <div className="blur-overlay"></div>}
      <div className="modal-container">{children}</div>
    </>
  );
};

export default CustomModalWrapper;
