import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../store/global-context";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import { PAGINATION_LIMIT, STATUS } from "../../utils/constants";
import API from "../../api";
import { Table, Button, Tag, Tooltip, Empty } from "antd";
import { currencyFormatter } from "../../utils/helpers";
import useManageTableFilter from "../../hooks/useManageTableFilter";
import AddButton from "../shared/AddButton";
import Notification from "../shared/Notification";
import HelperModal from "./HelperModal";
import DisapproveConfirmationModal from "./DisapproveConfirmationModal";

const TestcaseList = () => {
  const navigate = useNavigate();
  const globalCtx = useContext(GlobalContext);
  const [addresses, setAddresses] = useState([]);
  const [totalAddress, setTotalAddress] = useState(0);
  const [payload, setPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
  });
  const [isDisapproving, setIsDisapproving] = useState(false);
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [statusData, setStatusData] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [options, setOptions] = useState({});

  const handleExpand = (record) => {
    const newExpandedRowKeys = [...expandedRowKeys];
    if (newExpandedRowKeys.includes(record._id)) {
      // Collapse if already expanded
      const index = newExpandedRowKeys.indexOf(record._id);
      newExpandedRowKeys.splice(index, 1);
    } else {
      // Expand if not expanded
      newExpandedRowKeys.push(record._id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };
  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload);

  useEffect(() => {
    globalCtx.setBreadcrumb([{ name: "Validate Test Cases" }]);
    globalCtx.setActive("1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAddresses = useCallback(() => {
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setIsLoading(true);
    API.get("addresses/list-test-cases", {
      params: { ...payload, status: 1 },
      signal: newAbortController.signal,
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((res) => {
        if (res.status) {
          setAddresses(res?.data?.response?.data || []);
          setTotalAddress(res?.data?.response?.total || 0);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (!err?.code || err?.code !== "ERR_CANCELED") {
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const changeStatus = (note = "") => {
    let bodyData = statusData;
    if (note?.length) bodyData.note = note;
    setIsLoading(true);
    setModalOpen(false);
    API.post("addresses/update-test-case", bodyData, {
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    })
      .then((result) => {
        if (result.status) {
          getAddresses();
          Notification("success", "Status has been updated successfully.");
        }
        setStatusData({});

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setStatusData({});
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (payload) {
      getAddresses();
    }
  }, [getAddresses, payload]);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const getStates = useCallback(() => {
    setIsLoading(true);
    API.get(`addresses/get-state-county`, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setOptions(result?.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const classNames = {
    0: "centered",
    1: "active centered",
    2: "error centered",
  };

  // const generateItems = (record, text) => {
  //   let items = [];

  //   items.push({
  //     key: 2,
  //     label: (
  //       <div
  //         className={`pop-over-menu-item`}
  //         onClick={() => {
  //           setModalOpen(true);
  //           setStatusData({ uuid: text, status: 2 });
  //         }}
  //         style={{ color: "rgb(220, 56, 56)" }}
  //       >
  //         <i class="fa-solid fa-xmark"></i> Disapprove
  //       </div>
  //     ),
  //   });

  //   return items;
  // };

  const columns = [
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100,
      fixed: "left",
      render: (text) => (
        <Tag className={classNames[text]}>{STATUS[text] ?? "-"}</Tag>
      ),
    },
    {
      title: "State",
      key: "State",
      dataIndex: "State",
      width: 80,
      render: (text) => (
        <div className="state-option">
          <span style={{ marginRight: "8px" }}>{text || "-"}</span>
          {!options?.states?.[text]["is_licensed"] && (
            <Tag className={"centered error"}>{"Unlicensed"}</Tag>
          )}
        </div>
      ),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "State",
        inputPlaceholder: "Search by state",
      }),
    },
    {
      title: "County",
      key: "County",
      dataIndex: "County",
      width: 150,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "County",
        inputPlaceholder: "Search by county",
      }),
    },
    {
      title: "Transaction Type",
      key: "TransactionType",
      dataIndex: "TransactionType",
      width: 200,
      ellipsis: true,
      render: (text) =>
        <div style={{ textTransform: "capitalize" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Purchase",
            value: "purchase",
          },
          {
            label: "Refinance",
            value: "refi",
          },
        ],
        paramKey: "TransactionType",
        inputPlaceholder: "Search by transaction type",
      }),
    },
    {
      title: "Property Type",
      key: "PropertyType",
      dataIndex: "PropertyType",
      ellipsis: true,
      width: 200,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: options?.property_type?.map((opt) => {
          return { label: opt, value: opt };
        }),
        paramKey: "PropertyType",
        inputPlaceholder: "Search by property type",
      }),
    },
    {
      title: "Loan Type",
      key: "LoanType",
      dataIndex: "LoanType",
      ellipsis: true,
      width: 140,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: options?.loan_type?.map((opt) => {
          return { label: opt, value: opt };
        }),
        paramKey: "LoanType",
        inputPlaceholder: "Search by loan type",
      }),
    },

    {
      title: "Purchase Price",
      key: "PurchasePrice",
      dataIndex: "PurchasePrice",
      ellipsis: true,
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "PurchasePrice",
        inputPlaceholder: "Search by purchase price",
      }),
    },
    {
      title: "Loan Amount",
      key: "LoanOneAmount",
      dataIndex: "LoanOneAmount",
      ellipsis: true,
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "LoanOneAmount",
        inputPlaceholder: "Search by loan amount",
      }),
    },
    {
      title: "Standard Total",
      key: "standardTotal",
      dataIndex: "standardTotal",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div>
          {record?.Standard?.["Total"]
            ? currencyFormatter(record?.Standard?.["Total"])
            : "-"}
        </div>
      ),
    },
    {
      title: "AOL Total",
      key: "aolTotal",
      dataIndex: "aolTotal",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div>
          {record?.AOL?.["Total"]
            ? currencyFormatter(record?.AOL?.["Total"])
            : "-"}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "uuid",
      dataIndex: "uuid",
      width: 120,
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            {/* <Dropdown
              trigger={"click"}
              menu={{ items: generateItems(record, text) }}
              placement="bottomRight"
              arrow
            >
              <Button className="gear-btn">
                <i class="fa-solid fa-gear"></i>
              </Button>
            </Dropdown> */}
            {!expandedRowKeys?.includes(record._id) ? (
              <Tooltip title={`View Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={`Hide Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            )}
            <Tooltip title={`Disapprove`}>
              <Button
                type="text"
                shape="round"
                // className={`pop-over-menu-item`}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(true);
                  setIsDisapproving(true);
                  setNote("");
                  setStatusData({ uuid: text, status: 2 });
                }}
                style={{ color: "rgb(220, 56, 56)" }}
              >
                <i class="fa-solid fa-xmark"></i>
              </Button>
            </Tooltip>
            <Tooltip title={`Note`}>
              <Button
                type="text"
                shape="round"
                // className={`pop-over-menu-item`}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(true);
                  setIsDisapproving(false);
                  setNote(record?.note);
                  setStatusData({ uuid: record?.uuid, status: record?.status });
                }}
              >
                <i class="fa-regular fa-comment-dots"></i>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  const reverifyTestCases = () => {
    setIsLoading(true);
    setModalOpen(false);
    setVerifyModalOpen(false);
    API.post(
      "addresses/verify-bestx-calculation",
      {},
      {
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
      }
    )
      .then((result) => {
        if (result.status) {
          navigate(
            `/admin/best-x/reverification/${result?.data?.response?.verify_history_uuid}`
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="content-layout">
      <DisapproveConfirmationModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        handleSave={changeStatus}
        isDisapproving={isDisapproving}
        note={note}
      />
      <HelperModal
        isOpen={verifyModalOpen}
        setOpen={setVerifyModalOpen}
        handleSave={reverifyTestCases}
        text={
          "Clicking Yes will validate Standard & AOL prices of all the approved test cases against the BestX engine and then redirect you to the result page. Are you sure you want to validate all test cases?"
        }
      />
      <div className="listing-header">
        <div className="header-left" />
        <div className="header-right">
          <AddButton
            onClick={() => {
              setVerifyModalOpen(true);
            }}
            title={"Validate Test Cases"}
            icon={<i className="fa-solid fa-check"></i>}
            hideAddIcon
          />
          <Tooltip
            title={`Clicking this button will validate Standard & AOL prices of all the below approved test cases against the BestX engine and then redirect you to the result page.`}
          >
            <div style={{ cursor: "pointer" }}>
              <i class="fa fa-circle-info"></i>{" "}
            </div>
          </Tooltip>
        </div>
      </div>
      <Table
        className="best-x-tables"
        loading={isLoading}
        columns={columns}
        sticky={true}
        scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
        dataSource={addresses}
        onChange={handleTableChange}
        pagination={{
          current: payload?.page || 1,
          pageSize: payload?.limit || PAGINATION_LIMIT,
          showTotal: (total, range) =>
            `Showing Address ${range[0]}-${range[1]} of ${total}`,
          total: totalAddress,
          showSizeChanger: true,
          onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
            setPayload((prevPayload) => ({
              ...prevPayload,
              page,
              limit: pageSize,
            })),
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Table
                title={() => <div> Standard Pricing</div>}
                style={{ width: "45%", marginTop: "16px" }}
                scroll={{ x: "max-content" }}
                dataSource={Object?.keys(record?.Standard || {}).map((val) => {
                  return { type: val, price: record?.Standard?.[val] };
                })}
                columns={[
                  { title: "Type", dataIndex: "type" },
                  { title: "Price", dataIndex: "price", width: 40 },
                ]}
                pagination={false}
                locale={{
                  emptyText: (
                    <div className="empty-table-data">
                      {record?.standard_error?.length ? (
                        record?.standard_error
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  ),
                }}
              />
              <Table
                title={() => (
                  <div className="aol-title">
                    <div style={{ marginRight: "8px" }}>AOL Pricing</div>
                    {record?.aol_ineligible ? (
                      <Tag className={"centered error"}>{"AOL Ineligible"}</Tag>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                style={{
                  width: "45%",
                  marginTop: "16px",
                  marginLeft: "16px",
                }}
                scroll={{ x: "max-content" }}
                dataSource={Object?.keys(record?.AOL || {}).map((val) => {
                  return { type: val, price: record?.AOL?.[val] };
                })}
                columns={[
                  { title: "Type", dataIndex: "type" },
                  { title: "Price", dataIndex: "price" },
                ]}
                pagination={false}
                locale={{
                  emptyText: (
                    <div className="empty-table-data">
                      {record?.aol_error?.length ? (
                        record?.aol_error
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  ),
                }}
              />
            </div>
          ),
          expandedRowKeys,
          onExpand: (expanded, record) => handleExpand(record),
          showExpandColumn: false,
        }}
        rowKey={(record) => record._id}
        rowClassName={"cursor-pointer"}
        onRow={(record) => {
          return {
            onClick: () => {
              handleExpand(record);
            },
          };
        }}
      />
    </div>
  );
};

export default TestcaseList;
