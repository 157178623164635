import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Layout, Menu, Spin } from "antd";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import GlobalContext from "./store/global-context";
import ThemeProvider from "./ThemeProvider";
import API from "./api";
import _, { cloneDeep } from "lodash";
import {
  ADMIN_SIDEBAR_MENU_ITEMS,
  STANDALONE_SIDEBAR_MENU_ITEMS,
} from "./utils/constants";
import SidebarVoxturLogo from "./assets/images/SidebarVoxturLogo.png";
import { generateRoute } from "./utils/helpers";
import SideDrawer from "./components/shared/SideDrawer";
import PageHeader from "./components/shared/PageHeader";
import { ChannelsProvider } from "./store/socket-context";
import Notification from "./components/shared/Notification";

const { Content, Sider } = Layout;

const MainLayout = () => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  // const [applyMouseCollpase, setApplyMouseCollapse] = useState(false);
  // const [initialRender, setInitialRender] = useState(true);
  const [isViewMoreMenuOpen, setIsViewMoreMenuOpen] = useState(false);
  const [layoutLoading, setLayoutLoading] = useState(false);
  const [transactionViewAbortController, setTransactionViewAbortController] =
    useState(null);
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const handleThemeChange = (checked) => {
  //   if (checked) {
  //     globalCtx.setTheme("light");
  //     API.post(`users/change-theme`, {
  //       theme: "light",
  //     }).then((result) => {
  //       if (result.status === 200) {
  //       }
  //     });
  //   } else {
  //     globalCtx.setTheme("dark");
  //     API.post(`users/change-theme`, {
  //       theme: "dark",
  //     }).then((result) => {
  //       if (result.status === 200) {
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (transactionViewAbortController && pathname) {
      transactionViewAbortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const viewTransaction = (id) => {
    if (transactionViewAbortController) {
      transactionViewAbortController.abort();
    }
    const newAbortController = new AbortController();
    setTransactionViewAbortController(newAbortController);
    setLayoutLoading(true);
    API.post(`/transaction/${id}`, undefined, {
      signal: newAbortController.signal,
    })
      .then((res) => {
        if (res.status === 200) {
          const authToken = res?.data?.response?.auth_token?.substring(7);
          navigate(
            `/standalone/transaction/${res?.data?.response?.uuid}?token=${authToken}&theme=${res?.data?.response?.theme}`
          );
        }
        setLayoutLoading(false);
      })
      .catch((err) => {
        setLayoutLoading(false);
      });
  };

  const generateItems = () => {
    let items = _.cloneDeep(STANDALONE_SIDEBAR_MENU_ITEMS);
    const preference = globalCtx?.user?.client?.feature_preference;
    if (preference) {
      if (preference === "transaction" && globalCtx?.user?.can_create_tx) {
        return items.filter(
          (menu) =>
            menu.module === "user" ||
            menu.module === "transaction" ||
            menu.module === "dashboard"
        );
      } else if (preference === "title_calculator") {
        return items.filter(
          (menu) => menu.module === "user" || menu.module === "title_calculator"
        );
      } else {
        if (!globalCtx?.user?.can_create_tx) {
          return items.filter(
            (menu) =>
              menu.module === "user" || menu.module === "title_calculator"
          );
        }
        return items;
      }
    } else {
      if (!globalCtx?.user?.can_create_tx) {
        return items.filter(
          (menu) => menu.module === "user" || menu.module === "title_calculator"
        );
      }
      return items;
    }
  };

  const resendEmail = () => {
    setLayoutLoading(true);
    API.post(`/users/resend-email-verification`, {
      email: globalCtx?.user?.email,
    })
      .then((res) => {
        if (res?.data?.status) {
          Notification("success", res?.data?.response?.message);
        } else {
          Notification("error", res?.data?.response?.message);
        }
        setLayoutLoading(false);
      })
      .catch((err) => {
        Notification("error", err?.message || "Something went wrong!");
        setLayoutLoading(false);
      });
  };

  return (
    <ThemeProvider>
      <ChannelsProvider authToken={globalCtx.token} authUser={globalCtx.user}>
        <Layout
          hasSider
          className={collapsed ? "sider-collapsed" : "sider-full"}
        >
          {/* <Drawer
            open={!collapsed}
            onClose={() => {
              setCollapsed(true);
            }}
            closable={false}
            className="nav-drawer"
            placement="left"
          > */}
          <Sider
            collapsible
            width={230}
            collapsed={collapsed}
            trigger={null}
            className={"nav-drawer"}
          >
            <div className="sidebar-header">
              <img
                className="voxtur-logo"
                src={SidebarVoxturLogo}
                alt="logo"
                style={{
                  height: collapsed ? "40px" : "50px",
                }}
              />
              <p className="voxtur-title">voxtur</p>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              className="main-menu"
              selectedKeys={globalCtx.active}
              defaultOpenKeys={
                globalCtx?.user?.client?.feature_preference ===
                "title_calculator"
                  ? ["title-calculator"]
                  : []
              }
              onOpenChange={(openKeys) => {
                if (openKeys.includes("more-submenu")) {
                  setIsViewMoreMenuOpen(true);
                } else if (isViewMoreMenuOpen) {
                  setIsViewMoreMenuOpen(false);
                }
              }}
              items={generateRoute(
                cloneDeep(
                  window.location.pathname.startsWith("/standalone")
                    ? generateItems()
                    : ADMIN_SIDEBAR_MENU_ITEMS
                ),
                globalCtx?.user?.role,
                globalCtx?.permissions,
                isViewMoreMenuOpen,
                navigate,
                () => {},
                globalCtx?.configuration
              )}
            />
          </Sider>
          {/* </Drawer> */}
          <Layout
            // className="site-layout"
            className={collapsed ? "site-layout" : "site-layout sider-full"}
          >
            {globalCtx?.user?.created_by === "self" &&
              !globalCtx?.user?.validate_email && (
                <div style={{ padding: "0px 25px" }}>
                  {" "}
                  <Alert
                    style={{
                      marginBottom:
                        pathname === "/standalone/dashboard" ? "0px" : "15px",
                    }}
                    description={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          Your email address is not verified. Click on the
                          resend email button if you have not received any email
                          yet.
                        </div>
                        <div>
                          <Button onClick={resendEmail}>Resend Email</Button>
                        </div>
                      </div>
                    }
                    type="warning"
                  />
                </div>
              )}
            <PageHeader
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              setIsSideDrawerOpen={setIsSideDrawerOpen}
              viewTransaction={viewTransaction}
            />
            <Content
              className={`site-layout-background slim-scrollbar ${
                pathname.endsWith("add") ||
                pathname.includes("edit") ||
                (pathname.includes("view") &&
                  !pathname.includes("/logs/view")) ||
                pathname.includes("change-password") ||
                pathname.endsWith("settings")
                  ? "form-layout"
                  : ""
              }`}
            >
              <Spin
                wrapperClassName="layout-loader"
                spinning={layoutLoading}
                tip="Loading..."
              >
                <Outlet context={[setCollapsed]} />
              </Spin>
            </Content>
          </Layout>
        </Layout>
      </ChannelsProvider>
      <SideDrawer
        open={isSideDrawerOpen}
        handleClose={() => setIsSideDrawerOpen(false)}
      />
    </ThemeProvider>
  );
};

export default MainLayout;
