import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import GlobalContext from "../../store/global-context";
import { currencyFormatter } from "../../utils/helpers";
import { PAGINATION_LIMIT, STATUS } from "../../utils/constants";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import API from "../../api";
import { Button, Table, Tooltip, Tag, Form, Select, Input, Empty } from "antd";
import AddButton from "../shared/AddButton";
import { useNavigate } from "react-router-dom";
import useManageTableFilter from "../../hooks/useManageTableFilter";
import HelperModal from "./HelperModal";
import Notification from "../shared/Notification";
import DisapproveConfirmationModal from "./DisapproveConfirmationModal";
import { CSVLink } from "react-csv";
// import NoteModal from "./NoteModal";
const { Option } = Select;
const classNames = {
  0: "centered",
  1: "active centered",
  2: "error centered",
};

const ImportedTestcaseList = () => {
  const csvExport = useRef();
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [newRecord, setNewRecord] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [isDisapproving, setIsDisapproving] = useState(false);
  const [totalAddress, setTotalAddress] = useState(0);
  const [payload, setPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
  });
  const [isLoading, setIsLoading] = useState(false);
  // const [abortController, setAbortController] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  // const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [note, setNote] = useState("");
  const [disapproveModalOpen, setDisapproveModalOpen] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [options, setOptions] = useState({});
  const [csvData, setCsvData] = useState([]);

  const handleExpand = (record) => {
    const newExpandedRowKeys = [...expandedRowKeys];
    if (newExpandedRowKeys.includes(record._id)) {
      // Collapse if already expanded
      const index = newExpandedRowKeys.indexOf(record._id);
      newExpandedRowKeys.splice(index, 1);
    } else {
      // Expand if not expanded
      newExpandedRowKeys.push(record._id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload);

  useEffect(() => {
    globalCtx.setBreadcrumb([{ name: "All Test Cases" }]);
    globalCtx.setActive("3");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImportAddresses = useCallback(() => {
    setIsLoading(true);
    API.get(`addresses/list-test-cases`, {
      params: payload,
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          let tempAddress = result?.data?.response?.data;
          if (newRecord?._id) tempAddress = [newRecord, ...tempAddress];
          setAddresses(tempAddress);
          setTotalAddress(result?.data?.response?.total);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  }, [payload]);

  const getCSVData = () => {
    setIsLoading(true);
    let tempPayload = payload;
    delete tempPayload.limit;
    delete tempPayload.page;
    API.get(`addresses/list-test-cases`, {
      params: payload,
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result?.data?.response?.data?.length) {
          let tempData = result?.data?.response?.data;
          let generatedData = [];
          tempData.forEach((record) => {
            generatedData.push({
              Status: STATUS[record?.status],
              State: record?.State ?? "",
              County: record?.County ?? "",
              "Transaction Type": record?.TransactionType ?? "",
              "Property Type": record?.PropertyType ?? "",
              "Loan Type": record?.LoanType ?? "",
              "Purchase Price": record?.PurchasePrice ?? "",
              "Loan Amount": record?.LoanOneAmount ?? "",
              "Standard Total": record?.Standard?.["Total"] ?? "",
              "AOL Total": record?.AOL?.["Total"] ?? "",
            });
          });
          setCsvData(generatedData);
          setTimeout(() => {
            csvExport.current.link.click();
          }, 1000);
          setIsLoading(false);
        } else {
          Notification(
            "error",
            "Something went wrong! Please try again later."
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        Notification("error", "Something went wrong! Please try again later.");
        setIsLoading(false);
      });
  };

  const getStates = useCallback(() => {
    setIsLoading(true);
    API.get(`addresses/get-state-county`, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setOptions(result?.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const changeStatus = (note = "") => {
    setIsLoading(true);
    setModalOpen(false);
    setDisapproveModalOpen(false);
    let bodyData = statusData;
    if (note?.length) bodyData.note = note;
    API.post("addresses/update-test-case", bodyData, {
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    })
      .then((result) => {
        if (result.status) {
          getImportAddresses();
          Notification("success", "Status has been updated successfully.");
        }
        setStatusData({});
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setStatusData({});
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (payload) {
      getImportAddresses();
    }
  }, [getImportAddresses, payload]);

  // useEffect(() => {
  //   return () => {
  //     if (abortController) {
  //       abortController.abort();
  //     }
  //   };
  // }, [abortController]);

  const findPricing = (note = "") => {
    setIsLoading(true);
    setModalOpen(false);
    setDisapproveModalOpen(false);
    let payload = {
      county: newRecord?.County,
      state: newRecord?.State,
      loan_type: newRecord?.LoanType,
      transaction_type: newRecord?.TransactionType,
      property_type: newRecord?.PropertyType,
      loan_amount1: newRecord?.LoanOneAmount,
      purchase_price: newRecord?.PurchasePrice,
      status: isNew && statusData?.status ? statusData?.status : "",
    };

    if (note?.length) {
      payload.note = note;
    }
    API.post("addresses/create-test-case", payload, {
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    })
      .then((result) => {
        if (result?.data?.status) {
          if (isNew) {
            setNewRecord({});
            setExpandedRowKeys((oldKeys) => {
              let tempKeys = oldKeys.filter((key) => key !== "editable");
              return tempKeys;
            });
            Notification("success", "Test case saved successfully.");
            getImportAddresses();
          } else {
            if (+result?.data?.response?.failure_count === 1) {
              setAddresses((oldAddress) => {
                let tempAddress = oldAddress;
                const idx = oldAddress.findIndex(
                  (add) => add._id === "editable"
                );
                if (idx !== -1) {
                  tempAddress[idx].Standard = {};
                  tempAddress[idx].AOL = {};
                  if (
                    result?.data?.response?.bestx_response?.aol_error?.length
                  ) {
                    tempAddress[idx].aol_error =
                      result?.data?.response?.bestx_response?.aol_error;
                  }
                  if (
                    result?.data?.response?.bestx_response?.standard_error
                      ?.length
                  ) {
                    tempAddress[idx].standard_error =
                      result?.data?.response?.bestx_response?.standard_error;
                  }
                  tempAddress[idx].aol_ineligible =
                    result?.data?.response?.bestx_response?.aol_ineligible;
                }
                return tempAddress;
              });
              Notification("error", result?.data?.response?.failed);
            } else {
              setAddresses((oldAddress) => {
                let tempAddress = oldAddress;
                const idx = oldAddress.findIndex(
                  (add) => add._id === "editable"
                );
                if (idx !== -1) {
                  tempAddress[idx].Standard =
                    result?.data?.response?.bestx_response?.Standard || {};
                  tempAddress[idx].AOL =
                    result?.data?.response?.bestx_response?.AOL || {};
                  if (
                    result?.data?.response?.bestx_response?.aol_error?.length
                  ) {
                    tempAddress[idx].aol_error =
                      result?.data?.response?.bestx_response?.aol_error;
                  }
                  if (
                    result?.data?.response?.bestx_response?.standard_error
                      ?.length
                  ) {
                    tempAddress[idx].standard_error =
                      result?.data?.response?.bestx_response?.standard_error;
                  }
                  tempAddress[idx].aol_ineligible =
                    result?.data?.response?.bestx_response?.aol_ineligible;
                }
                return tempAddress;
              });
            }
          }
        } else {
          setAddresses((oldAddress) => {
            let tempAddress = oldAddress;
            const idx = oldAddress.findIndex((add) => add._id === "editable");
            if (idx !== -1) {
              tempAddress[idx].Standard = {};
              tempAddress[idx].AOL = {};
            }
            return tempAddress;
          });
        }
        setIsNew(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setAddresses((oldAddress) => {
          let tempAddress = oldAddress;
          const idx = oldAddress.findIndex((add) => add._id === "editable");
          if (idx !== -1) {
            tempAddress[idx].Standard = {};
            tempAddress[idx].AOL = {};
          }
          return tempAddress;
        });
        setIsNew(false);
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (newRecord?.PurchasePrice?.length || newRecord?.LoanOneAmount?.length) {
      const timeout = setTimeout(() => {
        findPricing();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [newRecord]);

  // const generateItems = (record, text) => {
  //   let items = [];
  //   if (record?.status !== 1) {
  //     items.push({
  //       key: 1,
  //       label: (
  //         <div
  //           className={`pop-over-menu-item`}
  //           onClick={() => {
  //             setModalOpen(true);
  //             setStatusData({ uuid: text, status: 1 });
  //           }}
  //           style={{ color: "green" }}
  //         >
  //           <i class="fa-solid fa-check"></i> Approve
  //         </div>
  //       ),
  //     });
  //   }
  //   if (record?.status !== 2) {
  //     items.push({
  //       key: 2,
  //       label: (
  //         <div
  //           className={`pop-over-menu-item`}
  //           onClick={() => {
  //             setModalOpen(true);
  //             setStatusData({ uuid: text, status: 2 });
  //           }}
  //           style={{ color: "rgb(220, 56, 56)" }}
  //         >
  //           <i class="fa-solid fa-xmark"></i> Disapprove
  //         </div>
  //       ),
  //     });
  //   }
  //   return items;
  // };

  const fetchLatestData = (record) => {
    setIsLoading(true);
    API.get("addresses/refresh-testcase", {
      params: { uuid: record.uuid },
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    })
      .then((result) => {
        if (result?.data?.status) {
          Notification("success", result?.data?.response?.Suceess);

          getImportAddresses();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  };
  const columns = [
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100,
      fixed: "left",
      render: (text, record) =>
        record?.editable ? (
          <div>-</div>
        ) : (
          <Tag className={classNames[text]}>{STATUS[text] ?? "-"}</Tag>
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Pending Review",
            value: "0",
          },
          {
            label: "Approved",
            value: "1",
          },
          {
            label: "Disapproved",
            value: "2",
          },
        ],
        paramKey: "status",
        inputPlaceholder: "Search by status",
      }),
    },
    {
      title: "State",
      key: "State",
      dataIndex: "State",
      width: 150,
      render: (text, record) =>
        record?.editable ? (
          <Form.Item
            style={{ width: "200px" }}
            name={"state"}
            rules={[
              {
                required: true,
                message: "Please select state",
              },
            ]}
          >
            <Select
              placeholder="Please Select a state"
              allowClear
              showSearch
              value={newRecord?.State}
              onChange={(value) => {
                setNewRecord((old) => {
                  return {
                    ...old,
                    State: value,
                    County: "",
                  };
                });
              }}
              filterOption={(input, option) =>
                option?.value
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase().trim()) >= 0
              }
            >
              {Object?.keys(options?.states || {})?.map((opt) => {
                return (
                  <Option key={opt} value={opt}>
                    <div className="state-option">
                      <span style={{ marginRight: "8px" }}>{opt}</span>
                      {!options?.states?.[opt]["is_licensed"] && (
                        <Tag className={"centered error"}>{"Unlicensed"}</Tag>
                      )}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        ) : (
          <div className="state-option">
            <span style={{ marginRight: "8px" }}>{text || "-"}</span>
            {!options?.states?.[text]["is_licensed"] && (
              <Tag className={"centered error"}>{"Unlicensed"}</Tag>
            )}
          </div>
        ),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "State",
        inputPlaceholder: "Search by state",
      }),
    },
    {
      title: "County",
      key: "County",
      dataIndex: "County",
      width: 150,
      render: (text, record) =>
        record?.editable ? (
          <Select
            style={{ width: "200px" }}
            placeholder="Please Select a county"
            allowClear
            showSearch
            value={newRecord?.County}
            onChange={(value) => {
              setNewRecord({ ...newRecord, County: value });
            }}
            filterOption={(input, option) =>
              option?.value
                ?.toLowerCase()
                .indexOf(input.toLowerCase().trim()) >= 0
            }
          >
            {options?.states?.[newRecord?.State]?.county?.map((opt) => (
              <Option key={opt} value={opt}>
                {opt}
              </Option>
            ))}
          </Select>
        ) : (
          <div>{text || "-"}</div>
        ),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "County",
        inputPlaceholder: "Search by county",
      }),
    },
    {
      title: "Transaction Type",
      key: "TransactionType",
      dataIndex: "TransactionType",
      ellipsis: true,
      width: 150,
      render: (text, record) =>
        record?.editable ? (
          <Form.Item
            style={{ width: "200px" }}
            name={"TransactionType"}
            rules={[
              {
                required: true,
                message: "Please select Transaction Type",
              },
            ]}
          >
            <Select
              placeholder="Please Select a Transaction Type"
              allowClear
              showSearch
              value={newRecord?.TransactionType}
              onChange={(value) => {
                setNewRecord({ ...newRecord, TransactionType: value });
              }}
              filterOption={(input, option) =>
                option?.value
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase().trim()) >= 0
              }
            >
              {[
                {
                  label: "Purchase",
                  value: "Purchase",
                },
                {
                  label: "Refinance",
                  value: "Refinance",
                },
              ]?.map((opt) => (
                <Option key={opt?.value} value={opt?.label}>
                  {opt?.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <div style={{ textTransform: "capitalize" }}>{text}</div> || "-"
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Purchase",
            value: "purchase",
          },
          {
            label: "Refinance",
            value: "refi",
          },
        ],
        paramKey: "TransactionType",
        inputPlaceholder: "Search by transaction type",
      }),
    },
    {
      title: "Property Type",
      key: "PropertyType",
      dataIndex: "PropertyType",
      ellipsis: true,
      width: 150,
      render: (text, record) =>
        record?.editable ? (
          <Form.Item
            style={{ width: "200px" }}
            name={"PropertyType"}
            rules={[
              {
                required: true,
                message: "Please select Property Type",
              },
            ]}
          >
            <Select
              placeholder="Please Select a Property Type"
              allowClear
              showSearch
              value={newRecord?.PropertyType}
              onChange={(value) => {
                setNewRecord({ ...newRecord, PropertyType: value });
              }}
              filterOption={(input, option) =>
                option?.value
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase().trim()) >= 0
              }
            >
              {options?.property_type?.map((opt) => (
                <Option key={opt} value={opt}>
                  {opt}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <div style={{ textTransform: "uppercase" }}>{text}</div> || "-"
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: options?.property_type?.map((opt) => {
          return { label: opt, value: opt };
        }),
        paramKey: "PropertyType",
        inputPlaceholder: "Search by property type",
      }),
    },
    {
      title: "Loan Type",
      key: "LoanType",
      dataIndex: "LoanType",
      ellipsis: true,
      width: 150,
      render: (text, record) =>
        record?.editable ? (
          <Form.Item
            style={{ width: "200px" }}
            name={"LoanType"}
            rules={[
              {
                required: true,
                message: "Please select Loan Type",
              },
            ]}
          >
            <Select
              placeholder="Please Select a Loan Type"
              allowClear
              showSearch
              value={newRecord?.LoanType}
              onChange={(value) => {
                setNewRecord({ ...newRecord, LoanType: value });
              }}
              filterOption={(input, option) =>
                option?.value
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase().trim()) >= 0
              }
            >
              {options?.loan_type?.map((opt) => (
                <Option key={opt} value={opt}>
                  {opt}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <div style={{ textTransform: "uppercase" }}>{text}</div> || "-"
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: options?.loan_type?.map((opt) => {
          return { label: opt, value: opt };
        }),
        paramKey: "LoanType",
        inputPlaceholder: "Search by loan type",
      }),
    },
    // Table.EXPAND_COLUMN,
    // {
    //   title: "Standard/AOL Pricing",
    //   ellipsis: true,
    //   key: "standard",
    //   dataIndex: "standard",
    //   width: 200,
    //   render: (text) => <div>{"Click + to see pricing"}</div> || "-",
    // },
    {
      title: "Purchase Price",
      key: "PurchasePrice",
      dataIndex: "PurchasePrice",
      ellipsis: true,
      width: 150,
      render: (text, record) =>
        record?.editable ? (
          <Form.Item
            style={{ width: "200px" }}
            name={"PurchasePrice"}
            rules={[
              {
                required: true,
                message: "Please enter Purchase Price",
              },
            ]}
          >
            <Input
              type="number"
              value={newRecord?.PurchasePrice}
              onChange={(e) => {
                setNewRecord({ ...newRecord, PurchasePrice: e.target.value });
              }}
              placeholder="Enter Purchase Price"
            />
          </Form.Item>
        ) : (
          <div>{text ? currencyFormatter(text) : "-"}</div>
        ),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "PurchasePrice",
        inputPlaceholder: "Search by purchase price",
      }),
    },
    {
      title: "Loan Amount",
      key: "LoanOneAmount",
      dataIndex: "LoanOneAmount",
      ellipsis: true,
      width: 150,
      render: (text, record) =>
        record?.editable ? (
          <Form.Item
            style={{ width: "200px" }}
            name={"LoanOneAmount"}
            rules={[
              {
                required: true,
                message: "Please enter Loan Amount",
              },
            ]}
          >
            <Input
              type="number"
              value={newRecord?.LoanOneAmount}
              onChange={(e) => {
                setNewRecord({ ...newRecord, LoanOneAmount: e.target.value });
              }}
              placeholder="Enter Loan Amount"
            />
          </Form.Item>
        ) : (
          <div>{text ? currencyFormatter(text) : "-"}</div>
        ),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "LoanOneAmount",
        inputPlaceholder: "Search by loan amount",
      }),
    },
    {
      title: "Standard Total",
      key: "standardTotal",
      dataIndex: "standardTotal",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div>
          {record?.Standard?.["Total"]
            ? currencyFormatter(record?.Standard?.["Total"])
            : "-"}
        </div>
      ),
    },
    {
      title: "AOL Total",
      key: "aolTotal",
      dataIndex: "aolTotal",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div>
          {record?.AOL?.["Total"]
            ? currencyFormatter(record?.AOL?.["Total"])
            : "-"}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "uuid",
      dataIndex: "uuid",
      width: 120,
      render: (text, record) =>
        record?.editable ? (
          <div style={{ display: "flex" }}>
            {!expandedRowKeys?.includes(record._id) ? (
              <Tooltip title={`View Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={`Hide Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            )}
            <Tooltip title={`Approve`}>
              <Button
                type="text"
                shape="round"
                onClick={() => {
                  setModalOpen(true);
                  setIsNew(true);
                  setStatusData({ uuid: text, status: 1 });
                }}
              >
                <i class="fa-solid fa-check"></i>
              </Button>
            </Tooltip>

            <Tooltip title={`Disapprove`}>
              <Button
                type="text"
                shape="round"
                onClick={() => {
                  setIsNew(true);
                  setIsDisapproving(true);
                  setDisapproveModalOpen(true);
                  setNote("");
                  setStatusData({ uuid: text, status: 2 });
                }}
                style={{ color: "rgb(220, 56, 56)" }}
              >
                <i class="fa-solid fa-xmark"></i>
              </Button>
            </Tooltip>
            <Tooltip title={`Discard new test case.`}>
              <Button
                type="text"
                shape="round"
                onClick={() => {
                  setNewRecord({});
                  setAddresses((oldAddress) => {
                    let tempAddress = oldAddress.filter(
                      (add) => add._id !== "editable"
                    );
                    return tempAddress;
                  });
                }}
                style={{ color: "rgb(220, 56, 56)" }}
              >
                <i class="fa fa-trash"></i>
              </Button>
            </Tooltip>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            {!expandedRowKeys?.includes(record._id) ? (
              <Tooltip title={`View Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={`Hide Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            )}
            {record?.status !== 1 ? (
              <Tooltip title={`Approve`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsNew(false);
                    setModalOpen(true);
                    setStatusData({ uuid: text, status: 1 });
                  }}
                >
                  <i class="fa-solid fa-check"></i>
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {record?.status !== 2 ? (
              <Tooltip title={`Disapprove`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsNew(false);
                    setIsDisapproving(true);
                    setDisapproveModalOpen(true);
                    setNote("");
                    setStatusData({ uuid: text, status: 2 });
                  }}
                  style={{ color: "rgb(220, 56, 56)" }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {record?.status === 2 ? (
              <div>
                <Tooltip title={`Fetch latest data`}>
                  <Button
                    type="text"
                    shape="round"
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchLatestData(record);
                      // setModalOpen(true);
                      // setStatusData({ uuid: text, status: 2 });
                    }}
                  >
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </Button>
                </Tooltip>
              </div>
            ) : (
              ""
            )}

            <div>
              <Tooltip title={`Notes`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDisapproveModalOpen(true);
                    setIsDisapproving(false);
                    setStatusData({
                      uuid: record?.uuid,
                      status: record?.status,
                    });
                    setNote(record?.note);
                  }}
                >
                  <i class="fa-regular fa-comment-dots"></i>
                </Button>
              </Tooltip>
            </div>

            {/* <Dropdown
              trigger={"click"}
              menu={{ items: generateItems(record, text) }}
              placement="bottomRight"
              arrow
            >
              <Button className="gear-btn">
                <i class="fa-solid fa-gear"></i>
              </Button>
            </Dropdown> */}
          </div>
        ),
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  const handleAdd = () => {
    const newData = {
      _id: "editable",
      editable: "true",
      State: "",
      County: "",
      TransactionType: "",
      LoanType: "",
      PropertyType: "",
      PurchasePrice: "",
      LoanOneAmount: "",
      LoanTwoAmount: null,
      Standard: {},
      AOL: {},
      status: "",
    };
    setExpandedRowKeys([...expandedRowKeys, "editable"]);
    setAddresses([newData, ...addresses]);
    setNewRecord(newData);
  };

  return (
    <div className="content-layout">
      <HelperModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        handleSave={isNew ? findPricing : changeStatus}
        text={`Are you sure you want to ${
          statusData?.status === 1 ? "approve" : "disapprove"
        } the test case?`}
        setIsNew={setIsNew}
      />
      {/* <NoteModal
        isOpen={noteModalOpen}
        setOpen={setNoteModalOpen}
        text={note}
      /> */}
      <DisapproveConfirmationModal
        isOpen={disapproveModalOpen}
        setOpen={setDisapproveModalOpen}
        handleSave={isNew ? findPricing : changeStatus}
        setIsNew={setIsNew}
        isDisapproving={isDisapproving}
        note={note}
      />
      <div className="listing-header">
        <div className="header-left" />

        <div className="header-right">
          <Button
            onClick={handleAdd}
            className={newRecord?._id ? `add-btn disabled-btn` : "add-btn"}
            hideAddIcon
            disabled={newRecord?._id}
          >
            <i className="fa-solid fa-plus" style={{ marginRight: "8px" }}></i>
            Add Test Case
          </Button>
          <CSVLink
            data={csvData}
            filename="bestx.csv"
            ref={csvExport}
            style={{ display: "none" }}
          ></CSVLink>
          <Button onClick={getCSVData} className={"add-btn"} hideAddIcon>
            <i
              className="fa-solid fa-file-export"
              style={{ marginRight: "8px" }}
            ></i>
            {"Export CSV"}
          </Button>
          <AddButton
            onClick={() => navigate("/admin/best-x/import/add")}
            title={"Import Test Cases"}
            icon={<i className="fa-solid fa-cloud-arrow-down"></i>}
            hideAddIcon
          />
        </div>
      </div>
      <Table
        className="best-x-tables"
        loading={isLoading}
        columns={columns}
        scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
        dataSource={addresses}
        onChange={handleTableChange}
        pagination={{
          current: payload?.page || 1,
          pageSize: payload?.limit || PAGINATION_LIMIT,
          total: totalAddress,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing Address ${range[0]}-${range[1]} of ${total}`,
          onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
            setPayload((prevPayload) => ({
              ...prevPayload,
              page,
              limit: pageSize,
            })),
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Table
                title={() => <div> Standard Pricing</div>}
                style={{ width: "45%", marginTop: "16px" }}
                scroll={{ x: "max-content" }}
                dataSource={Object?.keys(record?.Standard || {}).map((val) => {
                  return { type: val, price: record?.Standard?.[val] };
                })}
                columns={[
                  { title: "Type", dataIndex: "type" },
                  { title: "Price", dataIndex: "price" },
                ]}
                locale={{
                  emptyText: (
                    <div className="empty-table-data">
                      {record?.standard_error?.length ? (
                        record?.standard_error
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  ),
                }}
                pagination={false}
              />
              <Table
                title={() => (
                  <div className="aol-title">
                    <div style={{ marginRight: "8px" }}>AOL Pricing</div>
                    {record?.aol_ineligible ? (
                      <Tag className={"centered error"}>{"AOL Ineligible"}</Tag>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                style={{
                  width: "45%",
                  marginTop: "16px",
                  marginLeft: "16px",
                }}
                scroll={{ x: "max-content" }}
                dataSource={Object?.keys(record?.AOL || {}).map((val) => {
                  return { type: val, price: record?.AOL?.[val] };
                })}
                locale={{
                  emptyText: (
                    <div className="empty-table-data">
                      {record?.aol_error?.length ? (
                        record?.aol_error
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  ),
                }}
                columns={[
                  { title: "Type", dataIndex: "type" },
                  { title: "Price", dataIndex: "price" },
                ]}
                pagination={false}
              />
            </div>
          ),
          expandedRowKeys,
          onExpand: (expanded, record) => handleExpand(record),
          showExpandColumn: false,
        }}
        rowKey={(record) => record._id}
        rowClassName={"cursor-pointer"}
        onRow={(record) => {
          return {
            onClick: () => {
              !record?.editable && handleExpand(record);
            },
          };
        }}
      />
    </div>
  );
};
export default ImportedTestcaseList;
