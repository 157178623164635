import moment from "moment";
import { v4 as uuid } from "uuid";

export const subjectPropertyElements = [
  {
    child: 0,
    element_name: "address",
    element_id: uuid(),
    element_type: "T",
    formatting: "text",
    label: "Address",
    options: [],
    parent_element: null,
    parent_value: null,
    provider_id: "SubjectProperty.Address1",
    required: true,
    updatable_stages: "1,2",
    user_value: "",
    validation: "[^()]",
  },
  {
    child: 0,
    element_id: uuid(),
    element_name: "address2",
    element_type: "T",
    formatting: "text",
    label: "Address2",
    options: [],
    parent_element: null,
    parent_value: null,
    provider_id: "SubjectProperty.Address2",
    required: false,
    updatable_stages: "1,2",
    user_value: "",
    validation: "[^()]",
  },
  {
    child: 0,
    element_id: uuid(),
    element_name: "city",
    element_type: "T",
    formatting: "text",
    label: "City",
    options: [],
    parent_element: null,
    parent_value: null,
    provider_id: "SubjectProperty.City",
    required: true,
    updatable_stages: "1,2",
    user_value: "",
    validation: "[^()]",
  },
  {
    child: 0,
    element_id: uuid(),
    element_name: "state",
    element_type: "S",
    formatting: null,
    label: "State",
    options: [
      {
        option_name: "AK",
        option_default: false,
      },
      {
        option_name: "AL",
        option_default: false,
      },
      {
        option_name: "AR",
        option_default: false,
      },
      {
        option_name: "AS",
        option_default: false,
      },
      {
        option_name: "AZ",
        option_default: false,
      },
      {
        option_name: "CA",
        option_default: false,
      },
      {
        option_name: "CO",
        option_default: false,
      },
      {
        option_name: "CT",
        option_default: false,
      },
      {
        option_name: "DC",
        option_default: false,
      },
      {
        option_name: "DE",
        option_default: false,
      },
      {
        option_name: "FL",
        option_default: false,
      },
      {
        option_name: "GA",
        option_default: false,
      },
      {
        option_name: "GU",
        option_default: false,
      },
      {
        option_name: "HI",
        option_default: false,
      },
      {
        option_name: "IA",
        option_default: false,
      },
      {
        option_name: "ID",
        option_default: false,
      },
      {
        option_name: "IL",
        option_default: false,
      },
      {
        option_name: "IN",
        option_default: false,
      },
      {
        option_name: "KS",
        option_default: false,
      },
      {
        option_name: "KY",
        option_default: false,
      },
      {
        option_name: "LA",
        option_default: false,
      },
      {
        option_name: "MA",
        option_default: false,
      },
      {
        option_name: "MD",
        option_default: false,
      },
      {
        option_name: "ME",
        option_default: false,
      },
      {
        option_name: "MI",
        option_default: false,
      },
      {
        option_name: "MN",
        option_default: false,
      },
      {
        option_name: "MO",
        option_default: false,
      },
      {
        option_name: "MP",
        option_default: false,
      },
      {
        option_name: "MS",
        option_default: false,
      },
      {
        option_name: "MT",
        option_default: false,
      },
      {
        option_name: "NC",
        option_default: false,
      },
      {
        option_name: "ND",
        option_default: false,
      },
      {
        option_name: "NE",
        option_default: false,
      },
      {
        option_name: "NH",
        option_default: false,
      },
      {
        option_name: "NJ",
        option_default: false,
      },
      {
        option_name: "NM",
        option_default: false,
      },
      {
        option_name: "NV",
        option_default: false,
      },
      {
        option_name: "NY",
        option_default: false,
      },
      {
        option_name: "OH",
        option_default: false,
      },
      {
        option_name: "OK",
        option_default: false,
      },
      {
        option_name: "OR",
        option_default: false,
      },
      {
        option_name: "PA",
        option_default: false,
      },
      {
        option_name: "PR",
        option_default: false,
      },
      {
        option_name: "PW",
        option_default: false,
      },
      {
        option_name: "RI",
        option_default: false,
      },
      {
        option_name: "SC",
        option_default: false,
      },
      {
        option_name: "SD",
        option_default: false,
      },
      {
        option_name: "TN",
        option_default: false,
      },
      {
        option_name: "TX",
        option_default: false,
      },
      {
        option_name: "UT",
        option_default: false,
      },
      {
        option_name: "VA",
        option_default: false,
      },
      {
        option_name: "VI",
        option_default: false,
      },
      {
        option_name: "VT",
        option_default: false,
      },
      {
        option_name: "WA",
        option_default: false,
      },
      {
        option_name: "WI",
        option_default: false,
      },
      {
        option_name: "WV",
        option_default: false,
      },
      {
        option_name: "WY",
        option_default: false,
      },
    ],
    parent_element: null,
    parent_value: null,
    provider_id: "SubjectProperty.State",
    required: true,
    updatable_stages: "1,2",
    user_value: "",
    validation: "[^()]",
  },
  {
    child: 0,
    element_id: uuid(),
    element_name: "zip",
    element_type: "T",
    formatting: "zip",
    label: "Zip",
    options: [],
    parent_element: null,
    parent_value: null,
    provider_id: "SubjectProperty.PostalCode",
    required: true,
    updatable_stages: "1,2",
    user_value: "",
    validation: "(\\d{5})",
  },
  {
    child: 0,
    element_id: uuid(),
    element_name: "property_type",
    element_type: "S",
    formatting: null,
    label: "Property Type",
    options: [
      {
        option_name: "Single Family",
        option_default: false,
      },
      {
        option_name: "Condominium",
        option_default: false,
      },
      {
        option_name: "Cooperative",
        option_default: false,
      },
      {
        option_name: "Manufactured Home",
        option_default: false,
      },
      {
        option_name: "Two To Four Family",
        option_default: false,
      },
      {
        option_name: "Other",
        option_default: false,
      },
    ],
    parent_element: null,
    parent_value: null,
    provider_id: "SubjectProperty.PropertyType",
    required: true,
    updatable_stages: "1,2",
    user_value: "",
    validation: "[^()]",
  },
];

export const DEFAULT_SECTIONS = [
  {
    section_label: "General",
    section_id: uuid(),
    section_type: "generic",
    elements: [
      {
        element_name: "",
        element_id: uuid(),
        label: "",
        element_type: null,
        provider_id: "",
        formatting: null,
        validation: "",
        options: [],
        updatable_stages: null,
        required: false,
        parent_element: null,
        parent_value: null,
        child: 0,
        user_value: "",
      },
    ],
  },
  {
    section_label: "Subject Property",
    section_id: uuid(),
    section_type: "property",
    elements: subjectPropertyElements,
  },
];

export const DEFAULT_SECTION_FORMAT = {
  section_label: "",
  section_id: uuid(),
  section_type: "generic",
  elements: [
    {
      element_name: "",
      element_id: uuid(),
      label: "",
      element_type: null,
      provider_id: "",
      formatting: null,
      validation: "",
      options: [],
      updatable_stages: null,
      required: false,
      parent_element: null,
      parent_value: null,
      child: 0,
      user_value: "",
    },
  ],
};

export const DEFAULT_SUBJECT_PROPERTY_FIELDS = [
  "address",
  // "address2",
  "city",
  "state",
  "zip",
];

export const PAGINATION_LIMIT = 100;

export const PERMISSIONS_VALUES = {
  FULL: "full",
  VIEW_ONLY: "view_only",
  ADD_UPDATE_ONLY: "add_update_only",
  NONE: "none",
};

export const MODULE_VALUES = {
  DASHBOARD: "dashboard",
  PROVIDER_SETUP: "provider_setup",
  MASTER_FORM_CONFIGURATION: "master_form_configuration",
  PARTNER_SETUP: "partner_setup",
  FIELD_MAPPING: "field_mapping",
  PRODUCT: "product",
  CLIENT: "client",
  TRANSACTION: "transaction",
  USER: "user",
  ROLE: "role",
  APPROVAL: "approval",
  REPORT: "report",
  CATEGORY: "product_category",
  INTEGRATION_LOGS: "integration_logs",
  AUDIT_TRAILS: "audit_trails",
  TITLE_CALCULATOR: "title_calculator",
  TITLE_CALCULATOR_ADMIN: "title_calculator_admin",
};

export const ALLOWED_MODULE_FOR_STANDALONE_CONFIGURATIONS = Object.values(
  MODULE_VALUES
).filter(
  (module) =>
    module !== MODULE_VALUES.PARTNER_SETUP &&
    module !== MODULE_VALUES.FIELD_MAPPING &&
    module !== MODULE_VALUES.ROLE &&
    module !== MODULE_VALUES.REPORT
);

export const ALLOWED_MODULE_FOR_LOS_CONFIGURATIONS = Object.values(
  MODULE_VALUES
).filter((module) => module !== MODULE_VALUES.APPROVAL);

// CATEGORY OBJECT VALUE MUST BE SAME AS THE MODULE CATEGORIES OBJECT KEY
export const MODULE_CATEGORIES = {
  LOGS: {
    label: "Transaction and Activity Logs",
    icon: <i className="fa-solid fa-arrow-right-arrow-left"></i>,
    value: "LOGS",
  },
  CONFIGURATIONS: {
    label: "Configurations",
    icon: <i className="fa-solid fa-gears"></i>,
    value: "CONFIGURATIONS",
  },
  TRANSACTIONS: {
    label: "Transactions",
    icon: <i className="fa-solid fa-arrow-right-arrow-left"></i>,
    value: "TRANSACTIONS",
  },
  TITLE_CALCULATOR: {
    label: "Title Calculator",
    icon: <i className="fa-solid fa-calculator"></i>,
    value: "title_calculator",
  },
  ACCOUNT_MANAGEMENT: {
    label: "Account Management",
    icon: <i className="fa-solid fa-user-gear"></i>,
    value: "ACCOUNT_MANAGEMENT",
  },
  NOTIFICATIONS: {
    label: "Notifications",
    icon: <i className="fa-solid fa-bell"></i>,
    value: "NOTIFICATIONS",
  },
};
export const ADMIN_SIDEBAR_MENU_ITEMS = [
  {
    key: "1",
    icon: <i className="fa-solid fa-house"></i>,
    label: "Dashboard",
    route: "/admin/dashboard",
    module: MODULE_VALUES.DASHBOARD,
  },
  {
    key: "3-1",
    icon: <i className="fa-solid fa-hand-holding-hand"></i>,
    label: "Provider",
    module: [
      MODULE_VALUES.PROVIDER_SETUP,
      MODULE_VALUES.MASTER_FORM_CONFIGURATION,
    ],
    children: [
      {
        key: "3",
        label: "Provider Setup",
        module: MODULE_VALUES.PROVIDER_SETUP,
        category: MODULE_CATEGORIES.CONFIGURATIONS.value,
        icon: <i className="fa-solid fa-gear"></i>,
        route: "/admin/provider?status=active",
        className: "ml-10 w-95",
      },
      {
        key: "4",
        label: "Master Form Configuration",
        module: MODULE_VALUES.MASTER_FORM_CONFIGURATION,
        category: MODULE_CATEGORIES.CONFIGURATIONS.value,
        title: "Master Form Configuration",
        icon: <i className="fa-solid fa-list-check"></i>,
        route: "/admin/provider/form?is_draft=false",
        className: "ml-10 w-95",
      },
    ],
  },
  {
    key: "2-1",
    icon: <i className="fa-solid fa-handshake"></i>,
    label: "Partner",
    module: [MODULE_VALUES.PARTNER_SETUP, MODULE_VALUES.FIELD_MAPPING],
    children: [
      {
        key: "2",
        label: "Partner Setup",
        module: MODULE_VALUES.PARTNER_SETUP,
        category: MODULE_CATEGORIES.CONFIGURATIONS.value,
        icon: <i className="fa-solid fa-gear"></i>,
        route: "/admin/partner?status=active",
        className: "ml-10 w-95",
      },
      {
        key: "5",
        label: "Field Mapping",
        module: MODULE_VALUES.FIELD_MAPPING,
        category: MODULE_CATEGORIES.CONFIGURATIONS.value,
        icon: <i className="fa-solid fa-shuffle"></i>,
        route: "/admin/partner/field_map",
        className: "ml-10 w-95",
      },
    ],
  },
  {
    key: "6-1",
    icon: <i className="fa-solid fa-square-parking"></i>,
    label: "Product",
    module: [MODULE_VALUES.PRODUCT, MODULE_VALUES.CATEGORY],
    children: [
      {
        key: "15",
        label: "Category",
        module: MODULE_VALUES.CATEGORY,
        category: MODULE_CATEGORIES.CONFIGURATIONS.value,
        icon: <i className="fa-brands fa-buffer"></i>,
        route: "/admin/product/categories",
        className: "ml-10 w-95",
      },
      // {
      //   key: "6",
      //   label: "Product Setup",
      //   module: MODULE_VALUES.PRODUCT,
      //   category: MODULE_CATEGORIES.CONFIGURATIONS.value,
      //   icon: <i className="fa-solid fa-gear"></i>,
      //   route: "/admin/product",
      //   className: "ml-10 w-95",
      // },
    ],
  },
  // {
  //   key: "7",
  //   icon: <i className="fa-solid fa-person-circle-question"></i>,
  //   label: "Product Questions",
  //   onClick: (e) => {
  //     navigate("/admin/product/questions");
  //   },
  // },
  {
    key: "8",
    icon: <i className="fa-solid fa-user-tie"></i>,
    label: "Client",
    module: MODULE_VALUES.CLIENT,
    category: MODULE_CATEGORIES.CONFIGURATIONS.value,
    route: "/admin/client",
  },
  {
    key: "9",
    icon: <i className="fa-solid fa-exchange"></i>,
    label: "Transactions",
    module: MODULE_VALUES.TRANSACTION,
    category: MODULE_CATEGORIES.LOGS.value,
    route: "/admin/transactions",
  },
  {
    key: "10-1",
    icon: <i className="fa-solid fa-user"></i>,
    label: "User",
    module: [MODULE_VALUES.USER, MODULE_VALUES.ROLE],
    children: [
      {
        key: "10",
        icon: <i className="fa-solid fa-gear"></i>,
        label: "User Setup",
        module: MODULE_VALUES.USER,
        category: MODULE_CATEGORIES.ACCOUNT_MANAGEMENT.value,
        route: "/admin/user?status=active",
        className: "ml-10 w-95",
      },
      // {
      //   key: "17",
      //   icon: <i className="fa-solid fa-person-circle-check"></i>,
      //   label: "Signup Users",
      //   module: MODULE_VALUES.APPROVAL,
      //   category: MODULE_CATEGORIES.ACCOUNT_MANAGEMENT.value,
      //   route: "/admin/approvals?status=approved",
      //   className: "ml-10 w-95",
      // },
      {
        key: "13",
        icon: <i className="fa-solid fa-user-gear"></i>,
        label: "Roles",
        module: MODULE_VALUES.ROLE,
        category: MODULE_CATEGORIES.ACCOUNT_MANAGEMENT.value,
        route: "/admin/roles",
        className: "ml-10 w-95",
      },
    ],
  },
  {
    key: "11",
    icon: <i className="fa-solid fa-list"></i>,
    label: "Report",
    module: [MODULE_VALUES.REPORT],
    children: [
      {
        key: "12",
        label: "Request Report",
        module: MODULE_VALUES.REPORT,
        category: MODULE_CATEGORIES.LOGS.value,
        icon: <i className="fa-solid fa-chart-simple"></i>,
        route: `/admin/report/inboundRequests?start_date=${moment().format(
          "YYYY-MM-DD"
        )}&end_date=${moment().format("YYYY-MM-DD")}&event=all`,
        className: "ml-10 w-95",
      },
    ],
  },
  {
    key: "14",
    icon: <i className="fa-solid fa-chart-line"></i>,
    label: "Integration Logs",
    module: MODULE_VALUES.INTEGRATION_LOGS,
    category: MODULE_CATEGORIES.LOGS.value,
    route: "/admin/logs",
  },
  {
    key: "16",
    icon: <i className="fa-solid fa-chalkboard-user"></i>,
    label: "Audit Trails",
    module: MODULE_VALUES.AUDIT_TRAILS,
    category: MODULE_CATEGORIES.LOGS.value,
    route: "/admin/audit-trails",
  },
  {
    key: "18",
    icon: <i className="fa fa-calculator" aria-hidden="true"></i>,
    label: "Title Calculator History",
    title: "Title Calculator History",
    route: "/admin/title-calculator/history",
    module: MODULE_VALUES.TITLE_CALCULATOR_ADMIN,
  },
];

export const STANDALONE_SIDEBAR_MENU_ITEMS = [
  {
    key: "1",
    icon: <i className="fa-solid fa-house"></i>,
    label: "Dashboard",
    route: "/standalone/dashboard",
    module: "dashboard",
  },
  {
    key: "10",
    icon: <i className="fa-solid fa-user"></i>,
    label: "User",
    route: "/standalone/user",
    module: "user",
    category: MODULE_CATEGORIES.ACCOUNT_MANAGEMENT.value,
  },
  {
    key: "transaction-submenu",
    icon: <i className="fa-solid fa-shuffle"></i>,
    label: "Transactions",
    module: "transaction",
    children: [
      {
        key: "2",
        icon: <i className="fa-solid fa-arrow-right-arrow-left"></i>,
        label: "All Transactions",
        route: "/standalone/transaction",
        module: "transaction",
        category: MODULE_CATEGORIES.TRANSACTIONS.value,
        className: "ml-10 w-95",
      },
      {
        key: "3",
        icon: <i className="fa-solid fa-not-equal"></i>,
        label: "Not Submitted Transactions",
        title: "Not Submitted Transactions",
        route: "/standalone/transaction/not-submitted",
        module: "transaction",
        category: MODULE_CATEGORIES.TRANSACTIONS.value,
        className: "ml-10 w-95",
      },
      {
        key: "4",
        icon: <i className="fa-regular fa-hourglass"></i>,
        label: "Open Transactions",
        route: "/standalone/transaction/open",
        module: "transaction",
        category: MODULE_CATEGORIES.TRANSACTIONS.value,
        className: "ml-10 w-95",
      },
      {
        key: "6",
        label: "Completed Transactions",
        title: "Completed Transactions",
        icon: <i className="fa-regular fa-circle-check"></i>,
        route: "/standalone/transaction/completed",
        category: MODULE_CATEGORIES.TRANSACTIONS.value,
        className: "ml-10 w-95",
      },
      {
        key: "5",
        label: "Cancelled Transactions",
        title: "Cancelled Transactions",
        icon: <i className="fa-solid fa-xmark"></i>,
        route: "/standalone/transaction/cancelled",
        category: MODULE_CATEGORIES.TRANSACTIONS.value,
        className: "ml-10 w-95",
      },
    ],
  },
  {
    key: "11",
    icon: <i className="fa-solid fa-calculator"></i>,
    label: "Quick Calculator",
    title: "Quick Calculator",
    route: "/standalone/title-calculator/quick",
    category: MODULE_CATEGORIES.TITLE_CALCULATOR.value,
    module: "title_calculator",
  },
  {
    key: "12",
    icon: <i className="fa-solid fa-clock-rotate-left"></i>,
    label: "Quote History",
    title: "Quote History",
    route: "/standalone/title-calculator/history",
    category: MODULE_CATEGORIES.TITLE_CALCULATOR.value,
    module: "title_calculator",
  },
  {
    // key: "title-calculator",
    // icon: <i className="fa-solid fa-calculator"></i>,
    // label: "Title Calculator",
    // module: "title_calculator",
    // children: [
    // {
    //   key: "11",
    //   icon: <i className="fa-solid fa-calculator"></i>,
    //   label: "Quick Calculator",
    //   title: "Quick Calculator",
    //   route: "/standalone/title-calculator/quick",
    //   category: MODULE_CATEGORIES.TITLE_CALCULATOR.value,
    //   module: "title_calculator",
    //   className: "ml-10 w-95",
    // },
    // {
    //   key: "12",
    //   icon: <i className="fa-solid fa-clock-rotate-left"></i>,
    //   label: "Quote History",
    //   title: "Quote History",
    //   route: "/standalone/title-calculator/history",
    //   category: MODULE_CATEGORIES.TITLE_CALCULATOR.value,
    //   module: "title_calculator",
    //   className: "ml-10 w-95",
    // },
    // ],
  },
  // {
  //   key: "17",
  //   icon: <i className="fa fa-calculator" aria-hidden="true"></i>,
  //   label: "Title Calculator",
  //   route: "/standalone/title-calculator",
  //   module: "title",
  // },
];

export const ADDRESS_SIDEBAR_MENU_ITEMS = [
  {
    key: "1",
    icon: <i className="fa-solid fa-map-location"></i>,
    label: "Addresses",
    route: "/admin/addresses",
    module: "addresses",
  },
  {
    key: "2",
    icon: <i className="fa-solid fa-cloud-arrow-down"></i>,
    label: "Import Address",
    route: "/admin/addresses/import/add",
    module: "addresses",
  },
  {
    key: "3",
    icon: <i className="fa-solid fa-clock-rotate-left"></i>,
    label: "Import History",
    route: "/admin/addresses/import",
    module: "addresses",
  },
];

export const BESTX_SIDEBAR_MENU_ITEMS = [
  {
    key: "3",
    icon: <i className="fa-solid fa-bars-staggered"></i>,
    label: "All Test Cases",
    route: "/admin/best-x/import",
    module: "bestx",
    title: "All Test Cases",
  },
  {
    key: "1",
    icon: <i className="fa-solid fa-map-location"></i>,
    label: "Validate Test Cases",
    route: "/admin/best-x",
    module: "bestx",
    title: "Validate Test Cases",
  },
  {
    key: "4",
    icon: <i className="fa-solid fa-check"></i>,
    label: "Validate history",
    route: "/admin/best-x/reverification",
    module: "bestx",
    title: "Validate history",
  },
];

export const USER_ROLES = [
  {
    label: "Admin User",
    value: "admin",
  },
  {
    label: "Custom Role",
    value: "custom",
  },
  {
    label: "Standalone Admin",
    value: "standalone",
  },
  {
    label: "Standalone User",
    value: "standalone_user",
  },
];

export const DEFAULT_ROLE_PERMISSIONS_DATA = [
  {
    module: MODULE_VALUES.PROVIDER_SETUP,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.MASTER_FORM_CONFIGURATION,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.PARTNER_SETUP,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.FIELD_MAPPING,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.PRODUCT,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.CATEGORY,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.CLIENT,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.USER,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.ROLE,
    permission: PERMISSIONS_VALUES.FULL,
  },
  {
    module: MODULE_VALUES.TRANSACTION,
    permission: PERMISSIONS_VALUES.VIEW_ONLY,
  },
  {
    module: MODULE_VALUES.REPORT,
    permission: PERMISSIONS_VALUES.VIEW_ONLY,
  },
  {
    module: MODULE_VALUES.INTEGRATION_LOGS,
    permission: PERMISSIONS_VALUES.VIEW_ONLY,
  },
];

export const noPermissionMsg =
  "You don't have a permission to perform this action.";

export const PROVIDER_EVENTS = {
  SUBMITTED: "Submitted",
  DELAY: "DELAY",
  RESUME: "RESUME",
  REPORT: "REPORT - Final Delivery",
  REVISION: "REVISION",
  REVISION_ACCEPT: "REVISION-ACCEPT",
  REVISION_REJECT: "REVISION-REJECT",
  CANCELLATION: "CANCELLATION",
  CANCELLATION_ACCEPT: "CANCELLATION-ACCEPT",
  CANCELLATION_REJECT: "CANCELLATION-REJECT",
  OTHER: "OTHER",
};

export const EXECUTION_REQUESTS = {
  NEW_COMMENT: "NEW_COMMENT",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  UPDATE_VIEW_ORDER: "UPDATE_VIEW_ORDER",
  HOLD: "HOLD",
  CANCEL: "CANCEL",
  MAKE_REVISION: "MAKE_REVISION",
};

export const DEFAULT_EVENT_DATA = Object.values(PROVIDER_EVENTS).map(
  (event) => ({
    name: event,
    execution_requests: Object.values(EXECUTION_REQUESTS).reduce(
      (prev, val) => ({ ...prev, [val]: false }),
      {}
    ),
  })
);

export const ACTIVITY_DATE_TIME_BE_FORMAT = "MMMM DD, YYYY - hh:mma";

export const PUSHER_EVENTS = {
  NOTIFICATIONS: ".notification",
};

export const IGNORED_FIELDS_IN_HEADER_RESET_FILTERS = [
  "page",
  "limit",
  "status",
  "is_draft",
  "configuration_type",
  "platform",
  "user_type",
  "hide_spammer",
];

export const IGNORED_FILTERS_IN_URL = ["page", "limit", "hide_spammer"];

export const DEFAULT_TIMEZONE = "CST";

export const ALLOWED_FILE_TYPES = [
  {
    label: "PDF",
    value: "pdf",
  },
  {
    label: "Word Documents",
    value: "word_documents",
  },
  {
    label: "Excel Spreadsheets",
    value: "excel",
  },
  {
    label: "Image",
    value: "image",
  },
  {
    label: "JSON",
    value: "json",
  },
  {
    label: "XML",
    value: "xml",
  },
  {
    label: "CSV",
    value: "csv",
  },
  {
    label: "Text",
    value: "text",
  },
  {
    label: "PPT",
    value: "ppt",
  },
];

export const AUDIT_TRAILS_ACTIVITY_LIST = [
  {
    label: "Add Provider",
    value: "Add Provider",
  },
  {
    label: "Update Provider",
    value: "Update Provider",
  },
  {
    label: "Delete Provider",
    value: "Delete Provider",
  },
  {
    label: "Update Provider Event",
    value: "Update Provider Event",
  },
  {
    label: "Change Provider Status",
    value: "Change Provider Status",
  },
  {
    label: "Add Master Form Configuration",
    value: "Add Master Form Configuration",
  },
  {
    label: "Update Master Form Configuration",
    value: "Update Master Form Configuration",
  },
  {
    label: "Delete Master Form Configuration",
    value: "Delete Master Form Configuration",
  },
  {
    label: "Add Partner",
    value: "Add Partner",
  },
  {
    label: "Update Partner",
    value: "Update Partner",
  },
  {
    label: "Delete Partner",
    value: "Delete Partner",
  },
  {
    label: "Change Partner Status",
    value: "Change Partner Status",
  },
  {
    label: "Update Partner Field",
    value: "Update Partner Field",
  },
  {
    label: "Add Field Mapping",
    value: "Add Field Mapping",
  },
  {
    label: "Update Field Mapping",
    value: "Update Field Mapping",
  },
  {
    label: "Delete Field Mapping",
    value: "Delete Field Mapping",
  },
  {
    label: "Add Category",
    value: "Add Category",
  },
  {
    label: "Update Category",
    value: "Update Category",
  },
  {
    label: "Delete Category",
    value: "Delete Category",
  },
  {
    label: "Add Product",
    value: "Add Product",
  },
  {
    label: "Update Product",
    value: "Update Product",
  },
  {
    label: "Delete Product",
    value: "Delete Product",
  },
  {
    label: "Add Client",
    value: "Add Client",
  },
  {
    label: "Update Client",
    value: "Update Client",
  },
  {
    label: "Delete Client",
    value: "Delete Client",
  },
  {
    label: "Add User",
    value: "Add User",
  },

  {
    label: "Update User",
    value: "Update User",
  },
  {
    label: "Delete User",
    value: "Delete User",
  },
  {
    label: "Change User Status",
    value: "Change User Status",
  },
  {
    label: "Add Role",
    value: "Add Role",
  },
  {
    label: "Update Role",
    value: "Update Role",
  },
  {
    label: "Delete Role",
    value: "Delete Role",
  },
  {
    label: "Login",
    value: "Login",
  },
  {
    label: "Logout",
    value: "Logout",
  },
  {
    label: "Change Password",
    value: "Change Password",
  },
];

export const ADDRESS_FIELDS_MAPPING = [
  {
    label: "-- Do not map this field --",
    value: "",
    required: false,
  },
  {
    label: "Address",
    value: "address",
    required: true,
  },
  {
    label: "City",
    value: "city",
    required: true,
  },
  {
    label: "State",
    value: "state",
    required: true,
  },
  {
    label: "Zip",
    value: "zip",
    required: true,
  },
  {
    label: "County",
    value: "county",
    required: true,
  },
  {
    label: "Transaction Type",
    value: "transaction_type",
    required: true,
  },
  {
    label: "Property Type",
    value: "property_type",
    required: true,
  },
  {
    label: "Loan Type",
    value: "loan_type",
    required: true,
  },
  {
    label: "Purchase Price",
    value: "purchase_price",
    required: true,
  },
  {
    label: "Loan Amount",
    value: "loan_amount",
    required: true,
  },
  {
    label: "HOA",
    value: "hoa",
    required: true,
  },
  {
    label: "AOL",
    value: "aol",
    required: true,
  },
];

export const ADDRESS_ROUTE_PATHS = [
  "/admin/addresses",
  "/admin/addresses/import",
  "/admin/addresses/import/add",
];

export const TRANSACTION_STATUSES = [
  {
    label: "Not Submitted",
    value: "Not Submitted",
  },
  {
    label: "Submitted",
    value: "Submitted",
  },
  {
    label: "REPORT - Final Delivery",
    value: "REPORT - Final Delivery",
  },
  {
    label: "ORDER-RECEIVED",
    value: "ORDER-RECEIVED",
  },
  {
    label: "DELAY",
    value: "DELAY",
  },
  {
    label: "RESUME",
    value: "RESUME",
  },
  {
    label: "REVISION",
    value: "REVISION",
  },
  {
    label: "REVISION-ACCEPT",
    value: "REVISION-ACCEPT",
  },
  {
    label: "CANCELLATION",
    value: "CANCELLATION",
  },
  {
    label: "CANCELLATION-ACCEPT",
    value: "CANCELLATION-ACCEPT",
  },
  {
    label: "CANCELLATION-REJECT",
    value: "CANCELLATION-REJECT",
  },
];

export const TRANSACTION_TYPE_NAME = "loan_purpose_type";

export const STATUS = { 0: "Pending Review", 1: "Approved", 2: "Disapproved" };
export const APPROVAL_STATUS = {
  0: "Pending Approval",
  1: "Approved",
  2: "Disapproved",
};

export const EMAIL_VERIFICATION_STATUS = {
  0: "Non-verified",
  1: "Verified",
};


export const STATE_OPTIONS = [
  {
    option_name: "AK",
    option_default: false,
  },
  {
    option_name: "AL",
    option_default: false,
  },
  {
    option_name: "AR",
    option_default: false,
  },
  {
    option_name: "AS",
    option_default: false,
  },
  {
    option_name: "AZ",
    option_default: false,
  },
  {
    option_name: "CA",
    option_default: false,
  },
  {
    option_name: "CO",
    option_default: false,
  },
  {
    option_name: "CT",
    option_default: false,
  },
  {
    option_name: "DC",
    option_default: false,
  },
  {
    option_name: "DE",
    option_default: false,
  },
  {
    option_name: "FL",
    option_default: false,
  },
  {
    option_name: "GA",
    option_default: false,
  },
  {
    option_name: "GU",
    option_default: false,
  },
  {
    option_name: "HI",
    option_default: false,
  },
  {
    option_name: "IA",
    option_default: false,
  },
  {
    option_name: "ID",
    option_default: false,
  },
  {
    option_name: "IL",
    option_default: false,
  },
  {
    option_name: "IN",
    option_default: false,
  },
  {
    option_name: "KS",
    option_default: false,
  },
  {
    option_name: "KY",
    option_default: false,
  },
  {
    option_name: "LA",
    option_default: false,
  },
  {
    option_name: "MA",
    option_default: false,
  },
  {
    option_name: "MD",
    option_default: false,
  },
  {
    option_name: "ME",
    option_default: false,
  },
  {
    option_name: "MI",
    option_default: false,
  },
  {
    option_name: "MN",
    option_default: false,
  },
  {
    option_name: "MO",
    option_default: false,
  },
  {
    option_name: "MP",
    option_default: false,
  },
  {
    option_name: "MS",
    option_default: false,
  },
  {
    option_name: "MT",
    option_default: false,
  },
  {
    option_name: "NC",
    option_default: false,
  },
  {
    option_name: "ND",
    option_default: false,
  },
  {
    option_name: "NE",
    option_default: false,
  },
  {
    option_name: "NH",
    option_default: false,
  },
  {
    option_name: "NJ",
    option_default: false,
  },
  {
    option_name: "NM",
    option_default: false,
  },
  {
    option_name: "NV",
    option_default: false,
  },
  {
    option_name: "NY",
    option_default: false,
  },
  {
    option_name: "OH",
    option_default: false,
  },
  {
    option_name: "OK",
    option_default: false,
  },
  {
    option_name: "OR",
    option_default: false,
  },
  {
    option_name: "PA",
    option_default: false,
  },
  {
    option_name: "PR",
    option_default: false,
  },
  {
    option_name: "PW",
    option_default: false,
  },
  {
    option_name: "RI",
    option_default: false,
  },
  {
    option_name: "SC",
    option_default: false,
  },
  {
    option_name: "SD",
    option_default: false,
  },
  {
    option_name: "TN",
    option_default: false,
  },
  {
    option_name: "TX",
    option_default: false,
  },
  {
    option_name: "UT",
    option_default: false,
  },
  {
    option_name: "VA",
    option_default: false,
  },
  {
    option_name: "VI",
    option_default: false,
  },
  {
    option_name: "VT",
    option_default: false,
  },
  {
    option_name: "WA",
    option_default: false,
  },
  {
    option_name: "WI",
    option_default: false,
  },
  {
    option_name: "WV",
    option_default: false,
  },
  {
    option_name: "WY",
    option_default: false,
  },
];


export const FIELD_MAPPING_LIST = [
  { label: "Transaction Type", key: "transaction_type" },
  { label: "Purchase Price", key: "purchase_price" },
  { label: "Loan Type", key: "loan_type" },
  { label: "Loan Amount", key: "loan_amount" },
  { label: "Loan Number", key: "loan_number" },
  { label: "Address", key: "address" },
  { label: "Address 2", key: "address2" },
  { label: "Unit No", key: "unit" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip Code", key: "zip" },
  { label: "Property Type", key: "property_type" },
  { label: "County", key: "county" },
];


export const TRANSACTION_TYPE_OPTIONS = [
  { label: "Purchase (Mortgage)", value: "Purchase (Mortgage)" },
  { label: "Purchase (Cash)", value: "Purchase (Cash)" },
  { label: "Refinance - No Cash Out", value: "Refinance - No Cash Out" },
  { label: "Refinance - Cash Out", value: "Refinance - Cash Out" },
  { label: "Other", value: "Other" },
];

export const LOAN_TYPE_OPTIONS = [
  { label: "Conventional", value: "Conventional" },
  { label: "FHA", value: "FHA" },
  { label: "VA", value: "VA" },
  { label: "USDA", value: "USDA" },
  { label: "Equity/HELOC", value: "Equity/HELOC" },
  { label: "Other", value: "Other" },
];

export const PROPERTY_TYPE_OPTIONS = [
  { label: "Single Family Residence", value: "Single Family Residence" },
  { label: "Two Units", value: "Two Units" },
  { label: "Three Units", value: "Three Units" },
  { label: "Four Units", value: "Four Units" },
  { label: "PUD", value: "PUD" },
  { label: "Condo", value: "Condo" },
  { label: "CoOp", value: "CoOp" },
  { label: "Raw/Unimprove", value: "Raw/Unimprove" },
  { label: "Vacant Land", value: "Vacant Land" },
  { label: "Mobile Home", value: "Mobile Home" },
  { label: "Modular", value: "Modular" },
];

export const customTestWords = [
  "test",
  "dummy",
  "sample",
  "example",
  "placeholder",
  "temp",
  "testing",
  "lorem",
  "ipsum",
  "mock",
  "filler",
  "demo",
  "fake",
  "bogus",
  "trial",
  "practice",
  "prototype",
  "alpha",
  "beta",
  "junk",
  "abc",
];
