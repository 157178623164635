import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import GlobalContext from "../../store/global-context";
import { getLocalStorageToken } from "../../utils/helpers";

const ProtectedRoute = () => {
  let { token, setToken } = useContext(GlobalContext);
  if (!token) {
    token = getLocalStorageToken();
  }

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token, setToken]);

  return token ? <Outlet /> : <Navigate to={"login"} />;
};

export default ProtectedRoute;
