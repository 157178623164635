import { Layout, Menu } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "./components/shared/PageHeader";
import GlobalContext from "./store/global-context";
import Loader from "./components/shared/Loader";
import { generateRoute } from "./utils/helpers";
import { cloneDeep } from "lodash";
import SidebarVoxturLogo from "./assets/images/SidebarVoxturLogo.png";
import {
  ADDRESS_SIDEBAR_MENU_ITEMS,
  BESTX_SIDEBAR_MENU_ITEMS,
} from "./utils/constants";

const { Content, Sider } = Layout;

const PublicRouteLayout = () => {
  const globalCtx = useContext(GlobalContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [showUI, setShowUI] = useState(false);

  const identifier = pathname.split("/")?.[2];

  const menuItems = (() => {
    if (identifier === "addresses") {
      return ADDRESS_SIDEBAR_MENU_ITEMS;
    } else if (identifier === "best-x") {
      return BESTX_SIDEBAR_MENU_ITEMS;
    } else {
      return ADDRESS_SIDEBAR_MENU_ITEMS;
    }
  })();

  useEffect(() => {
    globalCtx.setTheme("light");
    document.body.classList.add("new-light");
    setTimeout(() => {
      setShowUI(true);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !showUI ? (
    <Loader />
  ) : (
    <Layout hasSider className={collapsed ? "sider-collapsed" : "sider-full"}>
      {/* <Drawer
        open={!collapsed}
        onClose={() => {
          setCollapsed(true);
        }}
        closable={false}
        className="nav-drawer"
        placement="left"
        width={250}
      > */}
      <Sider
        collapsible
        width={230}
        collapsed={collapsed}
        trigger={null}
        className={"nav-drawer"}
      >
        <div className="sidebar-header">
          <img className="voxtur-logo" src={SidebarVoxturLogo} alt="logo" />
          <p className="voxtur-title">voxtur</p>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="main-menu"
          selectedKeys={globalCtx.active}
          items={generateRoute(
            cloneDeep(menuItems),
            "public",
            undefined,
            undefined,
            navigate,
            () => {}
          )}
        />
        {/* </Drawer> */}
      </Sider>
      <Layout
        // className="site-layout"
        className={collapsed ? "site-layout" : "site-layout sider-full"}
      >
        <PageHeader
          hideHeaderItems
          showImportAddressButton
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Content
          className={`site-layout-background slim-scrollbar ${
            pathname.endsWith("add") ? "form-layout" : ""
          }`}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PublicRouteLayout;
