import { Modal } from "antd";
import React from "react";

const OrderCreateConfirmation = ({
  isOpen,
  setOpen,
  handleSave,
  loading = false,
}) => {
  const handleClose = () => {
    setOpen(false);
    handleSave(false);
  };
  return (
    <Modal
      destroyOnClose
      open={isOpen}
      title={"Confirmation"}
      onCancel={handleClose}
      onOk={() => {
        setOpen(false);
        handleSave(true);
      }}
      okText="Yes"
      cancelText="No"
      confirmLoading={loading}
      className="question-alert-pop-up"
    >
      <div className="alert-msg">{"Do you want to place a title order?"}</div>
    </Modal>
  );
};
export default OrderCreateConfirmation;
