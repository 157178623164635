import { useContext, useEffect } from "react";
import GlobalContext from "../store/global-context";
import {
  IGNORED_FIELDS_IN_HEADER_RESET_FILTERS,
  IGNORED_FILTERS_IN_URL,
  PAGINATION_LIMIT,
} from "../utils/constants";
import { cloneDeep, has, map as lodashMap } from "lodash";
import { useSearchParams } from "react-router-dom";
import { generateQueryParamsFromPayload } from "../utils/helpers";

const useManageTableFilter = (
  payload,
  setPayload,
  defaultPayload,
  disableHeaderFilter
) => {
  const globalCtx = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const payloadObject = {};
  for (let p of searchParams) {
    if (
      !IGNORED_FILTERS_IN_URL.filter((item) =>
        !window.location.pathname.includes("/standalone")
          ? item !== "status"
          : true
      ).includes(p?.[0])
    ) {
      payloadObject[p?.[0]] = p[1];
    }
  }

  const queryString = lodashMap(
    payloadObject,
    (value, key) => `${key}=${value || " "}`
  ).join("&");

  useEffect(() => {
    const filteredPayload = cloneDeep(payload);

    IGNORED_FILTERS_IN_URL.filter((item) =>
      !window.location.pathname.includes("/standalone")
        ? item !== "status"
        : true
    ).forEach((key) => {
      if (filteredPayload?.[key]) {
        delete filteredPayload?.[key];
      }
    });

    const payloadQueryString = lodashMap(
      filteredPayload,
      (value, key) => `${key}=${value || " "}`
    ).join("&");

    if (queryString !== payloadQueryString) {
      setPayload((prevPayload) => ({
        ...payloadObject,
        page: 1,
        limit: prevPayload?.limit || PAGINATION_LIMIT,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (payload) {
      const paginationChanged =
        Object.keys(payload).filter(
          (key) =>
            !IGNORED_FIELDS_IN_HEADER_RESET_FILTERS.includes(key) &&
            payload[key]
        ).length > 0;

      if (paginationChanged && !disableHeaderFilter) {
        globalCtx.setResetFilterFunction(() => {
          const newPayload = {
            page: payload?.page || 1,
            limit: payload?.limit || PAGINATION_LIMIT,
          };
          if (has(payload, "status")) {
            newPayload.status = payload.status || undefined;
          }
          if (has(payload, "is_draft")) {
            newPayload.is_draft = payload.is_draft || undefined;
          }
          setSearchParams(generateQueryParamsFromPayload(newPayload));
        });
      }
      return () => {
        globalCtx.setResetFilterFunction();
      };
    } else if (!queryString.trim()) {
      const queryParamsObject = generateQueryParamsFromPayload(defaultPayload);
      if (Object.keys(queryParamsObject).length > 0) {
        setSearchParams(queryParamsObject);
      } else {
        setPayload({ page: 1, limit: PAGINATION_LIMIT });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, setPayload]);

  return null;
};

export default useManageTableFilter;
