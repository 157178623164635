import React from "react";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";

const LogAccordion = ({ payload, payloadType, index }) => {
  return (
    <div className="accordion-content">
      {payloadType === "xml" ? (
        <XMLViewer
          xml={payload}
          collapsible
          theme={{
            tagColor: "#d0d0d0",
            textColor: "#fc6d24",
            attributeKeyColor: "#d381c3",
            attributeValueColor: "#6fb3d2",
            separatorColor: "#d0d0d0",
          }}
        />
      ) : payloadType === "json" ? (
        <ReactJson
          src={typeof payload === "string" ? JSON.parse(payload) : payload}
          collapsed="1"
          theme="bright"
          name={null}
        />
      ) : (
        <div className="details">{payload}</div>
      )}
    </div>
  );
};

export default LogAccordion;
