import React, { useCallback, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Divider,
  Checkbox,
  Select,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import LoginVoxturImage from "./../../assets/images/login-icon.png";
import ReactInputMask from "react-input-mask";
import API from "../../api";
import Notification from "../shared/Notification";
import { STATE_OPTIONS } from "../../utils/constants";
import OrderCreateConfirmation from "./OrderCreateConfirmation";

const { Option } = Select;
const SignUp = () => {
  const { search } = useLocation();
  const client = new URLSearchParams(search).get("client");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [options, setOptions] = useState([]);

  const contactData = JSON.parse(localStorage.getItem("SWBTitleContactInfo"));

  useEffect(() => {
    if (Object.keys(contactData || {})?.length) {
      form.setFieldsValue({ ...contactData });
    }
  }, [contactData]);

  const getStates = useCallback(() => {
    setIsLoading(true);
    API.get(`addresses/get-state-county`, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          let stateOptions = [];
          for (let key of Object.keys(result?.data?.states)) {
            stateOptions.push({
              label: result?.data?.states?.[key]?.full_name,
              value: key,
            });
          }
          setOptions(stateOptions);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const handleSubmit = (can_create_tx = false) => {
    setIsLoading(true);
    API.post(
      "signup",
      {
        ...submitData,
        phone: submitData.phone ? submitData?.phone?.replace(/\D/g, "") : "",
        client: client ? client : "",
        can_create_tx,
      },
      { baseURL: process.env.REACT_APP_API_ENDPOINT }
    )
      .then((result) => {
        if (result.status === 200) {
          Notification("success", result.data.response.message);
          localStorage.removeItem("SWBTitleContactInfo");
          navigate("/login");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleContinue = (data) => {
    setIsOpen(true);
    setSubmitData(data);
  };

  return (
    <div className="login-container sign-up">
      <OrderCreateConfirmation
        isOpen={isOpen}
        setOpen={setIsOpen}
        handleSave={handleSubmit}
      />
      <div className="login-wrapper">
        <div className="login-form">
          <div className="login-info">
            <div className="login-icon-container" style={{ marginTop: "8px" }}>
              <img
                className="login-icon"
                src={LoginVoxturImage}
                alt="Voxtur Login"
              />
            </div>
            {client ? (
              <div className="d-flex-center">
                <span className="client-name"> Client Name : {client}</span>
              </div>
            ) : (
              ""
            )}
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              layout={"vertical"}
              form={form}
              name="login"
              onFinish={handleContinue}
              className="custom-scrollbar signup-form"
            >
              <Row
                gutter={10}
                type="flex"
                className="custom-scrollbar-row"
                style={{
                  maxHeight: "65vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Col md={24} sm={24}>
                  {" "}
                  <Divider
                    orientation="left"
                    style={{ color: "#1976d2", borderTopColor: "#cacaca" }}
                  >
                    User Information
                  </Divider>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"first_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                    ]}
                    label="First Name"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"last_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                    ]}
                    label="Last Name"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter email",
                      },
                      {
                        type: "email",
                        message: "Please enter valid email",
                      },
                    ]}
                    label="Email"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item name={"phone"} label="Phone Number">
                    <ReactInputMask
                      className="ant-input phone-input"
                      // name="phone"
                      style={{ marginBottom: "0.5rem" }}
                      placeholder="(___) ___-____"
                      mask={"(999) 999-9999"}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter password",
                      },
                    ]}
                    label="Password"
                  >
                    <Input.Password autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"password_confirmation"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter password confirmation",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Password do not match!")
                          );
                        },
                      }),
                    ]}
                    label="Confirm Password"
                  >
                    <Input.Password autoComplete="new-password" />
                  </Form.Item>
                </Col>{" "}
                {/* <Col xl={12} lg={12} md={12} sm={24}>
                  <Form.Item name={"can_create_tx"} valuePropName={"checked"}>
                    <Checkbox>Do you want to create transaction?</Checkbox>
                  </Form.Item>
                </Col> */}
                <Col md={24} sm={24}>
                  {" "}
                  <Divider
                    orientation="left"
                    style={{ color: "#1976d2", borderTopColor: "#cacaca" }}
                  >
                    {" "}
                    Company Information
                  </Divider>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter company name",
                      },
                    ]}
                    label="Company Name"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                {/* <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_license"}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter license number",
                    //   },
                    // ]}
                    label="License Number"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_license_expired_date"}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter expiration date",
                    //   },
                    // ]}
                    label="Expiration Date"
                  >
                    <DatePicker
                      className="date-picker-signup"
                      format={"MM-DD-yyyy"}
                    />
                  </Form.Item>
                </Col> */}
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_address"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter address",
                      },
                    ]}
                    label="Address"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_address2"}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter address",
                    //   },
                    // ]}
                    label="Address 2"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_city"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter city",
                      },
                    ]}
                    label="City"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_state"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter state",
                      },
                    ]}
                    label="State"
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase().trim()) >= 0
                      }
                      loading={isLoading}
                    >
                      {options?.map((opt) => (
                        <Option key={opt.value} value={opt.value}>
                          {opt.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Item
                    name={"company_zip"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter zip code",
                      },
                    ]}
                    label="Zip Code"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  md={24}
                  sm={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className="login-btn"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Sign Up
                  </Button>
                </Col>
                <Col
                  md={24}
                  sm={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  Already have an account?{" "}
                  <div
                    style={{
                      color: "#1976d2",
                      marginLeft: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
