import { Modal } from "antd";
import React from "react";

const AlertModal = ({
  isOpen,
  setOpen,
  handleSave,
  text,
  loading = false,
  setIsNew = () => {},
}) => {
  const handleClose = () => {
    setIsNew(false);
    setOpen(false);
  };
  return (
    <Modal
      destroyOnClose
      open={isOpen}
      title={"Confirmation"}
      onCancel={handleClose}
      onOk={handleSave}
      okText="Yes"
      confirmLoading={loading}
      className="question-alert-pop-up"
    >
      <div className="alert-msg">{text}</div>
    </Modal>
  );
};
export default AlertModal;
