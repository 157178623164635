import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import API from "./api";
import Loader from "./components/shared/Loader";
import GlobalContext from "./store/global-context";
import { useLocation } from "react-router-dom";
import { STANDALONE_SIDEBAR_MENU_ITEMS } from "./utils/constants";
import { checkIfRouteIsAvailable, filterModule } from "./utils/helpers";
import ConfigurationSelectionModal from "./components/shared/ConfigurationSelectionModal";

const ThemeProvider = ({ children }) => {
  const location = useLocation();
  const globalCtx = useContext(GlobalContext);
  const [showUI, setShowUI] = useState(false);
  const [isConfigSelectionModalVisible, setIsConfigSelectionModalVisible] =
    useState();

  const initLayout = async () => {
    try {
      const userResponse = await API.get("user-info", {
        params: {
          login_type: globalCtx?.user?.role
            ? globalCtx?.user?.role
            : window.location.pathname.startsWith("/standalone")
            ? "standalone"
            : "admin",
        },
      });

      if (userResponse.status === 200) {
        globalCtx.setUser(userResponse.data.response);
        globalCtx.setPermissions(
          userResponse?.data?.response?.permissions?.permissions
        );
        if (!window.location.pathname.startsWith("/standalone")) {
          if (
            userResponse?.data?.response?.role === "admin" &&
            !localStorage.getItem("configuration")
          ) {
            globalCtx.setConfiguration("");
          } else if (userResponse?.data?.response?.role === "custom") {
            globalCtx.setConfiguration(
              userResponse?.data?.response?.configuration_type
                ? userResponse?.data?.response?.configuration_type
                : "los"
            );
          } else {
            const configuration =
              localStorage.getItem("configuration") === "standalone"
                ? "standalone"
                : "los";

            globalCtx.setConfiguration(configuration);
          }
        }
        // if (userResponse.data.response.theme === "dark") {
        //   globalCtx.setTheme("dark");
        // } else {
        globalCtx.setTheme("light");
        // }
      }
      const preference =
        userResponse?.data?.response?.client?.feature_preference;
      const canCreateTx = userResponse?.data?.response?.can_create_tx;

      if (
        (preference ? preference !== "title_calculator" : true) &&
        canCreateTx &&
        location.pathname.includes("standalone")
      ) {
        const transactionResponse = await API.get("transactions", {
          params: {
            limit: 1,
            page: 1,
          },
        });

        const notificationResponse = await API.get("notifications", {
          params: { unread: true },
        });

        if (
          transactionResponse.status === 200 &&
          transactionResponse.data.response.total === 0
        ) {
          globalCtx.setHasZeroTransactions(true);
          globalCtx.setShowWelcomePopup(true);
        } else {
          globalCtx.setHasZeroTransactions(false);
        }
        if (notificationResponse.status === 200) {
          globalCtx.setNotifications(
            notificationResponse?.data?.response || []
          );
        }
      }

      setShowUI(true);
    } catch (error) {
      setShowUI(true);
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    if (globalCtx.token) initLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalCtx.token]);

  useEffect(() => {
    if (
      globalCtx?.user?.role === "admin" &&
      !globalCtx.configuration &&
      showUI
    ) {
      setIsConfigSelectionModalVisible(true);
    }
  }, [globalCtx.configuration, globalCtx?.user?.role, showUI]);

  useEffect(() => {
    if (
      location.pathname !== "/standalone" &&
      location.pathname !== "/standalone/dashboard" &&
      checkIfRouteIsAvailable(
        globalCtx.user.role === "standalone_user"
          ? filterModule(
              STANDALONE_SIDEBAR_MENU_ITEMS,
              (route) => !["user"].includes(route.module)
            )
          : STANDALONE_SIDEBAR_MENU_ITEMS,
        location.pathname
      )
    ) {
      globalCtx.setShowWelcomePopup(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, globalCtx.showWelcomePopup]);

  useEffect(() => {
    // if (!window.location.pathname.startsWith("/standalone")) {
    //   document.body.classList.remove("new-light");

    //   if (globalCtx.theme === "dark") {
    //     document.body.classList.add("dark");
    //     document.body.classList.remove("light");
    //   } else {
    //     document.body.classList.add("light");
    //     document.body.classList.remove("dark");
    //   }
    // } else {
    // if (globalCtx.theme === "dark") {
    //   document.body.classList.add("new-dark");
    //   document.body.classList.remove("new-light");
    // } else {
    document.body.classList.add("new-light");
    // document.body.classList.remove("new-dark");
    // }
    // }
  }, [globalCtx.theme]);

  return !showUI ? (
    <Loader />
  ) : (
    <>
      {children}
      <ConfigurationSelectionModal
        isOpen={isConfigSelectionModalVisible}
        handleClose={() => setIsConfigSelectionModalVisible(false)}
      />{" "}
    </>
  );
};

export default ThemeProvider;
