import React from "react";
import { useContext } from "react";
import GlobalContext from "../../store/global-context";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Spin, Table, Tag, Tooltip, Button, Empty } from "antd";
import { currencyFormatter } from "../../utils/helpers";
import { useState } from "react";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { useCallback } from "react";
import API from "../../api";
import useManageTableFilter from "../../hooks/useManageTableFilter";
import Notification from "../shared/Notification";
import HelperModal from "./HelperModal";

const TestcaseDetails = () => {
  const globalCtx = useContext(GlobalContext);
  const { id } = useParams();
  const [payload, setPayload] = useState();
  const [importDetails, setImportDetails] = useState();
  const [addresses, setAddresses] = useState([]);
  const [totalAddress, setTotalAddress] = useState(0);
  const [addressLoading, setAddressLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [options, setOptions] = useState({});

  const handleExpand = (record) => {
    const newExpandedRowKeys = [...expandedRowKeys];
    if (newExpandedRowKeys.includes(record._id)) {
      // Collapse if already expanded
      const index = newExpandedRowKeys.indexOf(record._id);
      newExpandedRowKeys.splice(index, 1);
    } else {
      // Expand if not expanded
      newExpandedRowKeys.push(record._id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };
  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload, {
    page: 1,
    limit: PAGINATION_LIMIT,
  });

  const getTestcaseDetails = useCallback(() => {
    setIsLoading(true);
    API.get("addresses/verify-history", {
      params: { uuid: id },
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setImportDetails(result?.data?.response?.data[0]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // navigate(-1);
        setIsLoading(false);
        setImportDetails(false);
      });
  }, [id]);

  const getTestcaseRecords = useCallback(() => {
    setAddressLoading(true);
    API.get(`addresses/test-case-history`, {
      params: { ...payload, verify_history: id },
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setAddresses(result?.data?.response?.data);
          setTotalAddress(result?.data?.response?.total);
        }
        setAddressLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setAddressLoading(false);
      });
  }, [id, payload]);

  const changeStatus = () => {
    setAddressLoading(true);
    setModalOpen(false);
    API.post("addresses/update-test-case", statusData, {
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    })
      .then((result) => {
        if (result.status) {
          getTestcaseRecords();
          Notification("success", "Status has been updated successfully.");
        }
        setStatusData({});
        setAddressLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setStatusData({});
        setAddressLoading(false);
      });
  };

  useEffect(() => {
    globalCtx.setBreadcrumb([
      {
        name: "Verify Test Cases",
        route: "/admin/best-x/reverification",
      },
      { name: id },
    ]);
    globalCtx.setActive("4");
    getTestcaseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getTestcaseDetails]);

  useEffect(() => {
    if (payload) {
      getTestcaseRecords();
    }
  }, [getTestcaseRecords, id, payload]);

  const getStates = useCallback(() => {
    setIsLoading(true);
    API.get(`addresses/get-state-county`, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status) {
          setOptions(result?.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getStates();
  }, [getStates]);
  const classNames = {
    success: "active centered",
    failed: "error centered",
  };
  const columns = [
    {
      title: "Result",
      key: "result",
      dataIndex: "result",
      width: 100,
      fixed: "left",
      render: (text) => (
        <Tag className={classNames[text]}>
          {text?.charAt(0).toUpperCase() + text.slice(1) ?? "-"}
        </Tag>
      ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Success",
            value: "success",
          },
          {
            label: "Fail",
            value: "failed",
          },
        ],
        paramKey: "result",
        inputPlaceholder: "Search by result",
      }),
    },
    {
      title: "State",
      key: "State",
      dataIndex: "testcase.State",
      width: 80,
      render: (_, record) => (
        <div className="state-option">
          <span style={{ marginRight: "8px" }}>
            {record?.testcase?.State || "-"}
          </span>
          {!options?.states?.[record?.testcase?.State]?.["is_licensed"] && (
            <Tag className={"centered error"}>{"Unlicensed"}</Tag>
          )}
        </div>
      ),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "State",
        inputPlaceholder: "Search by state",
      }),
    },
    {
      title: "County",
      key: "County",
      dataIndex: "County",
      width: 150,
      render: (_, record) => record.testcase.County || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "County",
        inputPlaceholder: "Search by county",
      }),
    },
    {
      title: "Transaction Type",
      key: "TransactionType",
      dataIndex: "TransactionType",
      ellipsis: true,
      width: 220,
      render: (_, record) => record.testcase.TransactionType || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Purchase",
            value: "purchase",
          },
          {
            label: "Refinance",
            value: "refi",
          },
        ],
        paramKey: "TransactionType",
        inputPlaceholder: "Search by transaction type",
      }),
    },
    {
      title: "Property Type",
      key: "PropertyType",
      dataIndex: "PropertyType",
      ellipsis: true,
      width: 180,
      render: (_, record) => record.testcase.PropertyType || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: options?.property_type?.map((opt) => {
          return { label: opt, value: opt };
        }),
        paramKey: "PropertyType",
        inputPlaceholder: "Search by property type",
      }),
    },
    {
      title: "Loan Type",
      key: "LoanType",
      dataIndex: "LoanType",
      ellipsis: true,
      width: 140,
      render: (_, record) => record.testcase.LoanType || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: options?.loan_type?.map((opt) => {
          return { label: opt, value: opt };
        }),
        paramKey: "LoanType",
        inputPlaceholder: "Search by loan type",
      }),
    },
    // Table.EXPAND_COLUMN,
    // {
    //   title: "Standard/AOL Pricing",
    //   ellipsis: true,
    //   key: "standard",
    //   dataIndex: "standard",
    //   width: 200,
    //   render: (text) => <div>{"Click + to see pricing"}</div> || "-",
    // },
    {
      title: "Purchase Price",
      key: "PurchasePrice",
      dataIndex: "PurchasePrice",
      ellipsis: true,
      width: 180,
      render: (_, record) =>
        record?.testcase?.PurchasePrice
          ? currencyFormatter(record?.testcase?.PurchasePrice)
          : "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "PurchasePrice",
        inputPlaceholder: "Search by purchase price",
      }),
    },
    {
      title: "Loan Amount",
      key: "LoanOneAmount",
      dataIndex: "LoanOneAmount",
      ellipsis: true,
      width: 180,
      render: (_, record) =>
        record?.testcase?.LoanOneAmount
          ? currencyFormatter(record?.testcase?.LoanOneAmount)
          : "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "LoanOneAmount",
        inputPlaceholder: "Search by loan amount",
      }),
    },
    {
      title: "Standard Total",
      key: "standardTotal",
      dataIndex: "standardTotal",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div>
          {record?.bestx_response?.Standard?.["Total"]
            ? currencyFormatter(record?.bestx_response?.Standard?.["Total"])
            : "-"}
        </div>
      ),
    },
    {
      title: "AOL Total",
      key: "aolTotal",
      dataIndex: "aolTotal",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div>
          {record?.bestx_response?.AOL?.["Total"]
            ? currencyFormatter(record?.bestx_response?.AOL?.["Total"])
            : "-"}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "uuid",
      dataIndex: "uuid",
      width: 120,
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            {!expandedRowKeys?.includes(record._id) ? (
              <Tooltip title={`View Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={`Hide Standard/AOL Pricing`}>
                <Button
                  type="text"
                  shape="round"
                  onClick={() => {
                    handleExpand(record);
                  }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  return isLoading ? (
    <Spin spinning tip="Loading...">
      <div className="content-layout card-form-layout" />
    </Spin>
  ) : (
    <div className="content-layout card-form-layout">
      <HelperModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        handleSave={changeStatus}
        text={`Are you sure you want to ${
          statusData?.status === 1 ? "approve" : "disapprove"
        } the test case?`}
      />
      <Row gutter={24} style={{ marginTop: "8px" }}>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-calendar"></i>
                <p>Validated On</p>
              </div>
            }
          >
            {importDetails?.formatted_created_at}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            style={{
              textAlign: "center",
            }}
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-file"></i>
                <p>Total Test Cases</p>
              </div>
            }
          >
            {importDetails?.total_test_count}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-circle-check"></i>
                <p>Successful Test Cases</p>
              </div>
            }
            style={{
              textAlign: "center",
            }}
          >
            {importDetails?.sucess_count || 0}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div
                className="card-title-with-icon"
                style={{ color: "#dc3838" }}
              >
                <i className="fa-solid fa-circle-exclamation"></i>
                <p>Failed Test Cases</p>
              </div>
            }
            style={{
              color: "#dc3838",
              textAlign: "center",
            }}
          >
            {importDetails?.fail_count}
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "48px" }}>
        <Col span={24}>
          <Table
            className="best-x-tables"
            loading={addressLoading}
            columns={columns}
            scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
            dataSource={addresses}
            onChange={handleTableChange}
            pagination={{
              current: payload?.page || 1,
              pageSize: payload?.limit || PAGINATION_LIMIT,
              total: totalAddress,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing Address ${range[0]}-${range[1]} of ${total}`,
              onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
                setPayload((prevPayload) => ({
                  ...prevPayload,
                  page,
                  limit: pageSize,
                })),
            }}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Table
                    title={() => <div> Standard Pricing</div>}
                    style={{ width: "45%", marginTop: "16px" }}
                    scroll={{ x: "max-content" }}
                    dataSource={Object?.keys(
                      record?.bestx_response?.Standard || {}
                    ).map((val) => {
                      return {
                        type: val,
                        price: record?.bestx_response?.Standard?.[val],
                      };
                    })}
                    columns={[
                      { title: "Type", dataIndex: "type" },
                      {
                        title: "Price",
                        dataIndex: "price",
                        render: (text, data) => {
                          let renderText = text;
                          if (
                            Object.keys(
                              record?.conflicts?.Standard || {}
                            ).includes(data?.type)
                          ) {
                            const actualVal =
                              record?.testcase?.Standard?.[data?.type];
                            if (actualVal)
                              renderText =
                                renderText + ` (Expected Value : ${actualVal})`;
                          }

                          return <div>{renderText}</div>;
                        },
                      },
                    ]}
                    pagination={false}
                    rowClassName={(data) => {
                      if (
                        Object.keys(record?.conflicts?.Standard || {}).includes(
                          data?.type
                        )
                      )
                        return "error-row";
                      return undefined;
                    }}
                    locale={{
                      emptyText: (
                        <div className="empty-table-data">
                          {record?.testcase?.standard_error?.length ? (
                            record?.testcase?.standard_error
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </div>
                      ),
                    }}
                  />
                  <Table
                    title={() => (
                      <div className="aol-title">
                        <div style={{ marginRight: "8px" }}>AOL Pricing</div>
                        {record?.testcase?.aol_ineligible ? (
                          <Tag className={"centered error"}>
                            {"AOL Ineligible"}
                          </Tag>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    style={{
                      width: "45%",
                      marginTop: "16px",
                      marginLeft: "16px",
                    }}
                    scroll={{ x: "max-content" }}
                    dataSource={Object?.keys(
                      record?.bestx_response?.AOL || {}
                    ).map((val) => {
                      return {
                        type: val,
                        price: record?.bestx_response?.AOL?.[val],
                      };
                    })}
                    columns={[
                      { title: "Type", dataIndex: "type" },
                      {
                        title: "Price",
                        dataIndex: "price",
                        render: (text, data) => {
                          let renderText = text;
                          if (
                            Object.keys(record?.conflicts?.AOL || {}).includes(
                              data?.type
                            )
                          ) {
                            const actualVal =
                              record?.testcase?.AOL?.[data?.type];
                            if (actualVal)
                              renderText =
                                renderText + ` (Expected Value : ${actualVal})`;
                          }

                          return <div>{renderText}</div>;
                        },
                      },
                    ]}
                    pagination={false}
                    rowClassName={(data) => {
                      if (
                        Object.keys(record?.conflicts?.AOL || {}).includes(
                          data?.type
                        )
                      )
                        return "error-row";
                      return undefined;
                    }}
                    locale={{
                      emptyText: (
                        <div className="empty-table-data">
                          {record?.testcase?.aol_error?.length ? (
                            record?.testcase?.aol_error
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </div>
                      ),
                    }}
                  />
                </div>
              ),
              expandedRowKeys,
              onExpand: (expanded, record) => handleExpand(record),
              showExpandColumn: false,
            }}
            rowClassName={(record) =>
              record?.result === "failed"
                ? "error-row cursor-pointer"
                : "cursor-pointer"
            }
            rowKey={(record) => record._id}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleExpand(record);
                },
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TestcaseDetails;
