import React from "react";
import {
  Avatar,
  Button,
  Layout,
  Switch,
  Tooltip,
  Divider,
  Radio,
  Select,
  Form,
} from "antd";
import {
  AppstoreFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import BreadCrumbComponent from "./BreadCrumbComponent";
import NotificationPopover from "../Standalone/NotificationPopover/NotificationPopover";
import { useContext } from "react";
import GlobalContext from "../../store/global-context";
import { useLocation, useNavigate } from "react-router";

const { Header } = Layout;
const { Option } = Select;

const PageHeader = ({
  hideHeaderItems,
  collapsed,
  setCollapsed,
  setIsSideDrawerOpen,
  viewTransaction,
  showImportAddressButton,
}) => {
  const globalCtx = useContext(GlobalContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Header className="header">
      <div className="header-content">
        <div className="header-left">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => {
                setCollapsed(!collapsed);
              },
            }
          )}

          {hideHeaderItems && <Divider type="vertical" style={{ margin: 0 }} />}

          {!hideHeaderItems && (
            <>
              <Tooltip title="Dashboard" placement="bottom">
                <AppstoreFilled
                  className="app-icon"
                  onClick={() =>
                    navigate(
                      !window.location.pathname.startsWith("/standalone")
                        ? "/admin/dashboard"
                        : "/standalone/dashboard"
                    )
                  }
                />
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}
          <BreadCrumbComponent />
        </div>
        <div className="header-center">
          {/* {!hideHeaderItems &&
            (globalCtx?.user?.role === "admin" ||
              globalCtx?.user?.role === "custom") &&
            globalCtx.configuration && (
              <Switch
                className="configuration-switch"
                checked={globalCtx.configuration === "los"}
                checkedChildren="LOS Integration"
                style={{ marginRight: "16px" }}
                unCheckedChildren="Standalone"
                disabled={globalCtx?.user?.role === "custom"}
                onChange={(checked) => {
                  if (globalCtx?.resetFilterFunction) {
                    globalCtx?.resetFilterFunction();
                  }
                  if (checked) {
                    globalCtx.setConfiguration("los");
                  } else {
                    globalCtx.setConfiguration("standalone");
                  }
                  navigate(
                    !window.location.pathname.includes("standalone")
                      ? "/admin/dashboard"
                      : "/standalone/dashboard"
                  );
                }}
              />
            )} */}
          {/* {!hideHeaderItems &&
            (globalCtx?.user?.role === "admin" ||
              globalCtx?.user?.role === "custom") &&
            globalCtx.configuration && (
              <Radio.Group
                buttonStyle="solid"
                value={globalCtx.configuration}
                disabled={globalCtx?.user?.role === "custom"}
                onChange={(e) => {
                  if (globalCtx?.resetFilterFunction) {
                    globalCtx?.resetFilterFunction();
                  }

                  globalCtx.setConfiguration(e.target.value);

                  navigate(
                    !window.location.pathname.includes("standalone")
                      ? "/admin/dashboard"
                      : "/standalone/dashboard"
                  );
                }}
              >
                <Radio.Button value="standalone">Standalone</Radio.Button>
                <Radio.Button value="los">LOS Integration</Radio.Button>
              </Radio.Group>
            )} */}
          {!hideHeaderItems &&
            (globalCtx?.user?.role === "admin" ||
              globalCtx?.user?.role === "custom") &&
            globalCtx.configuration && (
              <div className="header-toggle">
                <Form layout={"horizontal"} name="clientForm">
                  <Form.Item
                    name={"integration_mode"}
                    label="Integration Mode"
                    initialValue={globalCtx.configuration}
                    value={globalCtx.configuration}
                    style={{ margin: "unset", minHeight: "unset" }}
                  >
                    <Select
                      style={{ width: "250px", minHeight: "unset" }}
                      disabled={globalCtx?.user?.role === "custom"}
                      onChange={(value) => {
                        if (globalCtx?.resetFilterFunction) {
                          globalCtx?.resetFilterFunction();
                        }

                        globalCtx.setConfiguration(value);

                        navigate(
                          !window.location.pathname.includes("standalone")
                            ? "/admin/dashboard"
                            : "/standalone/dashboard"
                        );
                      }}
                    >
                      <Option key={"standalone"} value={"standalone"}>
                        {"Standalone"}
                      </Option>
                      <Option key={"los"} value={"los"}>
                        {"LOS Integration"}
                      </Option>
                    </Select>
                  </Form.Item>
                </Form>
              </div>
            )}
        </div>
        <div className="header-right">
          {globalCtx?.resetFilterFunction && (
            <Tooltip title="Reset Table Filters">
              <Button
                type="text"
                shape="circle"
                className="header-btn reset-table-filter"
                icon={<i className="fa-solid fa-arrows-rotate"></i>}
                onClick={globalCtx?.resetFilterFunction}
              />
            </Tooltip>
          )}
          {showImportAddressButton && pathname === "/admin/addresses" && (
            <Button
              className="save-btn"
              onClick={() => navigate("/admin/addresses/import/add")}
              style={{
                marginRight: "8px",
                height: "36px",
              }}
            >
              Import Address
            </Button>
          )}

          {!hideHeaderItems &&
            window.location.pathname.includes("standalone") && (
              <NotificationPopover viewTransaction={viewTransaction} />
            )}
          {!hideHeaderItems &&
            window.location.pathname.includes("standalone") &&
            globalCtx?.user?.client?.name && (
              <div className="user-tag mr-10" style={{ padding: "0px 8px" }}>
                <div className="user-name">
                  Client : {globalCtx?.user?.client?.name}
                </div>
              </div>
            )}
          {!hideHeaderItems && (
            <Tooltip title={`My Account`} placement="bottom">
              <div
                className="user-tag"
                onClick={() => setIsSideDrawerOpen(true)}
              >
                <Avatar
                  className="user-avatar"
                  size="small"
                  icon={<i className="fa-solid fa-circle-user"></i>}
                />
                <div className="user-name">
                  {globalCtx?.user?.first_name +
                    " " +
                    globalCtx?.user?.last_name}
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </Header>
  );
};

export default PageHeader;
