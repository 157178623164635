import React from "react";
import { useContext } from "react";
import GlobalContext from "../../store/global-context";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Row, Spin, Tag, Table, Collapse } from "antd";
import LogAccordion from "../Logs/LogAccordion";
import { currencyFormatter, getPayloadType } from "../../utils/helpers";
import { useState } from "react";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import { PAGINATION_LIMIT } from "../../utils/constants";
import { useCallback } from "react";
import API from "../../api";
import useManageTableFilter from "../../hooks/useManageTableFilter";

const { Panel } = Collapse;

const ImportDetails = () => {
  const globalCtx = useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [payload, setPayload] = useState();
  const [importDetails, setImportDetails] = useState();
  const [addresses, setAddresses] = useState([]);
  const [totalAddress, setTotalAddress] = useState(0);
  const [addressLoading, setAddressLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorAccordionOpened, setErrorAccordionOpened] = useState("error");
  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload, {
    page: 1,
    limit: PAGINATION_LIMIT,
  });

  const getImportDetails = useCallback(() => {
    setIsLoading(true);
    API.get(`/addresses/import-detail/${id}`, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status === 200) {
          setImportDetails(result?.data?.response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        navigate(-1);
        setImportDetails(false);
      });
  }, [id, navigate]);

  const getImportAddresses = useCallback(() => {
    setAddressLoading(true);
    API.get(`addresses/import-addresses/${id}`, {
      params: payload,
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((result) => {
        if (result.status === 200) {
          setAddresses(result?.data?.response?.data);
          setTotalAddress(result?.data?.response?.total);
        }
        setAddressLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setAddressLoading(false);
      });
  }, [id, payload]);

  useEffect(() => {
    globalCtx.setBreadcrumb([
      { name: "Addresses", route: "/admin/addresses" },
      { name: "Import", route: "/admin/addresses/import" },
      { name: id },
    ]);
    globalCtx.setActive("2");
    getImportDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getImportDetails]);

  useEffect(() => {
    if (payload) {
      getImportAddresses();
    }
  }, [getImportAddresses, id, payload]);

  const columns = [
    {
      title: "BestX AOL Eligibility",
      key: "result",
      dataIndex: "aol_eligible",
      align: "center",
      fixed: "left",
      width: 210,
      render: (text, record) =>
        text ? (
          <Tag
            className={
              text === record?.aol ? "active centered" : "error centered"
            }
          >
            {text}
          </Tag>
        ) : (
          "-"
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ],
        paramKey: "aol_eligible",
      }),
    },
    {
      title: "Expected AOL Eligibility",
      key: "aol",
      dataIndex: "aol",
      fixed: "left",
      width: 230,
      render: (text, record) =>
        text ? (
          <Tag
            className={
              text === record?.aol_eligible
                ? "active centered"
                : "error centered"
            }
          >
            {text}
          </Tag>
        ) : (
          "-"
        ),
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ],
        paramKey: "aol",
      }),
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
      width: 170,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "address",
        inputPlaceholder: "Search by address",
      }),
    },
    {
      title: "City",
      key: "city",
      dataIndex: "city",
      width: 80,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "city",
        inputPlaceholder: "Search by city",
      }),
    },
    {
      title: "State",
      key: "state",
      dataIndex: "state",
      width: 100,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "state",
        inputPlaceholder: "Search by state",
      }),
    },
    {
      title: "Zip",
      key: "zip",
      dataIndex: "zip",
      width: 100,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "zip",
        inputPlaceholder: "Search by zip",
      }),
    },
    {
      title: "County",
      key: "county",
      dataIndex: "county",
      width: 100,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "county",
        inputPlaceholder: "Search by county",
      }),
    },
    {
      title: "Transaction Type",
      key: "transaction_type",
      dataIndex: "transaction_type",
      width: 200,
      render: (text) =>
        <div style={{ textTransform: "capitalize" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "Purchase",
            value: "purchase",
          },
          {
            label: "Refinance",
            value: "refi",
          },
        ],
        paramKey: "transaction_type",
        inputPlaceholder: "Search by transaction type",
      }),
    },
    {
      title: "Property Type",
      key: "property_type",
      dataIndex: "property_type",
      width: 180,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "SFR",
            value: "sfr",
          },
          {
            label: "Condo",
            value: "condo",
          },
          {
            label: "PUD",
            value: "pud",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        paramKey: "property_type",
        inputPlaceholder: "Search by property type",
      }),
    },
    {
      title: "Loan Type",
      key: "loan_type",
      dataIndex: "loan_type",
      width: 140,
      render: (text) =>
        <div style={{ textTransform: "uppercase" }}>{text}</div> || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          {
            label: "CNV",
            value: "cnv",
          },
          {
            label: "FHA",
            value: "fha",
          },
          {
            label: "VA",
            value: "va",
          },
          {
            label: "None",
            value: "none",
          },
        ],
        paramKey: "loan_type",
        inputPlaceholder: "Search by loan type",
      }),
    },
    {
      title: "Purchase Price",
      key: "purchase_price",
      dataIndex: "purchase_price",
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "purchase_price",
        inputPlaceholder: "Search by purchase price",
      }),
    },
    {
      title: "Loan Amount",
      key: "loan_amount",
      dataIndex: "loan_amount",
      width: 180,
      render: (text) => (text ? currencyFormatter(text) : "-"),
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "loan_amount",
        inputPlaceholder: "Search by loan amount",
      }),
    },
    {
      title: "HOA",
      key: "hoa",
      dataIndex: "hoa",
      width: 80,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        selection: true,
        loading: false,
        menuItems: [
          { label: "Available", value: "YES" },
          { label: "Not Available", value: "NO" },
        ],
        paramKey: "hoa",
        inputPlaceholder: "Search by HOA",
      }),
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  return isLoading ? (
    <Spin spinning tip="Loading...">
      <div className="content-layout card-form-layout" />
    </Spin>
  ) : (
    <div className="content-layout card-form-layout">
      <Row gutter={24} style={{ marginTop: "8px" }}>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-file"></i>
                <p>File Name</p>
              </div>
            }
          >
            {importDetails?.file_name}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-calendar"></i>
                <p>Imported On</p>
              </div>
            }
          >
            {importDetails?.formatted_created_at}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div className="card-title-with-icon">
                <i className="fa-solid fa-circle-check"></i>
                <p>Successful Imports</p>
              </div>
            }
            style={{
              textAlign: "center",
            }}
          >
            {importDetails?.success_count || 0}
          </Card>
        </Col>
        <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
          <Card
            className="info-card"
            title={
              <div
                className="card-title-with-icon"
                style={{ color: "#dc3838" }}
              >
                <i className="fa-solid fa-circle-exclamation"></i>
                <p>Failed Imports</p>
              </div>
            }
            style={{
              color: "#dc3838",
              textAlign: "center",
            }}
          >
            {importDetails?.failure_count}
          </Card>
        </Col>
      </Row>

      {importDetails?.error && (
        <Row gutter={24} style={{ marginTop: "24px" }}>
          <Col span={24}>
            <Card className="request-card">
              <Collapse
                activeKey={errorAccordionOpened}
                onChange={(key) => {
                  setErrorAccordionOpened(key);
                  if (typeof key !== "undefined") {
                    setTimeout(() => {
                      const scrollEl = document.getElementById(
                        `log-summary-${key}`
                      );
                      if (scrollEl) {
                        scrollEl.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }
                    }, 301);
                  }
                }}
                accordion
                bordered={false}
                className="log-summary-accordion address-error-accordion"
                expandIconPosition="end"
              >
                <Panel
                  id={`log-summary-error`}
                  header={
                    <div className="card-title-with-icon error-title">
                      <i className="fa-solid fa-circle-exclamation"></i>
                      <p>Import Error Details</p>
                    </div>
                  }
                  key={"error"}
                >
                  <LogAccordion
                    payload={importDetails?.error}
                    payloadType={getPayloadType({ test: "test" })}
                  />
                </Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: "48px" }}>
        <Col span={24}>
          <Table
            className="transaction-list-table"
            loading={addressLoading}
            columns={columns}
            scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
            dataSource={addresses}
            onChange={handleTableChange}
            pagination={{
              current: payload?.page || 1,
              pageSize: payload?.limit || PAGINATION_LIMIT,
              total: totalAddress,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing Address ${range[0]}-${range[1]} of ${total}`,
              onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
                setPayload((prevPayload) => ({
                  ...prevPayload,
                  page,
                  limit: pageSize,
                })),
            }}
            rowKey={(record) => record.uuid}
            rowClassName={(record) =>
              record?.aol !== record?.aol_eligible ? "error-row" : undefined
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default ImportDetails;
