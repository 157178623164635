/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb } from "antd";
import React, { useContext } from "react";
import GlobalContext from "../../store/global-context";
import { useNavigate } from "react-router-dom";

const BreadCrumbComponent = () => {
  const { breadCrumb } = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <Breadcrumb separator="/">
      {breadCrumb &&
        breadCrumb.length > 0 &&
        breadCrumb.map((data, i) => {
          return (
            <Breadcrumb.Item key={i}>
              <span
                className={data.route ? "link" : "disabled-link"}
                onClick={() => data.route && navigate(data && data.route)}
              >
                {data.name}
              </span>
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb>
  );
};

export default BreadCrumbComponent;
