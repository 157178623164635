import React, { useEffect } from "react";
import { Col, Form, Input, Modal, Row } from "antd";
const { TextArea } = Input;
const AlertModal = ({
  isOpen,
  setOpen,
  handleSave,
  setIsNew = () => {},
  isDisapproving,
  note = "",
}) => {
  const [form] = Form.useForm();

  const handleClose = () => {
    setIsNew(false);
    setOpen(false);
  };
  const handleSubmit = () => {
    let note = form.getFieldValue("note") || "";
    handleSave(note);
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        note: note ?? "",
      });
    }
  }, [isOpen, form, note]);

  return (
    <Modal
      destroyOnClose
      open={isOpen}
      title={"Note"}
      onCancel={handleClose}
      onOk={() => {
        form.submit();
      }}
      okText="Save"
      className="question-alert-pop-up"
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        layout={"vertical"}
        name="categoryForm"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={30}>
          <Col md={24} sm={24}>
            <Form.Item
              name={"note"}
              label={
                isDisapproving
                  ? "Please provide a reason for disapproving the test case. "
                  : "Note"
              }
              rules={[
                {
                  required: true,
                  message: "Please enter note",
                },
              ]}
            >
              <TextArea
                style={{ marginBottom: "0.5rem" }}
                placeholder="Enter Note"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default AlertModal;
