import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "antd";
import GlobalContext from "./store/global-context";
import Loader from "./components/shared/Loader";

import TitleCalculatorHeader from "./components/shared/TitleCalculatorHeader";

const { Content, Sider } = Layout;

const PublicRouteLayout = () => {
  const globalCtx = useContext(GlobalContext);
  const { pathname } = useLocation();
  const [showUI, setShowUI] = useState(false);

  const identifier = pathname.split("/")?.[2];

  useEffect(() => {
    globalCtx.setTheme("light");
    document.body.classList.add("new-light");
    setTimeout(() => {
      setShowUI(true);
    }, 500);
  }, []);

  return !showUI ? (
    <Loader />
  ) : (
    <div className="title-calculator">
      <Layout hasSider className={"sider-collapsed"}>
        <Layout className={"site-layout"}>
          <TitleCalculatorHeader />
          <Content
            className={`site-layout-background slim-scrollbar ${
              pathname.endsWith("add") ? "form-layout" : ""
            }`}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default PublicRouteLayout;
