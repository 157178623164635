import React, { useState, useEffect, useCallback, useContext } from "react";
import GlobalContext from "../../store/global-context";
import { PAGINATION_LIMIT } from "../../utils/constants";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import API from "../../api";
import { Button, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import useManageTableFilter from "../../hooks/useManageTableFilter";
import HelperModal from "./HelperModal";

const ImportedTestcaseList = () => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [totalAddress, setTotalAddress] = useState(0);
  const [payload, setPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload);

  useEffect(() => {
    globalCtx.setBreadcrumb([{ name: "Validate History" }]);
    globalCtx.setActive("4");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = useCallback(() => {
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setIsLoading(true);
    API.get("addresses/verify-history", {
      params: payload,
      signal: newAbortController.signal,
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((res) => {
        if (res.status) {
          setAddresses(res?.data?.response?.data || []);
          setTotalAddress(res?.data?.response?.total || 0);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (!err?.code || err?.code !== "ERR_CANCELED") {
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (payload) {
      getList();
    }
  }, [getList, payload]);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  // const generateItems = (record, text) => {
  //   let items = [];

  //   items.push({
  //     key: 2,
  //     label: (
  //       <div
  //         className={`pop-over-menu-item`}
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           navigate(`/admin/best-x/reverification/${record.uuid}`);
  //         }}
  //       >
  //         <i className="far fa-eye"></i> View
  //       </div>
  //     ),
  //   });

  //   return items;
  // };

  const columns = [
    {
      title: "Validated On",
      key: "formatted_created_at",
      dataIndex: "formatted_created_at",
      width: 220,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        date: true,
        paramKey: "created_at",
      }),
    },

    // {
    //   title: "Status",
    //   key: "import_status",
    //   dataIndex: "import_status",
    //   width: 200,
    //   render: (text) => (text ? <Tag>{text}</Tag> : "-"),
    //   ...getFilterParams({
    //     payload,
    //     setPayload,
    //     paramKey: "status",
    //     inputPlaceholder: "Search by import status",
    //   }),
    // },
    {
      title: "Total test cases",
      key: "total_test_count",
      dataIndex: "total_test_count",
      width: 150,
      align: "center",
      render: (text) => (
        <div className="secondary-text-color">
          {typeof text === "undefined" ? "-" : text}
        </div>
      ),
    },
    {
      title: "Success",
      key: "sucess_count",
      dataIndex: "sucess_count",
      width: 150,
      align: "center",
      render: (text) => (
        <div className="secondary-text-color">
          {typeof text === "undefined" ? "-" : text}
        </div>
      ),
    },
    {
      title: "Failed",
      key: "fail_count",
      dataIndex: "fail_count",
      align: "center",
      width: 150,
      render: (text) => (
        <div style={{ color: "#dc3838" }}>
          {typeof text === "undefined" ? "-" : text}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      align: "center",
      render: (_, record, i) => {
        return (
          <div key={i}>
            {/* <Dropdown
              trigger={"click"}
              menu={{ items: generateItems(record) }}
              placement="bottomRight"
              arrow
            >
              <Button className="gear-btn">
                <i class="fa-solid fa-gear"></i>
              </Button>
            </Dropdown> */}
            <Tooltip title={`View Import Details`}>
              <Button
                type="text"
                shape="round"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/admin/best-x/reverification/${record.uuid}`);
                }}
              >
                <i className="far fa-eye"></i>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  const reverifyTestCases = () => {
    setIsLoading(true);
    setModalOpen(false);
    API.post(
      "addresses/verify-bestx-calculation",
      {},
      {
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
      }
    )
      .then((result) => {
        if (result.status) {
          getList();
          Notification("success", "Verified all test cases.");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error", err?.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="content-layout">
      <HelperModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        handleSave={reverifyTestCases}
        text={`Are you sure you want to re-verify all test cases?`}
      />
      <div className="listing-header">
        <div className="header-left" />

        {/* <div className="header-right">
          <AddButton
            onClick={() => {
              setModalOpen(true);
            }}
            title={"Verify Calculation"}
            icon={<i className="fa-solid fa-check"></i>}
            hideAddIcon
          />
        </div> */}
      </div>
      {/* <Alert
        message={
          <ul style={{ fontSize: "12px" }}>
            <li>All test cases have been verified. No pending test cases.</li>
            <li>
              If you believe a re-verification is needed for all test cases you
              can click on Re-Verify button.
            </li>
            <li>
              A full re-verification may be needed when there is some major
              change made to the BestX engine or the underwriter changes the
              pricing and now some or all previously approved test cases become
              invalid. Re-verification will invalidate all previously uploaded
              test cases and move them back to "Test Cases to verify" queue
              which allow you to review, verify and then approve / disapprove
              each and every case again.
            </li>
          </ul>
        }
        style={{ marginBottom: "10px" }}
        type="info"
        closable
      /> */}

      <Table
        loading={isLoading}
        className="best-x-tables"
        columns={columns}
        dataSource={addresses}
        onChange={handleTableChange}
        sticky={true}
        scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
        pagination={{
          current: payload?.page || 1,
          pageSize: payload?.limit || PAGINATION_LIMIT,
          total: totalAddress,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing History ${range[0]}-${range[1]} of ${total}`,
          onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
            setPayload((prevPayload) => ({
              ...prevPayload,
              page,
              limit: pageSize,
            })),
        }}
        rowKey={(record) => record.import_address_uuid}
      />
    </div>
  );
};
export default ImportedTestcaseList;
