import React, { useState, useEffect, useCallback, useContext } from "react";
import GlobalContext from "../../store/global-context";
import { PAGINATION_LIMIT } from "../../utils/constants";
import useTableColumnFilter from "../../hooks/useTableColumnFilter";
import API from "../../api";
import { Button, Table, Dropdown } from "antd";
import AddButton from "../shared/AddButton";
import { useNavigate } from "react-router-dom";
import useManageTableFilter from "../../hooks/useManageTableFilter";

const ImportList = () => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [totalAddress, setTotalAddress] = useState(0);
  const [payload, setPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const { getFilterParams } = useTableColumnFilter();
  useManageTableFilter(payload, setPayload);

  useEffect(() => {
    globalCtx.setBreadcrumb([
      { name: "Addresses", route: "/admin/addresses" },
      { name: "Import" },
    ]);
    globalCtx.setActive("3");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImportList = useCallback(() => {
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setIsLoading(true);
    API.get("addresses/get-all-imports", {
      params: payload,
      signal: newAbortController.signal,
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
      .then((res) => {
        if (res.status === 200) {
          setAddresses(res?.data?.response?.data || []);
          setTotalAddress(res?.data?.response?.total || 0);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (!err?.code || err?.code !== "ERR_CANCELED") {
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (payload) {
      getImportList();
    }
  }, [getImportList, payload]);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const generateItems = (record) => {
    let items = [
      {
        key: 1,
        label: (
          <div
            type="text"
            shape="round"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/admin/addresses/import/${record.import_address_uuid}`);
            }}
            className={`pop-over-menu-item`}
          >
            <i className="far fa-eye"></i>View
          </div>
        ),
      },
    ];

    return items;
  };

  const columns = [
    {
      title: "Actions",
      key: "actions",
      width: 80,
      align: "center",
      fixed: "left",
      render: (_, record, i) => {
        return (
          <div key={i} className="table-action-wrap">
            {/* <Tooltip title={`View Import Details`}>
              <Button
                type="text"
                shape="round"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/admin/addresses/import/${record.import_address_uuid}`
                  );
                }}
              >
                <i className="far fa-eye"></i>
              </Button>
            </Tooltip> */}
            <Dropdown
              trigger={"click"}
              menu={{ items: generateItems(record) }}
              placement="bottomRight"
              arrow
            >
              <Button className="gear-btn">
                <i class="fa-solid fa-gear"></i>
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
    {
      title: "Imported on",
      key: "formatted_created_at",
      dataIndex: "formatted_created_at",
      width: 220,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        date: true,
        paramKey: "created_at",
      }),
    },
    {
      title: "File name",
      key: "file_name",
      dataIndex: "file_name",
      width: 300,
      render: (text) => text || "-",
      ...getFilterParams({
        payload,
        setPayload,
        paramKey: "file_name",
        inputPlaceholder: "Search by file name",
      }),
    },
    // {
    //   title: "Status",
    //   key: "import_status",
    //   dataIndex: "import_status",
    //   width: 200,
    //   render: (text) => (text ? <Tag>{text}</Tag> : "-"),
    //   ...getFilterParams({
    //     payload,
    //     setPayload,
    //     paramKey: "status",
    //     inputPlaceholder: "Search by import status",
    //   }),
    // },
    {
      title: "Success",
      key: "success_count",
      dataIndex: "success_count",
      width: 150,
      align: "center",
      render: (text) => (
        <div className="secondary-text-color">
          {typeof text === "undefined" ? "-" : text}
        </div>
      ),
    },
    {
      title: "Failed",
      key: "failure_count",
      dataIndex: "failure_count",
      align: "center",
      width: 150,
      render: (text) => (
        <div style={{ color: "#dc3838" }}>
          {typeof text === "undefined" ? "-" : text}
        </div>
      ),
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      order: sorter?.order,
      order_column: sorter?.order ? sorter?.field : undefined,
    }));
  };

  return (
    <div className="content-layout">
      <div className="listing-header">
        <div className="header-left" />

        <div className="header-right">
          <AddButton
            onClick={() => navigate("/admin/addresses/import/add")}
            title={"Import Addresses"}
            icon={<i className="fa-solid fa-cloud-arrow-down"></i>}
            hideAddIcon
          />
        </div>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={addresses}
        onChange={handleTableChange}
        sticky={true}
        scroll={{ x: "max-content", y: "calc(100vh - 350px)" }}
        pagination={{
          current: payload?.page || 1,
          pageSize: payload?.limit || PAGINATION_LIMIT,
          total: totalAddress,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing History ${range[0]}-${range[1]} of ${total}`,
          onChange: (page = 1, pageSize = PAGINATION_LIMIT) =>
            setPayload((prevPayload) => ({
              ...prevPayload,
              page,
              limit: pageSize,
            })),
        }}
        rowKey={(record) => record.import_address_uuid}
      />
    </div>
  );
};
export default ImportList;
