import React, { useState, useCallback } from "react";
import { getDefaultToken, getLocalStorageToken } from "../utils/helpers";

const defaultState = {
  globalContext: {
    active: [],
    breadCrumbs: [],
    theme: "light",
    // token: getLocalStorageToken(),
    token: getDefaultToken(),
    user: {},
    notifications: [],
    showWelcomePopup: false,
    hasZeroTransactions: false,
    permissions: [],
    backButtonRoute: "",
    configuration: "",
  },
};

const GlobalContext = React.createContext({
  globalContext: {
    active: [],
    breadCrumbs: [],
    theme: "",
    token: "",
    user: "",
    notifications: [],
    permissions: [],
    showWelcomePopup: false,
    hasZeroTransactions: false,
    backButtonRoute: "",
    resetFilterFunction: null,
    configuration: "",
    hideLayoutItems: [],
  },
  setBreadcrumb: (breadCrumb) => {},
  setActive: (index) => {},
  setTheme: (theme) => {},
  setToken: (token) => {},
  setUser: (user) => {},
  setShowWelcomePopup: (showWelcomePopup) => {},
  setHasZeroTransactions: (hasZeroTransactions) => {},
  setNotifications: (notifications) => {},
  addNotification: (notification) => {},
  setPermissions: (permissions) => {},
  setBackButtonRoute: (route) => {},
  setResetFilterFunction: (fn) => {},
  setConfiguration: (configuration) => {},
});

export const GlobalContextProvider = (props) => {
  const [breadCrumb, setBreadcrumb] = useState(
    defaultState.globalContext.breadCrumbs
  );
  const [active, setActive] = useState(defaultState.globalContext.active);
  const [theme, setTheme] = useState(defaultState.globalContext.theme);
  const [token, setToken] = useState(defaultState.globalContext.token);
  const [user, setUser] = useState({});
  const [hasZeroTransactions, setHasZeroTransactions] = useState(
    defaultState.globalContext.hasZeroTransactions
  );
  const [showWelcomePopup, setShowWelcomePopup] = useState(
    defaultState.globalContext.showWelcomePopup
  );
  const [notifications, setNotifications] = useState(
    defaultState.globalContext.notifications
  );
  const [permissions, setPermissions] = useState(
    defaultState.globalContext.permissions
  );
  const [backButtonRoute, setBackButtonRoute] = useState();
  const [resetFilterFunction, setResetFilterFunction] = useState();
  const [configuration, setConfiguration] = useState(
    defaultState.globalContext.configuration
  );

  const setBreadcrumbHandler = (breadCrumbToSet) =>
    setBreadcrumb(breadCrumbToSet);

  const setActiveHandler = (index) => setActive([index]);

  const setThemeHandler = (theme) => {
    setTheme(theme);
  };

  const setTokenHandler = (token) => {
    setToken(token);
  };

  const setUserHandler = (user) => {
    setUser(user);
  };

  const setShowWelcomePopupHandler = (showWelcomePopup) => {
    setShowWelcomePopup(showWelcomePopup);
  };

  const setHasZeroTransactionsHandler = (hasZeroTransactions) => {
    setHasZeroTransactions(hasZeroTransactions);
  };

  const setNotificationsHandler = (notifications) => {
    setNotifications(notifications);
  };

  const addNotificationHandler = (notification) => {
    setNotifications((prevNotifications) => [
      notification,
      ...prevNotifications,
    ]);
  };

  const setPermissionsHandler = (permissions = []) => {
    setPermissions(permissions);
  };

  const setBackButtonRouteHandler = (route) => {
    setBackButtonRoute(route);
  };

  const setResetFilterFunctionHandler = useCallback((fn) => {
    setResetFilterFunction(fn ? () => fn : undefined);
  }, []);

  const setConfigurationHandler = (configuration) => {
    localStorage.setItem("configuration", configuration);
    setConfiguration(configuration);
  };

  const context = {
    breadCrumb,
    active,
    theme,
    token,
    user,
    showWelcomePopup,
    hasZeroTransactions,
    notifications,
    permissions,
    backButtonRoute,
    resetFilterFunction,
    configuration,
    setBreadcrumb: setBreadcrumbHandler,
    setActive: setActiveHandler,
    setTheme: setThemeHandler,
    setToken: setTokenHandler,
    setUser: setUserHandler,
    setShowWelcomePopup: setShowWelcomePopupHandler,
    setHasZeroTransactions: setHasZeroTransactionsHandler,
    setNotifications: setNotificationsHandler,
    addNotification: addNotificationHandler,
    setPermissions: setPermissionsHandler,
    setBackButtonRoute: setBackButtonRouteHandler,
    setResetFilterFunction: setResetFilterFunctionHandler,
    setConfiguration: setConfigurationHandler,
  };

  return (
    <GlobalContext.Provider value={context}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
