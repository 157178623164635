import { Avatar, Button, Col, Drawer, List, Row } from "antd";
import React, { useContext } from "react";
import GlobalContext from "../../store/global-context";
import { useNavigate } from "react-router";
import API from "../../api";
import {
  removeLocalStorageToken,
  removeLocalStorageUser,
} from "../../utils/helpers";

const { Item: ListItem } = List;

const SideDrawer = ({ open, handleClose }) => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();

  const listItems = [
    {
      label: "My Account",
      icon: <i className="fa-solid fa-user"></i>,
      link: `/user/view/${globalCtx?.user?.user_uuid}?profile=${true}`,
    },
    {
      label: "Change Password",
      icon: <i className="fa-solid fa-key"></i>,
      link: `/change-password`,
    },
    {
      label: "Settings",
      icon: <i className="fa-solid fa-gears"></i>,
      link: `/settings`,
    },
  ];

  const handleNavigate = (link) => {
    navigate(
      `${
        !window.location.pathname.includes("standalone")
          ? "/admin"
          : "/standalone"
      }${link}`
    );
    handleClose();
  };

  const handleLogout = () => {
    API.get("logout", {
      headers: {
        Authorization: `Bearer ${globalCtx.token}`,
      },
    })
      .then((result) => {
        // if (result.status === 200) {
        // if (
        //   globalCtx.user.role === "standalone" ||
        //   globalCtx.user.role === "standalone_user"
        // ) {
        //   navigate("/standalone");
        // } else {
        //   navigate("/admin");
        // }
        // }
      })
      .catch((err) => {
        console.log(err.message);
      });
    globalCtx.setToken(null);
    removeLocalStorageToken();
    removeLocalStorageUser();
    if (globalCtx?.user.role === "admin") {
      localStorage.removeItem("configuration");
    }
  };

  return (
    <Drawer
      className="user-profile"
      open={open}
      title={
        <Row gutter={30}>
          <Col span={24}>
            <Avatar
              size="large"
              icon={<i className="fa-solid fa-circle-user"></i>}
            />
            <span className="username">
              <span style={{ marginRight: "4px" }}>
                {globalCtx.user.first_name &&
                  `${globalCtx?.user?.first_name[0]?.toUpperCase()}${globalCtx?.user?.first_name?.slice(
                    1
                  )}`}
              </span>
              <span>
                {globalCtx.user.last_name &&
                  `${globalCtx?.user?.last_name[0]?.toUpperCase()}${globalCtx?.user?.last_name?.slice(
                    1
                  )}`}
              </span>
            </span>
            <span className="user-meta-info">
              <i className="fa fa-envelope" /> {globalCtx?.user?.email}
            </span>
          </Col>
        </Row>
      }
      onClose={handleClose}
      width="400"
      destroyOnClose
      footer={
        <Button
          className="cancel-btn"
          icon={<i className="fa-solid fa-arrow-right-from-bracket"></i>}
          onClick={handleLogout}
        >
          Log Out
        </Button>
      }
    >
      <List
        dataSource={listItems}
        renderItem={(item) => (
          <ListItem onClick={() => handleNavigate(item.link)}>
            {item.icon} {item.label}
          </ListItem>
        )}
      />
    </Drawer>
  );
};

export default SideDrawer;
