import { Col, Form, Input, Modal, Row } from "antd";
import React, { useContext, useState } from "react";
import API from "../../../api";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../store/global-context";
import {
  setLocalStorageToken,
  setLocalStorageUser,
} from "../../../utils/helpers";

const AccountConfirmationModal = ({ open, setOpen, formData }) => {
  const globalCtx = useContext(GlobalContext);
  const { token, setToken, setUser, setPermissions, user } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);

  const handleSubmit = (data) => {
    setIsLoading(true);
    API.post("/create-account", {})
      .then((result) => {
        if (result.status === 200) {
          setToken(result?.data?.response?.token);
          setUser(result?.data?.response?.user);
          setPermissions(
            result?.data?.response?.user?.permissions?.permissions
          );
          setLocalStorageToken(
            result?.data?.response?.token,
            "standalone_token"
          );
          setLocalStorageUser(result?.data?.response?.user);
          localStorage.setItem("SWBTitlePayload", formData);
          navigate("/standalone/title-calculator/quick?new=true");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    if (!isLoading) {
      setStep(1);
      setOpen(false);
      form.resetFields();
    }
  };

  return (
    <Modal
      open={open}
      title="Create Account"
      onCancel={handleClose}
      okText={step === 1 ? "Yes" : "Submit"}
      cancelText={step === 1 ? "No" : "Back"}
      confirmLoading={step === 2 && isLoading}
      cancelButtonProps={{
        loading: step === 1 && isLoading,
        disabled: step === 2 && isLoading,
        onClick: () => {
          if (step === 1) {
            handleClose();
          }
          if (step === 2) {
            setStep(1);
          }
        },
      }}
      okButtonProps={{
        disabled: step === 1 && isLoading,
        onClick: () => {
          if (step === 1) {
            setStep(2);
          }
          if (step === 2) {
            form.submit();
          }
        },
      }}
      onOk={() => {
        form.submit();
      }}
      destroyOnClose
      className="create-transaction-modal"
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        layout={"vertical"}
        name="partnerForm"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={30}>
          {step === 1 && (
            <Col sm={24} md={24} lg={24}>
              <p className="loan-number-text">Do you want to create account?</p>
            </Col>
          )}
          {step === 2 && (
            <>
              <Col md={12} sm={12}>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                  ]}
                  label="Password"
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              </Col>
              <Col md={12} sm={12}>
                <Form.Item
                  name={"password_confirmation"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter password confirmation",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error("Password do not match!")
                        );
                      },
                    }),
                  ]}
                  label="Confirm Password"
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default AccountConfirmationModal;
