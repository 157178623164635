import { Col, DatePicker, Divider, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import API from "../../../api";
import ModalRenderer from "./ModalRenderer";
import { customTestWords } from "../../../utils/constants";
let Filter = require("bad-words");
let filter = new Filter();
filter.addWords(...customTestWords);
const ContactPopup = ({ open, setOpen, payload, handleContinue }) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (open) {
      const data = localStorage.getItem("SWBTitleContactInfo");
      if (data) {
        form.setFieldsValue({ ...JSON.parse(data) });
      }
    }
  }, [open, form]);

  const handleSubmit = (data) => {
    setSubmitLoading(true);
    API.post(
      `contact_info`,
      {
        ...data,
        phone: data.phone ? data.phone?.replace(/\D/g, "") : "",
      },
      {
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
      }
    )
      .then((result) => {
        if (result.status === 200) {
          // Notification(
          //   "success",
          //   result?.data?.response?.message || "Contact information updated."
          // );
          localStorage.setItem(
            "SWBTitleContactInfo",
            JSON.stringify({
              ...data,
              phone: data.phone ? data.phone?.replace(/\D/g, "") : "",
              contact_info_uuid: result?.data?.response?.contact_info_uuid,
            })
          );
          setSubmitLoading(false);
          handleClose();
          handleContinue(payload, result?.data?.response?.contact_info_uuid);
          // setIsAccountModalOpen(true);
        }
        setSubmitLoading(false);
      })
      .catch((err) => setSubmitLoading(false));
  };

  const handleClose = (onCancel) => {
    if (!submitLoading) {
      setOpen(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      open={open}
      title={"Contact Information"}
      // onCancel={() => {
      //   handleClose(true);
      // }}
      onOk={() => {
        form.submit();
      }}
      okText="Save"
      confirmLoading={submitLoading}
      width={700}
      className="confirmation-popup contact-info-popup customer-scrollbar-row"
      style={{ maxHeight: "300px" }}
      cancelButtonProps={{ style: { display: "none" } }}
      modalRender={(modal) => (
        <ModalRenderer visible={open}>{modal}</ModalRenderer>
      )}
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        layout={"vertical"}
        name="partnerForm"
        scrollToFirstError
        className="date-picker-user"
        onFinish={handleSubmit}
      >
        <Row gutter={12}>
          {/* <Col md={24} sm={24}>
            {" "}
            <Divider
              orientation="left"
              style={{ color: "#1976d2", borderTopColor: "#cacaca" }}
            >
              User Information
            </Divider>
          </Col> */}
          <Col md={12} sm={12}>
            <Form.Item
              name={"first_name"}
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
                {
                  validator: (_, value) => {
                    if (filter.isProfane(value)) {
                      return Promise.reject(
                        new Error(
                          "Please correct your input as it contains restricted words."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input style={{ marginBottom: "0.5rem" }} />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"last_name"}
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
                {
                  validator: (_, value) => {
                    if (filter.isProfane(value)) {
                      return Promise.reject(
                        new Error(
                          "Please correct your input as it contains restricted words."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input style={{ marginBottom: "0.5rem" }} />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"email"}
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter valid email",
                },
                {
                  validator: (_, value) => {
                    if (filter.isProfane(value)) {
                      return Promise.reject(
                        new Error(
                          "Please correct your input as it contains restricted words."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input style={{ marginBottom: "0.5rem" }} />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"phone"}
              label="Phone No."
              rules={[
                // {
                //   required: true,
                //   message: "Please enter phone number",
                // },
                {
                  pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                  message: "Please enter valid phone number",
                },
              ]}
            >
              <ReactInputMask
                className="ant-input phone-input"
                // name="phone"
                style={{ marginBottom: "0.5rem" }}
                // placeholder="(___) ___-____"
                mask={"(999) 999-9999"}
              />
            </Form.Item>
          </Col>
          {/* <Col md={24} sm={24}>
            {" "}
            <Divider
              orientation="left"
              style={{ color: "#1976d2", borderTopColor: "#cacaca" }}
            >
              Company Information
            </Divider>
          </Col> */}
          <Col md={12} sm={12}>
            <Form.Item
              name={"company_name"}
              rules={[
                {
                  required: true,
                  message: "Please enter company name",
                },
                {
                  validator: (_, value) => {
                    if (filter.isProfane(value)) {
                      return Promise.reject(
                        new Error(
                          "Please correct your input as it contains restricted words."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label="Company Name"
            >
              <Input autoComplete="new-password" />
            </Form.Item>
          </Col>

          {/* <Col md={12} sm={12}>
            <Form.Item
              name={"company_license"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter license number",
              //   },
              // ]}
              label="License Number"
            >
              <Input autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"company_license_expired_date"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter expiration date",
              //   },
              // ]}
              label="Expiration Date"
            >
              <DatePicker
                className="date-picker-signup"
                format={"MM-DD-yyyy"}
              />
            </Form.Item>
          </Col>

          <Col md={12} sm={12}>
            <Form.Item
              name={"company_address"}
              rules={[
                {
                  required: true,
                  message: "Please enter address",
                },
              ]}
              label="Address"
            >
              <Input autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"company_address2"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter address",
              //   },
              // ]}
              label="Address 2"
            >
              <Input autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"company_city"}
              rules={[
                {
                  required: true,
                  message: "Please enter city",
                },
              ]}
              label="City"
            >
              <Input autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"company_state"}
              rules={[
                {
                  required: true,
                  message: "Please enter state",
                },
              ]}
              label="State"
            >
              <Input autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col md={12} sm={12}>
            <Form.Item
              name={"company_zip"}
              rules={[
                {
                  required: true,
                  message: "Please enter zip code",
                },
              ]}
              label="Zip Code"
            >
              <Input autoComplete="new-password" />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
};

export default ContactPopup;
