import { Avatar, Badge, Button, Divider, Popover } from "antd";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../store/global-context";
import { groupBy } from "lodash";
import { useNavigate } from "react-router-dom";
import API from "../../../api";
import { usePrivateChannels } from "../../../store/socket-context";
import { PUSHER_EVENTS } from "../../../utils/constants";
import Notification from "../../shared/Notification";

const NotificationPopover = ({ viewTransaction }) => {
  const globalCtx = useContext(GlobalContext);
  const channels = usePrivateChannels(globalCtx?.user?.user_uuid);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const notificationGroupedByTransaction = groupBy(
    globalCtx?.notifications,
    "data.transaction_uuid"
  );

  useEffect(() => {
    if (channels) {
      channels.listen(PUSHER_EVENTS.NOTIFICATIONS, (data) => {
        Notification("info", "You have a new notification.");
        globalCtx.addNotification(data?.data);
      });
      return () => {
        channels.stopListening(PUSHER_EVENTS.NOTIFICATIONS);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels, globalCtx?.user?.user_uuid]);

  const handleNotificationRead = (transaction_uuid, loanNumber) => {
    API.get("notifications/mark-as-read", {
      params: {
        transaction_uuid,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const filteredNotifications = globalCtx?.notifications?.filter(
            (notification) =>
              notification?.data?.transaction_uuid !== transaction_uuid
          );
          globalCtx.setNotifications(filteredNotifications);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMarkNotificationAsRead = (transactionUUID, loanNumber) => {
    if (transactionUUID) {
      handleNotificationRead(transactionUUID, loanNumber);
      viewTransaction(transactionUUID);
    }
    setVisible(false);
  };

  const content = (
    <div className="notification-content">
      {Object.keys(notificationGroupedByTransaction).length > 0 ? (
        <div className="notification-list">
          {Object.keys(notificationGroupedByTransaction).map((key) => (
            <div
              className="notification-item"
              key={key}
              onClick={() => {
                handleMarkNotificationAsRead(
                  notificationGroupedByTransaction?.[key]?.[0]?.data
                    ?.transaction_uuid,
                  notificationGroupedByTransaction?.[key]?.[0]?.data
                    ?.loan_number
                );
              }}
            >
              <div className="header">
                <Avatar size="small">
                  {notificationGroupedByTransaction?.[key].length}
                </Avatar>
                <p className="loan-number">
                  {
                    notificationGroupedByTransaction?.[key]?.[0]?.data
                      ?.loan_number
                  }
                </p>
              </div>
              {notificationGroupedByTransaction?.[key].map((event, index) => (
                <div className="event" key={index}>
                  <div className="event-info">
                    <p className="event-name">
                      Event: <span>{event?.data?.event || "-"}</span>
                    </p>
                    {event?.data?.time_passed && (
                      <p className="date">{event?.data?.time_passed}</p>
                    )}
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-notification-cell">
          <p className="no-notification">No New Notification.</p>
        </div>
      )}
      <Button
        onClick={() => {
          navigate("/standalone/notifications");
          setVisible(false);
        }}
      >
        View All Notifications
      </Button>
    </div>
  );

  return (
    <Popover
      title={
        <div className="notification-title">
          <p className="title">Notifications</p>
          {globalCtx?.notifications?.length > 0 && (
            <div className="notification-count">
              {globalCtx?.notifications?.length} Unread
            </div>
          )}
        </div>
      }
      trigger="click"
      overlayClassName="notification-popover"
      content={content}
      destroyTooltipOnHide
      arrowPointAtCenter
      placement="bottomRight"
      open={visible}
      onOpenChange={(visible) => {
        setVisible(visible);
      }}
      // getPopupContainer={(triggerNode) => {
      //   return triggerNode.parentNode.parentNode;
      // }}
    >
      <Badge
        count={globalCtx?.notifications?.length || 0}
        overflowCount={10}
        className="notification-bell"
        onClick={() => setVisible(true)}
      >
        <Avatar icon={<i className="fa-solid fa-bell"></i>} />
      </Badge>
    </Popover>
  );
};

export default NotificationPopover;
