import { Card, Col, Modal, Row } from "antd";
import React from "react";
import { useContext } from "react";
import GlobalContext from "../../store/global-context";
const ConfigurationSelectionModal = ({ isOpen, handleClose }) => {
  const globalCtx = useContext(GlobalContext);
  const setConfiguration = (value) => {
    globalCtx.setConfiguration(value);
    handleClose();
  };
  return (
    <Modal
      destroyOnClose
      open={isOpen}
      title={"Select for which mode you want to change configuration?"}
      // onCancel={() => {
      //   handleClose(true);
      // }}
      closable={false}
      footer={null}
      maskClosable={false}
      centered
      className="config-selection-popup confirmation-popup"
      width={600}
    >
      <Row gutter={[24, 24]} className="home" style={{ marginTop: "24px" }}>
        <Col span={12}>
          <Card
            className="category-card vertical-button"
            onClick={() => setConfiguration("los")}
          >
            <i className="fa-solid fa-building-columns"></i>
            <p>LOS Integration</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            className="category-card vertical-button"
            onClick={() => setConfiguration("standalone")}
          >
            <i className="fa-solid fa-user"></i>
            <p>Standalone</p>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};
export default ConfigurationSelectionModal;
