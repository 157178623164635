import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../api";
import Notification from "../shared/Notification";
import LoginVoxturImage from "./../../assets/images/login-icon.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (data) => {
    setIsLoading(true);
    API.post(
      "forget-password",
      {
        email: data.email,
      },
      { baseURL: process.env.REACT_APP_API_ENDPOINT }
    )
      .then((result) => {
        if (result.status === 200) {
          Notification("success", result.data.response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-form">
          <div className="login-info">
            <div className="login-icon-container">
              <img
                className="login-icon"
                src={LoginVoxturImage}
                alt="Voxtur Login"
              />
            </div>
            <h3>Forgot Password</h3>

            <h4>
              Enter the email address associated with your account, then click
              on the Submit button.
            </h4>
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              layout={"vertical"}
              name="login"
              onFinish={handleSubmit}
            >
              <Row gutter={30} type="flex">
                <Col md={24} sm={24}>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter email",
                      },
                      {
                        type: "email",
                        message: "Please enter valid email",
                      },
                    ]}
                    label="Email"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col
                  md={24}
                  sm={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className="login-btn"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

            <Row gutter={30} type="flex">
              <Col
                md={24}
                sm={24}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="login-btn"
                  onClick={() => {
                    navigate(
                      window.location.pathname.startsWith("/standalone")
                        ? "/standalone"
                        : "/admin"
                    );
                  }}
                >
                  Back To Login
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="login-image"></div>
      </div>
    </div>
  );
};

export default ForgotPassword;
