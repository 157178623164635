import React from "react";
import { Layout } from "antd";
import SidebarVoxturLogo from "../../assets/images/SidebarVoxturLogo.png";
const { Header } = Layout;

const PageHeader = ({}) => {
  return (
    <Header className="header">
      <div className="header-content">
        <div className="header-left">
          <div>
            <img src={SidebarVoxturLogo} />
          </div>
          <div
            className="title-calc-header"
            style={{ marginLeft: "10px", fontSize: "26px" }}
          >
            <span
              style={{
                fontWeight: "1000",
                color: "black",
                fontSize: "30px",
                marginRight: "4px",
              }}
            >
              VOXTUR
            </span>
            RateAdvisor
          </div>
        </div>
        <div className="header-center"></div>
        <div className="header-right"></div>
      </div>
    </Header>
  );
};

export default PageHeader;
