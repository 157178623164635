import React from "react";
import { Form, Row, Col, Input, Button, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import GlobalContext from "../../store/global-context";
import API from "../../api";
import { useState } from "react";
import {
  getLocalStorageRememberData,
  removeLocalStorageRememberData,
  setLocalStorageRememberData,
  setLocalStorageToken,
  setLocalStorageUserByConfiguration,
} from "../../utils/helpers";
import CryptoJS from "crypto-js";
import Notification from "../shared/Notification";
import LoginVoxturImage from "./../../assets/images/login-icon.png";
import axios from "axios";
import Loader from "../shared/Loader";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { token, setToken, setUser, setPermissions, user } =
    useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectLoading, setIsRedirectLoading] = useState(false);

  const handleSubmit = (data) => {
    if (data.remember_me) {
      setLocalStorageRememberData({
        ...data,
        password: CryptoJS.AES.encrypt(
          data.password,
          process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
        ).toString(),
        rememberMe: true,
      });
    } else {
      removeLocalStorageRememberData();
    }

    setIsLoading(true);
    API.post(
      "login",
      {
        email: data.email,
        password: data.password,
      },
      { baseURL: process.env.REACT_APP_API_ENDPOINT }
    )
      .then((result) => {
        if (result.status === 200) {
          if (
            (result.data.response.user.role === "standalone" ||
              result.data.response.user.role === "standalone_user") &&
            !result.data.response.user.password_reset
          ) {
            Notification("info", "Please reset your password");
            navigate(
              `/reset-password?token=${encodeURIComponent(
                result?.data?.response?.password_reset_token
              )}&&email=${encodeURIComponent(
                result?.data?.response?.user.email
              )}`
            );
            return;
          }

          setToken(result.data.response.token);
          setUser(result.data.response.user);
          setPermissions(
            result?.data?.response?.user?.permissions?.permissions
          );
          setLocalStorageToken(
            result.data.response.token,
            result.data.response.user.role === "standalone" ||
              result.data.response.user.role === "standalone_user"
              ? "standalone_token"
              : "admin_token"
          );
          setLocalStorageUserByConfiguration(
            result.data.response.user,
            result.data.response.user.role === "standalone" ||
              result.data.response.user.role === "standalone_user"
              ? "standalone_user"
              : "admin_user"
          );
          // if (result.data.response.user.role === "standalone") {
          //   navigate(`/standalone`);
          // } else {
          //   navigate(`/admin`);
          // }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleRedirection = () => {
    let preference = user?.client?.feature_preference;
    let canCreateTx = user?.can_create_tx;
    setIsRedirectLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}get-role`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result?.data?.status) {
          if (preference === undefined) {
            preference = result?.data?.response?.feature_preference;
          }
          if (canCreateTx === undefined) {
            canCreateTx = result?.data?.response?.can_create_tx;
          }
          navigate(
            result?.data?.response?.role === "standalone" ||
              result?.data?.response?.role === "standalone_user"
              ? (preference && preference === "title_calculator") ||
                !canCreateTx
                ? "/standalone/title-calculator/quick"
                : "/standalone/dashboard"
              : "/admin/dashboard"
          );
        }
        setIsRedirectLoading(false);
      })
      .catch((error) => {
        setIsRedirectLoading(false);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (token) {
      handleRedirection();
    }
  }, [token, navigate]);

  useEffect(() => {
    if (getLocalStorageRememberData()?.rememberMe) {
      form.setFieldsValue({
        email: getLocalStorageRememberData().email,
        password: CryptoJS.AES.decrypt(
          getLocalStorageRememberData().password,
          process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
        ).toString(CryptoJS.enc.Utf8),
        remember_me: true,
      });
    }
  }, [form]);

  return (
    <>
      {redirectLoading ? (
        <Loader />
      ) : (
        <div className="login-container">
          <div className="login-wrapper">
            <div className="login-form">
              <div className="login-info">
                <div className="login-icon-container">
                  <img
                    className="login-icon"
                    src={LoginVoxturImage}
                    alt="Voxtur Login"
                  />
                </div>
                <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  layout={"vertical"}
                  form={form}
                  name="login"
                  onFinish={handleSubmit}
                >
                  <Row gutter={30} type="flex">
                    <Col md={24} sm={24}>
                      <Form.Item
                        name={"email"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter email",
                          },
                          {
                            type: "email",
                            message: "Please enter valid email",
                          },
                        ]}
                        label="Email"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={24} sm={24}>
                      <Form.Item
                        name={"password"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter password",
                          },
                        ]}
                        label="Password"
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                    <Col md={24} sm={24} className="remember-me">
                      <Form.Item name={"remember_me"} valuePropName={"checked"}>
                        <Checkbox>Remember Me</Checkbox>
                      </Form.Item>
                      <span
                        className="forget-password-text"
                        onClick={() => navigate("/forgot-password")}
                      >
                        Forgot Password?
                      </span>
                    </Col>
                    <Col
                      md={24}
                      sm={24}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        className="login-btn"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Login
                      </Button>
                    </Col>
                    <Col
                      md={24}
                      sm={24}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      Don't have an account?{" "}
                      <div
                        style={{
                          color: "#1976d2",
                          marginLeft: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/sign-up?client=vss-standard");
                        }}
                      >
                        Sign Up
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
