import axios from "axios";
import Notification from "./components/shared/Notification";
import { getLocalStorageToken, removeLocalStorageToken } from "./utils/helpers";

let baseURL = process.env.REACT_APP_API_ENDPOINT;

// export const currentURL = `${baseURL}${
//   window.location.pathname.startsWith("/standalone") ? "standalone" : "admin"
// }`;

// const getCurrentURL = () => {
//   return `${baseURL}${
//     window.location.pathname.startsWith("/standalone") ? "standalone" : "admin"
//   }`;
// };

const API = axios.create({
  // baseURL: getCurrentURL(),
  responseType: "json",
});

export default API;
// Axios Request interceptors
API.interceptors.request.use((req) => {
  if (!req.baseURL) {
    req.baseURL = `${baseURL}${
      window.location.pathname.startsWith("/standalone")
        ? "standalone"
        : "admin"
    }`;
  }
  const userToken = getLocalStorageToken();
  if (userToken)
    req.headers = {
      Authorization: "Bearer " + userToken.replace(/^"|"$/g, ""),
    };
  if (process.env.NODE_ENV === "development") {
  }
  return req;
});

API.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === "development") {
    }
    return res;
  },
  (err) => {
    if (process.env.NODE_ENV === "development") {
    }
    if (err?.response?.status === 400) {
      Notification("error", err?.response?.data?.message);
    }
    if (err?.response?.status === 404) {
      Notification("error", err?.response?.data?.message);
    }
    if (err?.response?.status === 422) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        Object.keys(err.response.data.message).forEach(function (key) {
          Notification("error", `${err.response.data.message[key][0]}`);
        });
      } else {
        Notification("error", err.response.data.message);
      }
    }
    if (err?.response?.status === 500) {
      Notification("error", err?.response?.data?.message);
    }
    if (err?.response?.status === 403) {
      Notification("error", err?.response?.data?.message);
    }
    if (
      err?.response?.status === 401 &&
      err?.response?.config?.url !== "logout"
    ) {
      Notification("error", err?.response?.data?.message);
      window.location.replace("/login");
      if (JSON.parse(localStorage.getItem("admin_user"))?.role === "admin") {
        localStorage.removeItem("configuration");
      }
      removeLocalStorageToken();
    } else {
      // Notification("error", "internal Server Error");
    }
    throw err;
  }
);
